import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  authUser: ['response'],
  authSuccess: ['response'],
  authFail: ['error'],
  authLogout: null,
  authCheckTimeout: null,
  authCheckState: null
});
export const authTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  token: null,
  user: null,
  expirationDate: null,
  error: '',
  loading: null
});
const authUser = state => state.merge({
  loading: true,
  token: null,
  user: null
});

const authSuccess = (state, { response }) => state.merge({
  token: response.Token,
  expirationDate: response.expirationDate,
  user: response.User,
  loading: false
});

const authFail = (state, action) => state.merge({
  error: action.error,
  loading: false
});

const authLogout = state => state.merge({ token: null });
const authCheckState = state => state;
const authCheckTimeout = (state, action) => state.merge({ expirationDate: action.expirationDate });
export const reducer = createReducer(INITIAL_STATE, {
  [authTypes.AUTH_USER]: authUser,
  [authTypes.AUTH_SUCCESS]: authSuccess,
  [authTypes.AUTH_FAIL]: authFail,
  [authTypes.AUTH_LOGOUT]: authLogout,
  [authTypes.AUTH_CHECK_STATE]: authCheckState,
  [authTypes.AUTH_CHECK_TIMEOUT]: authCheckTimeout
});
