import { takeLatest, put, call, all } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import generateCsvActions, {
  generateCsvTypes
} from '../../../store/reducers/csvReducers/generateCsv';

import instance from '../../../axios';
import alertActions from '../../../store/reducers/alertReducers';
import { errorMessageToReturn } from '../../../shared/utility';

// import axios from 'axios';
function* generateCsvSagas(response) {
  try {
    let url = '/api/projet/generatecsv/byid?ids=' + response.response.ids;// ''   5     1,2 <<<<<< all
    if (response.response.ids === 'all') url = '/api/projet/generatecsv/all';
    const res = yield instance.get(url);
    if (res.status === 200) {
      window.open('http://' + res.data.lien, '_blank');
    }
    else {
      yield put(generateCsvActions.generateCsvFailure(res.data));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/logout'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(generateCsvActions.generateCsvFailure(errorMessage));
  }
}

export function* generateCsvSaga() {
  yield takeLatest(generateCsvTypes.GENERATE_CSV_REQUEST, generateCsvSagas);
}
