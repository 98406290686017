import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Redirect, NavLink } from 'react-router-dom';
import { updateObject, checkValidity } from '../../shared/utility';
import Input from '../../components/UI/Input/InputLogin';
import Button from '../../components/UI/Button/Button';
import CubeLoader from '../../components/UI/CubeLoader';
import authActions from '../../store/reducers/authReducers/auth/auth';
import Logo from '../../assets/images/logo-sigap-2.png';
import './login.css';

const Centred = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
/* eslint guard-for-in: 0 */
class Auth extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    //    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    onAuth: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  static defaultProps = {
    loading: false,
    isAuthenticated: false,
    error: false
  };

  state = {
    controls: {
      username: {
        elementType: 'input',
        elementConfig: {
          type: 'text',
          placeholder: "Nom d'utilisateur ",
          icon: 'fa fa-envelope',
          label: "Nom d'utilisateur"
        },
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false
      },
      password: {
        elementType: 'input',
        elementConfig: {
          type: 'password',
          placeholder: 'Mot de passe ',
          icon: 'fa fa-key',
          label: ' Mot de passe '
        },
        value: '',
        validation: {
          required: true,
          minLength: 4
        },
        valid: false,
        touched: false
      }
    }
  };

  submitHandler = (event) => {
    event.preventDefault();
    const { onAuth } = this.props;
    const { controls } = this.state;
    onAuth({
      payload: {
        username: controls.username.value,
        password: controls.password.value
      }
    });
  };

  inputChangedHandler = (event, controlName) => {
    const { controls } = this.state;
    const updatedControls = updateObject(controls, {
      [controlName]: updateObject(controls[controlName], {
        value: event.target.value,
        valid: checkValidity(
          event.target.value,
          controls[controlName].validation
        ),
        touched: true
      })
    });
    this.setState({ controls: updatedControls });
  };

  render() {
    const formElementsArray = [];
    const { loading, error, isAuthenticated } = this.props;
    const { controls } = this.state;
    for (const key in controls) {
      formElementsArray.push({
        id: key,
        config: controls[key]
      });
    }

    const form = formElementsArray.map(formElement => (
      <Input
        key={formElement.id}
        elementType={formElement.config.elementType}
        elementConfig={formElement.config.elementConfig}
        value={formElement.config.value}
        invalid={!formElement.config.valid}
        shouldValidate={formElement.config.validation}
        touched={formElement.config.touched}
        changed={event => this.inputChangedHandler(event, formElement.id)}
      />
    ));

    let errorMessage = null;

    if (error) {
      errorMessage = <p>{error.message}</p>;
    }

    if (isAuthenticated) {
      return <Redirect to="/" />;
    }
    return (
      <React.Fragment>

        <div className="col-md-12">
          <div className="col-md-6  offset-2">
            {errorMessage}

            <form onSubmit={this.submitHandler}>
              <div className="text-center">
                <img
                  style={{
                    width: 150,
                    marginTop: '-10px',
                    display: 'inline-block'
                  }}
                  src={Logo}
                  alt=""
                />
                <Centred>
                  <CubeLoader showLoader={loading || false} />
                </Centred>
                <br />
                <br />
                <h4 style={{ textTransform: 'uppercase' }}>Système Intégré de Gestion <br />de l’Aide Publique au Mali (SIGAP)</h4>
              </div>
              {form}
              <NavLink
                isActive={() => true}
                activeStyle={{ float: 'right', color: '#009688' }}
                to="/user/forgotpassword/">
                {' '}
                mot de passe oublié ?
              </NavLink>
              <br />
              <br />
              <Button
                type="submit"
                btnType="btn btn-raised bgSha">
                Connexion
              </Button>
              <br />
              <br />
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loading: state.auth.auth.loading,
    error: state.auth.auth.error,
    isAuthenticated: state.auth.auth.token !== null,
    authRedirectPath: state.auth.auth.authRedirectPath
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: payload => dispatch(authActions.authUser(payload))
    //  onSetAuthRedirectPath: () => dispatch(authActions.setAuthRedirectPath('/'))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Auth);
