
import { addUserSaga as addUser }  from './addUserSaga';
import { editUserSaga as editUser }  from './editUserSaga';
import { getUsersSaga as getUsers }  from './getUsersSaga';
import { getAllUsersSaga as  getAllUsers }  from './getAllUsers';
import { updatePasswordSaga as updatePassword } from './updatePasswordSaga';
// import { changePasswordSaga as changePassword }  from './getAllUsers';

const user = [
  addUser,
  editUser,
  getUsers,
  getAllUsers,
  updatePassword

];
export default user;
