import { combineReducers } from 'redux';
import { reducer as getRole } from './getRole';
import { reducer as getRoles } from './getRoles';
import { reducer as addRole } from './addRole';
import { reducer as removeRole } from './removeRole';
import { reducer as editRole } from './editRole';

export default combineReducers({
  getRole,
  getRoles,
  addRole,
  removeRole,
  editRole
});
