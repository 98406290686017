import React from 'react';
import PropTypes from 'prop-types';
// import classes from './Button.css';

const button = ({
  disabled, btnType, clicked, children, title
}) => (
  <button
    disabled={disabled}
        // className={[classes.Button, classes[btnType]].join(' ')}
    className={[btnType].join(' ')}
    onClick={clicked}
    title={title}
    >
    {children}

  </button>
);

export default button;

button.propTypes = {
  disabled: PropTypes.bool,
  btnType: PropTypes.string.isRequired,
  clicked: PropTypes.func,
  children: PropTypes.any.isRequired,
  title: PropTypes.string
};
button.defaultProps = {
  clicked: () => {},
  disabled: false,
  title: ''
};

