import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { updateObject, checkValidity } from '../../../shared/utility';
import Input from '../../../components/UI/Input/InputLogin';
import CubeLoader from '../../../components/UI/CubeLoader';
import forgotPasswordActions from '../../../store/reducers/authReducers/forgotPassword';

import Logo from '../../../assets/images/logo-sigap-2.png';
import '../login.css';

const Centred = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
/* eslint guard-for-in: 0 */
class ForgotPassword extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    history: PropTypes.object,
    onSendLink: PropTypes.func.isRequired
  };

    static defaultProps = {
      loading: false,
      history: {}
    };

    state = {
      controls: {
        email: {
          elementType: 'input',
          elementConfig: {
            type: 'email',
            placeholder: 'Email ',
            icon: 'fa fa-envelope',
            label: 'Email',
            required: true
          },
          value: '',
          validation: {
            required: true
          },
          valid: false,
          touched: false
        }
      }
    };

    submitHandler = (event) => {
      event.preventDefault();
      const {  onSendLink } = this.props;
      const { controls } = this.state;
      let url = window.location.href.replace('forgot', 'change');
      url = url.replace(/\//g, '_');
      onSendLink({
        payload: {
          email: controls.email.value,
          url: url
        }
      });
    };

    inputChangedHandler = (event, controlName) => {
      const { controls } = this.state;
      const updatedControls = updateObject(controls, {
        [controlName]: updateObject(controls[controlName], {
          value: event.target.value,
          valid: checkValidity(
            event.target.value,
            controls[controlName].validation
          ),
          touched: true
        })
      });
      this.setState({ controls: updatedControls });
    };

    cancel=() => {
      const { history } = this.props;
      history.push('/auth');
    }

    render() {
      const formElementsArray = [];
      const { loading } = this.props;
      const { controls } = this.state;
      for (const key in controls) {
        formElementsArray.push({
          id: key,
          config: controls[key]
        });
      }

      const form = formElementsArray.map(formElement => (
        <Input
          key={formElement.id}
          elementType={formElement.config.elementType}
          elementConfig={formElement.config.elementConfig}
          value={formElement.config.value}
          invalid={!formElement.config.valid}
          shouldValidate={formElement.config.validation}
          touched={formElement.config.touched}
          changed={event => this.inputChangedHandler(event, formElement.id)}
      />
      ));
      return (
        <React.Fragment>
          <div className="col-md-12">
            <div className="col-md-6  offset-2">
              <form onSubmit={this.submitHandler} className=" form-group">
                <div className="text-center">
                  <img
                    style={{
                      width: 150,
                      marginTop: '-10px',
                      display: 'inline-block'
                    }}
                    src={Logo}
                    alt=""
                />
                  <Centred>
                    <CubeLoader showLoader={loading === true} />
                  </Centred>
                  <br />
                  {' '}
                  <br />
                  <h4 style={{ textTransform: 'uppercase' }}>
Système Intégré de Gestion
                    {' '}
                    <br />
de l’Aide Publique au Mali (SIGAP)
                    {' '}
                  </h4>
                </div>
                <br />
                <p>Veuillez saisir votre email.</p>
                {form}

                <br />
                <button className="btn btn-raised bgSha" align="content-right" type="submit"> Envoyer </button>
                <button
                  className="btn btn-danger "
                  align="content-right"
                  onClick={() => this.cancel()}
               >
                  {' '}
Retour
                </button>
              </form>
            </div>
          </div>
        </React.Fragment>
      );
    }
}
const mapStateToProps = (state) => {
  return {
    loading: state.auth.forgotPassword.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSendLink: payload => dispatch(forgotPasswordActions.forgotPasswordRequest(payload))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);
