import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import { errorMessageToReturn } from '../../shared/utility';
import getStatistiquesActions, {
  getStatistiquesTypes
} from '../../store/reducers/statistiquesReducers/getStatistiques';
import alertActions from '../../store/reducers/alertReducers';
import instance from '../../axios';
/* eslint no-underscore-dangle: 0 */

function* getStatistiquesSagas({ response }) {
  try {
    let url = '';
    switch (response) {
      case 1:
        url = 'decaissement/stat/ptfCategorie';
        break;
      case 9:
        url = 'decaissement/stat/naturefinancement';
        break;
      case 3:
        url = 'decaissement/stat/refInstrument';
        break;
      case 4:
        url = 'decaissement/stat/aligSpecCREDD';
        break;
      case 5:
        url = 'projet/stat/venprojGEO';
        break;
      case 6:
        url = 'decaissement/stat/ventProjCREDD';
        break;
      case 7:
        url = 'decaissement/stat/specPTFCREDD';
        break;
      case 8:
        url = 'decaissement/stat/specPreObjCREDPTF';
        break;
      case 10:
        url = 'decaissement/stat/specEvolMtTot';
        break;
      default:
        break;
    }
    const res = yield instance.get('/api/' + url);

    if (res.status === 200 || res.status === 201) {
      yield put(getStatistiquesActions.getStatistiquesSuccess([res.data, response]));
    }
    else {
      yield put(getStatistiquesActions.getStatistiquesFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getStatistiquesActions.getStatistiquesFailure(errorMessage));
  }
}

export function* getStatistiquesSaga() {
  yield takeLatest(
    getStatistiquesTypes.GET_STATISTIQUES_REQUEST,
    getStatistiquesSagas
  );
}
