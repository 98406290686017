import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  editOrganismeRequest: ['response'],
  editOrganismeSuccess: ['loading', 'response'],
  editOrganismeFailure: ['error'],

  getOrganismeByIdRequest: ['response'],
  getOrganismeByIdSuccess: ['response', 'loading'],
  getOrganismeByIdFailure: ['error']
});

export const editOrganismeTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null,
  missions: null
});

/* ------------- Reducers ------------- */

const editOrganismeRequest = state => state.merge({ loading: true });

const editOrganismeSuccess = (state, { response }) => state.merge({
  loading: false,
  error: false,
  response
});

const editOrganismeFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

const getOrganismeByIdRequest = state => state.merge({ loading: true });

const getOrganismeByIdSuccess = (state, { response }) => state.merge({
  error: false,
  loading: false,
  response
});

const getOrganismeByIdFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.EDIT_ORGANISME_REQUEST]: editOrganismeRequest,
  [Types.EDIT_ORGANISME_SUCCESS]: editOrganismeSuccess,
  [Types.EDIT_ORGANISME_FAILURE]: editOrganismeFailure,

  [Types.GET_ORGANISME_BY_ID_REQUEST]: getOrganismeByIdRequest,
  [Types.GET_ORGANISME_BY_ID_SUCCESS]: getOrganismeByIdSuccess,
  [Types.GET_ORGANISME_BY_ID_FAILURE]: getOrganismeByIdFailure
});
