import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line

import getAllUsersActions, {
  getAllUsersTypes
} from '../../../store/reducers/UsersReducers/getAllUsers';
import alertActions from '../../../store/reducers/alertReducers';
import instance from '../../../axios';
/* eslint no-underscore-dangle: 0 */

function* getAllUsersSagas() {
  try {
    const res = yield instance.get('api/user/auth');
    if (res.status === 200) {
      yield put(getAllUsersActions.getAllUsersSuccess(res.data));
    }
    else {
      yield put(getAllUsersActions.getAllUsersFailure(res));
    }
  }
  catch (error) {
    yield put(
      alertActions.alertShow(
        false,
        true,
        false,
        false,
        'Erreur',
        error.toString()
      )
    );
    yield put(getAllUsersActions.getAllUsersFailure(error.toString()));
  }
}

export function* getAllUsersSaga() {
  yield takeLatest(getAllUsersTypes.GET_ALL_USERS_REQUEST, getAllUsersSagas);
}
