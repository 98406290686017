// @flow
import React from 'react';
import { ConnectedRouter } from 'react-router-redux';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker'; // eslint-disable-line
import App from './components/core/App';
import configureStore, { history } from './store/configStore/configureStore';
//import CacheBuster from './CacheBuster';

//replaced the file CacheBuster with an npm package CacheBuster
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';

//HashRouter allows the fetch from  to get executed on reload
import { HashRouter } from 'react-router-dom'

//Please uncomment this folowing line if your are on development mode and comment the next line
//const isProduction = process.env.NODE_ENV === 'development';
const isProduction = process.env.NODE_ENV === 'production';

const store = configureStore();
const persistor = persistStore(store);
const route = (
  // <CacheBuster>
  //   {({ loading, isLatestVersion, refreshCacheAndReload }) => {
  //                   if (loading) return null
  //                   if (!loading && !isLatestVersion) {
  //                       refreshCacheAndReload()
  //                   }
  //                   return (

      <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={null}
      >
        <HashRouter>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <ConnectedRouter history={history}>
                    <App />
                </ConnectedRouter>
              </PersistGate>
            </Provider>
        </HashRouter>
</CacheBuster>
//  )
// }}
// </CacheBuster>

);

ReactDOM.render(route, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
