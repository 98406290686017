import React, { Component } from 'react';// eslint-disable-next-line
import { Redirect } from 'react-router-dom'; 
import { connect } from 'react-redux';
import PropTypes from  'prop-types';
import  authActions from '../../../store/reducers/authReducers/auth/auth';
import instance from '../../../axios';

class Logout extends Component {
    static propTypes = {
      onLogout: PropTypes.func.isRequired
    }

    componentDidMount() {
      const { onLogout } = this.props;
      instance.defaults.headers.Authorization = '';
      onLogout();
      localStorage.clear();
    }

    render() {
      return (<Redirect to="/auth" />);
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(authActions.authLogout())
  };
};

export default connect(null, mapDispatchToProps)(Logout);
