import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import getOrganismeByIdActions, {
  editOrganismeTypes
} from '../../../store/reducers/organismeReducers/editOrganisme';
// import getOrganismesByPaginationRequestActions from '../../../store/reducers/organismeReducers/getOrganismesByPagination';
import instance from '../../../axios';
import alertActions from '../../../store/reducers/alertReducers';
import getOrganismesByPaginationRequestActions from '../../../store/reducers/organismeReducers/getOrganismesByPagination';
import { errorMessageToReturn } from '../../../shared/utility';
import getAllOrganismestActions from '../../../store/reducers/organismeReducers/getAllOrganismes';


/* eslint no-underscore-dangle: 0 */

function* getOrganismeByIdSagas(payload) {
  try {
    const res = yield instance.get('/api/organisme/' + payload.response.id);
    if (res.status === 200 || res.status === 201) {
      yield put(getOrganismeByIdActions.getOrganismeByIdSuccess(res.data));
    }
    else {
      yield put(getOrganismeByIdActions.getOrganismeByIdFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getOrganismeByIdActions.getOrganismeByIdFailure(errorMessage));
  }
}

function* editOrganismeSagas({ response }) {
  const newResponse = {
    designation: response.designation,
    code: response.code,
    ptf_categorie: response.ptf_categorie,
    ptf_type: response.ptf_type,
    structure_etat: response.structure_etat
  };
  try {
    const res = yield instance.patch('/api/organisme/' + response.id, newResponse);
    if (res.status === 200 || res.status === 201) {
      yield put(getOrganismeByIdActions.editOrganismeSuccess(res.data));
      yield put(
        alertActions.alertShow(
          false,
          false,
          true,
          false,
          'Succès',
          'Organisme est modifié avec succès'
        )
      );
      yield put(
        getOrganismesByPaginationRequestActions.getOrganismesByPaginationRequest(
          {
            limit: 5,
            activePage: 1,
            navigate: true
          }
        )
      );
      yield put(
        getAllOrganismestActions.getAllOrganismesRequest()
      );
      yield put(push('/organisme/list'));
    }
    else {
      yield put(getOrganismeByIdActions.editOrganismeFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getOrganismeByIdActions.editOrganismeFailure(errorMessage));
  }
}

export function* editOrganismeSaga() {
  yield takeLatest(
    editOrganismeTypes.GET_ORGANISME_BY_ID_REQUEST,
    getOrganismeByIdSagas
  );
  yield takeLatest(
    editOrganismeTypes.EDIT_ORGANISME_REQUEST,
    editOrganismeSagas
  );
}
