import { takeLatest, put, call, all } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import deleteReferencesActions, {
  deleteReferenceTypes
} from '../../../store/reducers/referencialReducers/deleteReferencial';
import getAllReferencesActions from '../../../store/reducers/referencialReducers/getAllReferencial';
import instance from '../../../axios';
import alertActions from '../../../store/reducers/alertReducers';
import { errorMessageToReturn } from '../../../shared/utility';

function* deleteReferencialSagas(response) {
  try {
    const responseDelete = yield instance.delete(
      'api/referenciel/' + response.response
    );

    if (responseDelete.status === 200) {
      yield all([
        yield put(
          deleteReferencesActions.deleteReferenceSuccess(responseDelete.data)
        ),
        yield put(getAllReferencesActions.getAllReferenceRequest()),
        yield put(
          getAllReferencesActions.getReferenceByPaginationRequest(
            1,
            5,
            null,
            null
          )
        )
      ]);
    }
    else {
      yield put(
        deleteReferencesActions.deleteReferenceFailure(responseDelete.data)
      );
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecté
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autorisé
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(deleteReferencesActions.deleteReferenceFailure(errorMessage));
  }
}

export function* deleteReferencialSaga() {
  yield takeLatest(
    deleteReferenceTypes.DELETE_REFERENCE_REQUEST,
    deleteReferencialSagas
  );
}
