import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import { errorMessageToReturn } from '../../../shared/utility';
import addUserActions, {
  addUserTypes
} from '../../../store/reducers/UsersReducers/addUser';
import getUsersActions from '../../../store/reducers/UsersReducers/getUsers';
import alertActions from '../../../store/reducers/alertReducers';
import instance from '../../../axios';
/* eslint no-underscore-dangle: 0 */

function* addUserSagas({ response }) {
  try {
    const res = yield instance.post(
      '/api/users/register', response
    );
    if (res.status === 200 || res.status === 201) {
      yield put(addUserActions.addUserSuccess(res.data));
      yield put(
        alertActions.alertShow(
          false, 
          false,
          true,
          false,
          'Succès',
          'Utilisateur est ajouté avec succès'
        )
      );
      yield put(getUsersActions.getUsersRequest({
        payload: {
          activePage: 1, itemPerPage: 5, searchValue: '', role: 0
        }
      }));
      yield put(push('/user/list'));
    }
    else {
      yield put(addUserActions.addUserFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(addUserActions.addUserFailure(errorMessage));
  }
}
export function* addUserSaga() {
  yield takeLatest(
    addUserTypes.ADD_USER_REQUEST,
    addUserSagas
  );
}
