import styled from 'styled-components';

export const Centred = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 33%;
`;
export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== '' && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }
  if (rules.isInt) {
    isValid = Number(value) === value && value % 1 === 0 && isValid;
  }
  if (rules.isFloat) {
    const pattern =  /^-?\d*(\.\d+)?$/;
    isValid = pattern.test(value) && isValid;
  }
  return isValid;
};

const checkElementConfig = (elementType, elementConfig) => {
  switch (elementType) {
    case 'input':
      return {
        type: elementConfig.type,
        placeholder: elementConfig.placeholder
      };
    case 'select':
      return {
        options: elementConfig.options
      };
    default:
      return {
        type: elementConfig.type,
        placeholder: elementConfig.placeholder
      };
  }
};
//  InputComponent("Mission", "select", validation, true, '', false, { options: missionOptions }),

export function InputComponent(
  label,
  elementType,
  elementConfig,
  value,
  validation,
  valid,
  touched
) {
  return {
    label: label,
    elementType: elementType,
    elementConfig: checkElementConfig(elementType, elementConfig),
    value: value,
    validation: validation,
    valid: valid,
    touched: touched
  };
}

export const objectsToOptions = (objects) => {
  const options = [];

  if (objects !== null) {
    objects.forEach((element) => {
      options.push({
        id: element.id,
        value: element.id,
        displayValue: element.intitule_fr

      });
    });
  }
  return options;
};

export const errorNotification = (id, type, title, message) => {
  return {
    id: id,
    type: type,
    headline: title,
    message: message,
    position: 'bottom-right'
  };
};
export function generateKey() {
  const uuid = require('uuid/v1');
  const id = uuid();
  return id;
}
export const  errorMessageToReturn = (err) => {
  let errorMessage = '';// 'Code :  ' + err.response.data.code.toString();
  if (Array.isArray(err.response.data.message)) {
    errorMessage = errorMessage  + ' ' + err.response.data.message.toString().join();
  }
  else if (typeof (err.response.data.message) === 'object') {
    let errorMessageObject = '';
    Object.keys(err.response.data.message).forEach((key) => {
      errorMessageObject = errorMessageObject + '  ' + key + '  ' + err.response.data.message[key];
    });
    errorMessage = errorMessage  + '  ' + errorMessageObject;
  }
  else {
    errorMessage = errorMessage  + '  ' + err.response.data.message.toString();
  }

  return errorMessage;
};
export const removeSpecialChars = (string) => {
  // eslint-disable-next-line no-useless-escape
  return typeof string === 'string' ? string.replace(/[!@#%^&*()_+\-=\[\]{};':"\\|,.<~`°²¨>\/?]/g, '') : '';
};
export const convertNumber = (string) => {
  // eslint-disable-next-line no-useless-escape
  // eslint-disable-next-line radix
  return parseFloat(string.toString().replace(/ /g, '')) || 0;
};

export const normalizeFloat=(montant, total) => {
  let f = 0;
  if (montant !== 0 && total !== 0) f = ((montant / total) * 100).toFixed(2);
  const arr =  f.toString().split('.');
  if (arr[1] === '0' || arr[1] === '00') return arr[0];
  return arr.join(',');
}

export const normalizeNumber = (x) => {
  if (x === '') return '';
  if (!x) return 0;
  const y = x.toString().split('.');
  const arr = y[0].toString().replace(/ /g, '');
  const afterComma = ((x.toString().indexOf('.') > -1) ? '.' + y[1].charAt(0) + y[1].charAt(1) : '');
  if (arr.length < 4) return arr + afterComma;
  if (arr.length % 3 === 0) {
    return arr.match(/.{1,3}/g).join(' ') + afterComma;
  } if (arr.length % 3 === 1) {
    const first = arr.charAt(0);
    const str =  arr.substring(1).match(/.{1,3}/g);
    return first + ' ' + str.join(' ') + afterComma;
  }
  const first = arr.charAt(0);
  const second = arr.charAt(1);

  const str =  arr.substring(2).match(/.{1,3}/g);
  return first + second + ' ' + str.join(' ') + afterComma;
};
export const getPermission = (ecran, userConected, methode) => {
  // get user interfaces
  if (userConected.ecrans[0] === '*') return true;
  const hasPermission = userConected.ecrans.includes(ecran);
  const userRole = userConected.details.user_roles[0].role;
  const getMethodes = userConected.roles[userRole];
  if (hasPermission && methode) {
    return getMethodes[ecran].includes(methode);
  }
  return false;
};
export const generateTableButtons = (user, ecran, canSeeDetails = true, canAffectPermission = false, canValidate = false, canDelete = true) => {
  const actions = [];
  if (getPermission(ecran, user, 'Edit')) {
    if (ecran === 'reclamation') {
      actions.push({
        onClick: 'editClick', icon: 'fa fa-reply', label: 'Répondre', key: Math.random().toString(36).substr(2, 9)
      });
    }
    else {
      actions.push({
        onClick: 'editClick', icon: 'fa fa-edit', label: 'Modifier', key: Math.random().toString(36).substr(2, 9)
      });
    }
  }
  if (getPermission(ecran, user, 'Delete') && canDelete) {
    actions.push({
      onClick: 'supClick', icon: 'fa fa-trash', label: 'Supprimer', key: Math.random().toString(36).substr(2, 9)
    });
  }
  if (canSeeDetails && getPermission(ecran, user, 'Get')) {
    actions.push({
      onClick: 'detailsClick', icon: 'fa fa-eye', label: 'Détails', key: Math.random().toString(36).substr(2, 9)
    });
  }
  if (canAffectPermission && getPermission(ecran, user, 'AddPermission')) {
    actions.push({
      onClick: 'addLink', icon: 'fa fa-lock', label: 'Permissions', key: Math.random().toString(36).substr(2, 9)
    });
  }
  if (canValidate && getPermission(ecran, user, 'Validate')) {
    actions.push({
      onClick: 'addLink', icon: 'fa fa-lock', label: 'Valider', key: Math.random().toString(36).substr(2, 9)
    });
  }
  return actions;
};

export const getByEcran = (ecran, userConected) => {
  /* eslint guard-for-in: 0 */
  if (userConected.ecrans[0] === '*') return true;
  if (ecran === '*') return true;
  return userConected.ecrans.includes(ecran);
};

export const getMyEcran = (ecransListe, userConected, methode = null) => {
  /* eslint guard-for-in: 0 */

  if (userConected.ecrans[0] === '*') return true;
  if (ecransListe[0] === '*') return true;
  let hasPermission = false;
  let hasPermissionMethode = false;
  let hasGlobalePermission = false;
  const userRole = userConected.details.user_roles[0].role;
  const getMethodes = userConected.roles[userRole];
  ecransListe.forEach((ecran) => {
    if (hasGlobalePermission) return true;
    hasPermission = userConected.ecrans.includes(ecran);
    if (hasPermission && methode && getMethodes[ecran]) {
      hasPermissionMethode = getMethodes[ecran].includes(methode);
    }
    hasGlobalePermission = hasPermission && hasPermissionMethode;
  });
  return hasGlobalePermission;
};
