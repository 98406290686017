import { combineReducers } from 'redux';
import { reducer as allConventions } from './getConventions';
import { reducer as allConventionsNoPag } from './getConventionsNoPag';
import { reducer as addConvention } from './addConvention';
import { reducer as editConvention } from './editConvention';
import { reducer as detailsConvention } from './detailsConvention';
import { reducer as deleteConvention } from './deleteConvention';

export default combineReducers({
  allConventions,
  allConventionsNoPag,
  addConvention,
  editConvention,
  detailsConvention,
  deleteConvention
});
