import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import alertActions from '../../../store/reducers/alertReducers';

class Alert extends React.Component {
  static propTypes = {
    warning: PropTypes.bool.isRequired,
    show: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    info: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onHideAlert: PropTypes.func.isRequired,
    onConfirm: PropTypes.func,
    deletedReferencial: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  };

  static defaultProps = {
    onConfirm: null,
    deletedReferencial: null
  };

  constructor(props) {
    super(props);
    this.state = { confirmDelete: false };
  }

  componentWillReceiveProps(nextProps) {
    const { deletedReferencial, onHideAlert } = this.props;
    if (nextProps.deletedReferencial !== deletedReferencial) {
      this.setState({ confirmDelete: true });
      setTimeout(() => {
        onHideAlert();
        this.setState({ confirmDelete: false });
      }, 1500);
    }
  }

  hideAlert = () => {
    const { onHideAlert } = this.props;
    onHideAlert();
    this.setState({ confirmDelete: false });
  };

  onConfirm = () => {
    const { onConfirm, onHideAlert } = this.props;
    if (onConfirm != null) {
      onConfirm();
      onHideAlert();
    }
    else {
      onHideAlert();
      this.setState({ confirmDelete: false });
    }
  };

  render() {
    const { confirmDelete } = this.state;
    const {
      error,
      info,
      success,
      warning,
      title,
      message,
      show,
      onConfirm
    } = this.props;

    return (
      <div>
        {' '}
        {show && (
          <SweetAlert
            showCancel={onConfirm != null}
            cancelBtnText="Non"
            confirmBtnText={warning ? 'Oui' : 'Ok'}
            confirmBtnBsStyle={error || warning ? 'danger' : 'default'}
            cancelBtnBsStyle="default"
            onCancel={() => this.hideAlert()}
            error={error}
            info={info}
            warning={warning}
            success={success || confirmDelete}
            title={confirmDelete ? 'Succès' : title}
            onConfirm={() => this.onConfirm()}
          >
            {confirmDelete ? 'Suppression terminer avec succès' : message}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onHideAlert: () => dispatch(alertActions.alertHide())
});
const mapStateToProps = ({ alert, referencial }) => {
  return {
    warning: alert.warning,
    show: alert.show,
    error: alert.error,
    success: alert.success,
    info: alert.info,
    title: alert.title,
    message: alert.message,
    onConfirm: alert.onConfirm,
    deletedReferencial: referencial.deleteReferencial.response
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Alert);
