import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import {
  seamlessImmutableReconciler,
  seamlessImmutableTransformer
} from 'redux-persist-seamless-immutable';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native

import authReducers from './authReducers/index';
import referencialReducers from './referencialReducers';
import organismeReducers from './organismeReducers';
import conventionReducers from './conventionReducers';
import budgetYearReducers from './budgetYearReducers';
import decaissementReducers from './decaissementReducers';
import projectReducers from './projectReducers';
import { reducer as alert } from './alertReducers/index';
import usersReducers from './UsersReducers';
import reclamationReducers from './reclamationReducers';
import statistiquesReducers from './statistiquesReducers';
import notificationsReducers from './notificationsReducers';
import activityReducers from './activityReducers';
import activityCsvReducers from './activityCsvReducers';
import csvReducers from './csvReducers';

const containersReducer = {
  notifications: notificationsReducers,
  statistiques: statistiquesReducers,
  projects: projectReducers,
  auth: authReducers,
  referencial: referencialReducers,
  organisme: organismeReducers,
  convention: conventionReducers,
  budgetYear: budgetYearReducers,
  decaissement: decaissementReducers,
  reclamation: reclamationReducers,
  alert: alert,
  users: usersReducers,
  activity: activityReducers,
  activityCsv: activityCsvReducers,
  csv: csvReducers

};

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: seamlessImmutableReconciler,
  transforms: [seamlessImmutableTransformer],
  whitelist: ['auth','organisme','route','referencial']
};
const appReducer = combineReducers({
  ...containersReducer,
  route: routerReducer
});
export const rootReducer = (state, action) => {
  if (action.type === 'AUTH_LOGOUT') {
    Object.keys(state).forEach((key) => {
      storage.removeItem(`persist:${key}`);
    });
    /*eslint-disable */
    state = undefined;
    localStorage.setItem('token', '');

    /* eslint-enable */
  }
  return appReducer(state, action);
};
const createGlobalReducer = () => persistReducer(persistConfig, rootReducer);
export default createGlobalReducer;
