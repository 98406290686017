import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  addRoleRequest: ['response'],
  addRoleSuccess: ['response'],
  addRoleFailure: ['error']
});
export const addRoleTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null
});

/* ------------- Reducers ------------- */

const addRoleSuccess = (state, {
  response
}) => {
  return state.merge({
    error: false,
    response: response,
    loading: false
  });
};
const addRoleFailure = (state, { error }) => state.merge({
  loading: false,
  error: error
});
const addRoleRequest = (state) => {
  return state.merge({ loading: true });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_ROLE_REQUEST]: addRoleRequest,
  [Types.ADD_ROLE_SUCCESS]: addRoleSuccess,
  [Types.ADD_ROLE_FAILURE]: addRoleFailure

});
