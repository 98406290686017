import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  changePasswordRequest: ['response'],
  changePasswordSuccess: ['response'],
  changePasswordFailure: ['error']
});
export const changePasswordTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  loading: null
});
const changePasswordRequest = state => state.merge({
  loading: true
});

const changePasswordSuccess = state => state.merge({
  loading: false
});

const changePasswordFailure = state => state.merge({
  loading: false
});

export const reducer = createReducer(INITIAL_STATE, {
  [changePasswordTypes.CHANGE_PASSWORD_REQUEST]: changePasswordRequest,
  [changePasswordTypes.CHANGE_PASSWORD_SUCCESS]: changePasswordSuccess,
  [changePasswordTypes.CHANGE_PASSWORD_FAILURE]: changePasswordFailure
});
