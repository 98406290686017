import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.span`
  background-color: #eee;
  color: #555;
  left: 50%;
  min-width: 120px;
  padding: 3px 5px;
  position: absolute;
  text-align: center;
  bottom: 35px;
  border-radius: 4px;
  font-family: "Lato-Light";
  font-size: 12px;
  line-height: 18px;
  z-index: 300;
  transform: translateX(-50%);
  transition: 0.2s ease;
  opacity: ${({ show }) => (show ? 1 : 0)};
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  :before {
    border-color: #eee transparent transparent;
    border-style: solid;
    border-width: 7px;
    bottom: -14px;
    content: "";
    display: block;
    left: 50%;
    opacity: 0.8;
    position: absolute;
    transform: translateX(-50%);
  }
`;

const ToolTip = ({ show, intl, ...other }) => (
  <Container {...other} show={show}>

    Ce boutton permet de rafraîchir touts les référentiels
  </Container>
);

ToolTip.propTypes = {
  show: PropTypes.bool
};

ToolTip.defaultProps = {
  show: false
};

export default ToolTip;
