import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import { errorMessageToReturn } from '../../../shared/utility';
import getAllDecaissementActions, {
  getAllDecaissementTypes
} from '../../../store/reducers/decaissementReducers/getAllDecaissements';
import alertActions from '../../../store/reducers/alertReducers';
import instance from '../../../axios';
/* eslint no-underscore-dangle: 0 */

function* getAllDecaissementsSagas({ response }) {
  try {
    let url;
    if (
      response.payload.searchValue === ''
    ) {
      url =        '?page='
        + response.payload.activePage
        + '&limit='
        + response.payload.itemsPerPage;
    }
    else {
      url =        '?page='
        + response.payload.activePage
        + '&limit='
        + response.payload.itemsPerPage
        + '&activite='
        + response.payload.searchValue;
    }
    const res = yield instance.get('/api/decaissement/all' + url);
    if (res.status === 200) {
      yield put(getAllDecaissementActions.getAllDecaissementsSuccess(res.data));
      if (response.navigate === true) {
        yield put(push('/decaissement/list'));
      }
    }
    else {
      yield put(getAllDecaissementActions.getAllDecaissementsFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getAllDecaissementActions.getAllDecaissementsFailure(errorMessage));
  }
}

export function* getAllDecaissementsSaga() {
  yield takeLatest(
    getAllDecaissementTypes.GET_ALL_DECAISSEMENTS_REQUEST,
    getAllDecaissementsSagas
  );
}
