import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  editDecaissementRequest: ['response'],
  editDecaissementSuccess: ['loading', 'response'],
  editDecaissementFailure: ['error'],

  getDecaissementByIdRequest: ['response'],
  getDecaissementByIdSuccess: ['response', 'loading'],
  getDecaissementByIdFailure: ['error']
});

export const editDecaissementTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null
});

/* ------------- Reducers ------------- */

const editDecaissementRequest = state => state.merge({ loading: true });

const editDecaissementSuccess = (state, { response }) => state.merge({
  error: false,
  response
});

const editDecaissementFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

const getDecaissementByIdRequest = state => state.merge({ loading: true });

const getDecaissementByIdSuccess = (state, { response }) => state.merge({
  error: false,
  loading: false,
  response
});

const getDecaissementByIdFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.EDIT_DECAISSEMENT_REQUEST]: editDecaissementRequest,
  [Types.EDIT_DECAISSEMENT_SUCCESS]: editDecaissementSuccess,
  [Types.EDIT_DECAISSEMENT_FAILURE]: editDecaissementFailure,

  [Types.GET_DECAISSEMENT_BY_ID_REQUEST]: getDecaissementByIdRequest,
  [Types.GET_DECAISSEMENT_BY_ID_SUCCESS]: getDecaissementByIdSuccess,
  [Types.GET_DECAISSEMENT_BY_ID_FAILURE]: getDecaissementByIdFailure
});
