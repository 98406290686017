import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  addPermissionRequest: ['response'],
  addPermissionSuccess: ['response'],
  addPermissionFailure: ['error']
});
export const addPermissionTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null
});

/* ------------- Reducers ------------- */

const addPermissionSuccess = (state, {
  response
}) => {
  return state.merge({
    error: false,
    response: response,
    loading: false
  });
};
const addPermissionFailure = (state, { error }) => state.merge({
  loading: false,
  error: error
});
const addPermissionRequest = (state) => {
  return state.merge({ loading: true });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_PERMISSION_REQUEST]: addPermissionRequest,
  [Types.ADD_PERMISSION_SUCCESS]: addPermissionSuccess,
  [Types.ADD_PERMISSION_FAILURE]: addPermissionFailure

});
