import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { history } from '../../store/configStore/configureStore.dev';
import Alert from '../../components/UI/Alert';
import Header from './Header';
import Footer from './Footer';
import Sidebare from '../../components/Navigations/Sidbare/Sidebare';
import routeConfig from '../../constantes/Routes';
import NotFound from '../../components/404NotFound/NotFound';
import Auth from '../../containers/Auth/Auth';
import ForgotPassword from '../../containers/Auth/ForgotPassword/forgotPassword';
import ChangePassword from '../../containers/Auth/ForgotPassword/changePassword';
import Logout from '../../containers/Auth/Logout/Logout';
import './styles/appStyle.css';
import instance from '../../axios';
import withErrorHandler from '../withErrorHandler/withErrorHandler';
import { getMyEcran } from '../../shared/utility';

const routes = userInfo => routeConfig.map((config) => {
  const {
    path, loadableComponent, props, ecrans, methode
  } = config;
  const methodePermission = methode ? methode[0] : null;

  if (!getMyEcran(ecrans, userInfo, methodePermission)) {
    return '';
  }
  return (
    <Route key={path} {...props} path={path} component={loadableComponent} />
  );
});
const routesUser = [
  <Route key={1} exact path="/auth" component={Auth} />,
  <Route key={1} exact path="/logout" component={Logout} />,
  <Route key={2} exact path="/user/forgotpassword" component={ForgotPassword} />,
  <Route key={3} exact path="/user/changepassword" component={ChangePassword} />
];
const Layout = ({ isLogued, userInfo, loading }) => (
  <Fragment>
    <div className={(loading && isLogued) ? 'overlay disabledbutton' : ''}>
    <nav
          className="navbar navbar-expand-lg" style={{ backgroundColor: '#269228', height: '41px'}}>
            <a className="navbar-brand">
            Ministère de l’Economie et des Finances
            </a>
            <a className="navbar-nav flex-row ml-md-auto d-none d-md-flex">
            République du MALI
            </a>
          </nav>
      {isLogued ? <Header user={userInfo} /> : ''}
      <div className="container-fluid">
      <div className="row">
        {isLogued ? (
          <div
            id="sidebb"
            className="col-md-2 d-none d-md-block bg-light sidebar"
          >
            <Sidebare user={userInfo} />
            {' '}
          </div>
        ) : (
          ''
        )}

        <TransitionGroup
          role="main"
          className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4"
        >
          <CSSTransition
            key={history.location.key}
            classNames="fade"
            timeout={300}
            exit={false}
          >
            <div className="row">
           
              <Switch>
                {isLogued ? routes(userInfo) : routesUser }
                <Route component={NotFound} />
              </Switch>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
      <Alert />
    </div>
      {isLogued ? <Footer /> : ''}
    </div>

  </Fragment>
);
export default withErrorHandler(Layout, instance);

Layout.propTypes = {
  isLogued: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  userInfo: PropTypes.object
};
Layout.defaultProps = {
  userInfo: {}
};
