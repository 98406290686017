import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import alertActions from '../../../store/reducers/alertReducers';
import { errorMessageToReturn } from '../../../shared/utility';
import getAllConventionsActions from '../../../store/reducers/conventionReducers/getConventions';
import getAllConventionsNoPagActions from '../../../store/reducers/conventionReducers/getConventionsNoPag';
import addNewConventionActions, {
  addNewConventionTypes
} from '../../../store/reducers/conventionReducers/addConvention';
import instance from '../../../axios';
/* eslint no-underscore-dangle: 0 */
function* addNewConventionSagas({ response }) {
  try {
    const res = yield instance.post('/api/convention/', response);

    if (res.status === 200 || res.status === 201) {
      yield put(alertActions.alertShow(false, false, true, false, 'Succès', 'Convention est ajoutée avec succès'));
      yield put(addNewConventionActions.addNewConventionSuccess(res.data));
      yield put(getAllConventionsNoPagActions.getAllConventionsNoPagRequest());
      yield put(getAllConventionsActions.getAllConventionsRequest({ limit: 5, activePage: 1, navigate: true }));
    }
    else {
      yield put(addNewConventionActions.addNewConventionFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecté
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autorisé
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(addNewConventionActions.addNewConventionFailure(errorMessage));
  }
}

export function* addNewConventionSaga() {
  yield takeLatest(
    addNewConventionTypes.ADD_NEW_CONVENTION_REQUEST,
    addNewConventionSagas
  );
}
