import { delay } from 'redux-saga';
import { takeLatest, put, call, all, takeEvery } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';

import instance from '../../../axios';
// import getAllReferencesActions from '../../../store/reducers/referencialReducers/getAllReferencial';

import authActions, {
  authTypes
} from '../../../store/reducers/authReducers/auth/auth';
import alertActions from '../../../store/reducers/alertReducers';

function* authLogout() {
  yield call([localStorage, 'removeItem'], 'token');
  yield call([localStorage, 'removeItem'], 'expirationDate');
  yield call([localStorage, 'removeItem'], 'user');
  // voir si'l faut faire des autres supprission
  yield put(push('/auth'));
}

function* authUser({ response }) {
  const authData = {
    username: response.payload.username,
    password: response.payload.password
  };
  const url = '/api/user/auth/login';
  try {
    const res = yield instance.post(url, authData);
    const expirationDate = yield new Date(
      new Date().getTime() +  (res.data.expirationDate  * 1000)
    ); // test date d'expiration du token reel.
    yield localStorage.setItem('token', res.data.Token);
    yield localStorage.setItem('expirationDate', expirationDate);
    yield localStorage.setItem('user', JSON.stringify(res.data.User));
    yield instance.defaults.headers.Authorization = 'Bearer ' + res.data.Token;
    // add all other pages :
    yield all([
      yield put(authActions.authSuccess(res.data)),
      yield put(authActions.authCheckTimeout()), // delais pour déconnection
      // yield put(getAllReferencesActions.getAllReferenceRequest()),
      yield put(push('/'))
    ]);
  }
  catch (err) {
    let errorMessage;
    if (err.response !== undefined) {
      errorMessage =  '';
      if (Array.isArray(err.response.data.message)) {
        errorMessage =  ' ' + err.response.data.message.toString().join();
      }
      else {
        errorMessage =  ' ' +    err.response.data.message.toString();
      }
    }
    yield put(
      alertActions.alertShow(false, true, false, false, 'Erreur ', errorMessage)
    );
    yield put(authActions.authFail(errorMessage));
  }
}

function* authCheckTimeout() {
  const expirationDate = yield new Date(localStorage.getItem('expirationDate'));
  yield delay((expirationDate.getTime() - new Date().getTime()));
  yield put(authActions.authLogout());
}
function* authCheckState() {
  const Token = localStorage.token;
  const expirationDate = yield new Date(
    localStorage.getItem('expirationDate')
  );
  if (Token === undefined || !Token || Token === '') {
    yield put(authActions.authLogout());
  }
  else if (expirationDate <= new Date()) {
    yield put(authActions.authLogout());
  }
  else {
    const User = JSON.parse(localStorage.user);
    const experDate = expirationDate.getTime();
    yield put(authActions.authSuccess({ Token, User, experDate  }));
    yield put(
      authActions.authCheckTimeout(
        (experDate - new Date().getTime())
      )
    );
    // yield put(push('/'));
  }
}
export function* auth() {
  yield takeLatest(authTypes.AUTH_CHECK_TIMEOUT, authCheckTimeout);
  yield takeLatest(authTypes.AUTH_LOGOUT, authLogout);
  yield takeLatest(authTypes.AUTH_USER, authUser);
  yield takeLatest(authTypes.AUTH_CHECK_STATE, authCheckState);
}
