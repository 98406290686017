import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line

import { push } from 'react-router-redux';
import getAllProjectsActions, {
  getAllProjectsTypes
} from '../../../store/reducers/projectReducers/getAllProjects';
import alertActions from '../../../store/reducers/alertReducers';
import instance from '../../../axios';
import { errorMessageToReturn } from '../../../shared/utility';

/* eslint no-underscore-dangle: 0 */
function* getAllProjectsSagas() {
  try {
    const res = yield instance.get('api/projet/all');

    if (res.status === 200 || res.status === 201) {
      yield put(getAllProjectsActions.getAllProjectsSuccess(res.data));
    }
    else {
      yield put(getAllProjectsActions.getAllProjectsFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecté
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autorisé
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getAllProjectsActions.getAllProjectsFailure(errorMessage));
  }
}

export function* getAllProjectsSaga() {
  yield takeLatest(
    getAllProjectsTypes.GET_ALL_PROJECTS_REQUEST,
    getAllProjectsSagas
  );
}