import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line 
import { push } from 'react-router-redux';
import getAllOrganismesActions, {
  getAllOrganismesTypes
} from '../../../store/reducers/organismeReducers/getAllOrganismes/index';
import instance from '../../../axios';
import alertActions from '../../../store/reducers/alertReducers';
import { errorMessageToReturn } from '../../../shared/utility';
/* eslint no-underscore-dangle: 0 */

function* getOrganismesSagas() {
  try {
    const url = '/api/organisme/all';
    const res = yield instance.get(url);

    if (res.status === 200) {
      yield put(getAllOrganismesActions.getAllOrganismesSuccess(res.data));
    }
    else {
      yield put(getAllOrganismesActions.getAllOrganismesFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getAllOrganismesActions.getAllOrganismesFailure(errorMessage));
  }
}

export function* getOrganismesSaga() {
  yield takeLatest(
    getAllOrganismesTypes.GET_ALL_ORGANISMES_REQUEST,
    getOrganismesSagas
  );
}
