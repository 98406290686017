import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  editProjectRequest: ['response'],
  editProjectSuccess: ['loading', 'response'],
  editProjectFailure: ['error'],
  getProjectByIdRequest: ['response'],
  getProjectByIdSuccess: ['response', 'loading'],
  getProjectByIdFailure: ['error']
});

export const editProjectTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null,
  missions: null
});

/* ------------- Reducers ------------- */

const editProjectRequest = state => state.merge({ loading: true });

const editProjectSuccess = (state, { response }) => state.merge({
  error: false,
  response
});

const editProjectFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

const getProjectByIdRequest = state => state.merge({ loading: true });

const getProjectByIdSuccess = (state, { response }) => state.merge({
  error: false,
  loading: false,
  response
});

const getProjectByIdFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.EDIT_PROJECT_REQUEST]: editProjectRequest,
  [Types.EDIT_PROJECT_SUCCESS]: editProjectSuccess,
  [Types.EDIT_PROJECT_FAILURE]: editProjectFailure,

  [Types.GET_PROJECT_BY_ID_REQUEST]: getProjectByIdRequest,
  [Types.GET_PROJECT_BY_ID_SUCCESS]: getProjectByIdSuccess,
  [Types.GET_PROJECT_BY_ID_FAILURE]: getProjectByIdFailure
});
