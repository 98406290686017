import { combineReducers } from 'redux';
import { reducer as getProjects } from './getProjects';
import { reducer as getFiles } from './getFiles';
import { reducer as editProject } from './editProject';
import { reducer as getAllProjects } from './getAllProjects';

export default combineReducers({
  getProjects,
  getAllProjects,
  getFiles,
  editProject
});
