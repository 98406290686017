import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ToolTip from './ToolTip';
import getAllReferencesActions from '../../store/reducers/referencialReducers/getAllReferencial';
import getNotificationsActions from '../../store/reducers/notificationsReducers/getNotifications';

import { images } from '../../assets/ressources/images';

const Button = styled.button`
  display: block;
  height: 40px;
  width: 40px;
  right: 100px;
  position: absolute;
  background-color: transparent;
  border-width: 0px;
`;
const MoreInfoContainer = styled.button`
  position: absolute;
  ${({ arabic }) => (arabic ? 'left: -24px' : 'right: -24px')};
  top: 0;
  bottom: 0;
  display: flex;
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
`;
const MoreInfo = styled.i`
  height: 40px;
  width: 40px;
  margin-top: -6px;
  color: #495567;
  position: relative;
  font: normal normal normal 14px/1;
`;

class MoreInfoButton extends React.Component {
  static propTypes = {
    // getAllReference: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = { showTooltip: false };
  }

  componentWillMount() {
    const { getAllReference, onDisplayNotifications, referencial } = this.props;
    // if(!notifications) onDisplayNotifications();
    if(!referencial) getAllReference();
  }

  onMouseLeave = () => {
    this.setState({ showTooltip: false });
  };

  onMouseEnter = () => {
    this.setState({ showTooltip: true });
  };

  onClickRefresh = () => {
    const {
      getAllReference,
      onDisplayNotifications
    } = this.props;
    onDisplayNotifications();
    getAllReference({ showIt: true });
  };

  render() {
    const { showTooltip } = this.state;
    return (
      <Button onClick={() => this.onClickRefresh()}>
        <MoreInfoContainer>
          <MoreInfo
            onMouseLeave={this.onMouseLeave}
            onMouseEnter={this.onMouseEnter}
          >
            <ToolTip show={showTooltip} />
          </MoreInfo>
          {false && (
            <img
              src={images.logoRefresh}
              alt="test"
              className="logoRefresh scale_icn"
            />
          )}
        </MoreInfoContainer>
      </Button>
    );
  }
}
MoreInfoButton.defaultProps = {};

MoreInfoButton.propTypes = {};
const mapDispatchToProps = dispatch => ({
  getAllReference: payload => dispatch(getAllReferencesActions.getAllReferenceRequest(payload)),
  onDisplayNotifications: ()  => dispatch(getNotificationsActions.getNotificationsRequest()),

});
const mapStateToProps = state => ({
  referencial: state.referencial.allReferencials.response,
  loading: state.referencial.allReferencials.loading,
  notifications: state.notifications.getNotifications.response,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoreInfoButton);
