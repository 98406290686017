import { takeLatest, put, call, all, takeEvery } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import instance from '../../../axios';
import changePasswordActions, {
  changePasswordTypes
} from '../../../store/reducers/authReducers/changePassword';
import alertActions from '../../../store/reducers/alertReducers';

function* changePasswordSagas({ response }) {
  const url = '/api/users/edit/password';
  try {
    yield instance.patch(url, response.payload);
    yield put(
      changePasswordActions.changePasswordSuccess()
    );
    yield put(
      alertActions.alertShow(
        false,
        false,
        true,
        false,
        'Envoyé',
        'Votre mot de passe à été modifié avec succés.'
      )
    );

    yield put(push('/auth'));
  }
  catch (err) {
    let errorMessage;
    if (err.response !== undefined) {
      errorMessage =  err.response.data.code.toString();
      if (Array.isArray(err.response.data.message)) {
        errorMessage =  +' ' + err.response.data.message.toString().join();
      }
      else {
        errorMessage =  +' ' +    err.response.data.message.toString();
      }
    }
    yield put(
      alertActions.alertShow(
        false,
        true,
        false,
        false,
        'Erreur ',
        errorMessage
      )
    );
    yield put(changePasswordActions.changePasswordFailure(errorMessage));
  }
}

export function* changePasswordSaga() {
  yield all([
    yield takeLatest(changePasswordTypes.CHANGE_PASSWORD_REQUEST, changePasswordSagas)
  ]);
}
