import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getAllReferenceRequest: ['response'],
  getAllReferenceSuccess: ['response', 'loading'],
  getAllReferenceFailure: ['error'],
  getReferenceByPaginationRequest: [
    'page',
    'limit',
    'categorie',
    'intituleFr',
    'key',
    'order',
    'intituleAr'
  ],
  getReferenceByPaginationSuccess: ['filtredResponse'],
  getReferenceByPaginationFailure: ['error']
});

export const getAllReferenceTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null,
  page: 1,
  limit: 5,
  filtredResponse: null,
  categorie: null,
  intitulFr: null,
  key: null,
  order: null,
  intituleAr: null,
});

/* ------------- Reducers ------------- */
const getReferenceByPaginationRequest = (
  state,
  {
    page, limit, categorie, intitulefr, order, key
  }
) => {
  return state.merge({
    error: false,
    page,
    loading: true,
    limit,
    categorie,
    intitulFr: intitulefr,
    key,
    order
  });
};
const getReferenceByPaginationSuccess = (state, action) => {
  return state.merge({
    error: false,
    loading: false,
    filtredResponse: action.filtredResponse.data,
    activePage: action.filtredResponse.meta.page,
    total: action.filtredResponse.meta.total,
    itemsPerPage: action.filtredResponse.meta.limit
  });
};
const getAllReferenceSuccess = (state, { response }) => {
  return state.merge({
    error: false,
    response,
    loading: false
  });
};
const getAllReferenceFailure = (state, { error }) => state.merge({
  loading: false,
  error: error
});
const getAllReferenceRequest = (state) => {
  return state.merge({ loading: true, error: null });
};
const getReferenceByPaginationFailure = (state, { error }) => state.merge({
  loading: false,
  error: error
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ALL_REFERENCE_REQUEST]: getAllReferenceRequest,
  [Types.GET_ALL_REFERENCE_SUCCESS]: getAllReferenceSuccess,
  [Types.GET_ALL_REFERENCE_FAILURE]: getAllReferenceFailure,
  [Types.GET_REFERENCE_BY_PAGINATION_REQUEST]: getReferenceByPaginationRequest,
  [Types.GET_REFERENCE_BY_PAGINATION_SUCCESS]: getReferenceByPaginationSuccess,
  [Types.GET_REFERENCE_BY_PAGINATION_FAILURE]: getReferenceByPaginationFailure
});
