import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getAllDecaissementsRequest: ['response'],
  getAllDecaissementsSuccess: ['response'],
  getAllDecaissementsFailure: ['error']
});

export const getAllDecaissementTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null,
  totalItems: 0,
  activePage: 1,
  itemPerPage: 5
});

/* ------------- Reducers ------------- */
const getAllDecaissementsSuccess = (state, {
  response
}) => {
  return state.merge({
    error: false,
    response: response,
    loading: false,
    activePage: 1,
    total: 20,
    itemsPerPage: 10
    // activePage: response.meta.page,
    // total: response.meta.total,
    // itemsPerPage: response.meta.limit
  });
};
const getAllDecaissementsFailure = (state, { error }) => state.merge({
  loading: false,
  error: error
});
const getAllDecaissementsRequest = (state) => {
  return state.merge({ loading: true });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ALL_DECAISSEMENTS_REQUEST]: getAllDecaissementsRequest,
  [Types.GET_ALL_DECAISSEMENTS_SUCCESS]: getAllDecaissementsSuccess,
  [Types.GET_ALL_DECAISSEMENTS_FAILURE]: getAllDecaissementsFailure

});
