import React from 'react';

import classes from './Input.css';

const inputLogin = ({
  invalid, shouldValidate, touched, elementType, name, elementConfig, value, changed, label
}) => {
  let inputElement = null;
  // const inputClasses = [classes.InputElement, 'form-control'];
  const inputClasses = ['form-control'];

  if (invalid && shouldValidate && touched) {
    inputClasses.push(classes.Invalid);
  }

  switch (elementType) {
    case ('input'):
      inputElement = (
        <input
          name={name}
          className={inputClasses.join(' ')}
          {...elementConfig}
          value={value}
          onChange={changed} />
      );
      break;
    case ('textarea'):
      inputElement = (
        <textarea
          name={name}
          className={inputClasses.join(' ')}
          {...elementConfig}
          value={value}
          onChange={changed}
          rows="3" />
      );
      break;
    case ('select'):
      inputElement = (
        <select
          name={name}
          className={inputClasses.join(' ')}
          value={value}
          onChange={changed}>
          {elementConfig.options.map(option => (
            <option key={option.value} value={option.value}>
              {option.displayValue}
            </option>
          ))}
        </select>
      );
      break;
    default:
      inputElement = (
        <input
          className={inputClasses.join(' ')}
          {...elementConfig}
          value={value}
          onChange={changed} />
      );
  }
  return (
    <React.Fragment>
      <div id={inputElement.props.name} className="form-group has-warning">

        <div className="form-group has-warning">
          <label className={classes.Label}>
            {label}
            <i className={elementConfig.icon} />

            {elementConfig.label}
          </label>
          {inputElement}
        </div>
      </div>
    </React.Fragment>
  );
};

export default inputLogin;
