import React from 'react';
import PropTypes from 'prop-types';

const NotFound = ({ location }) => (
  <div>
    <h3>
        Vous n'avez pas la permission d'accéder à cette interface
      <code>{location.pathname}</code>
    </h3>
  </div>
);
export default NotFound;

NotFound.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
};

NotFound.defaultProps = {
  location: {
    pathname: ''
  }
};

