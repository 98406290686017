import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  editReclamationRequest: ['response'],
  editReclamationSuccess: ['loading', 'response'],
  editReclamationFailure: ['error'],

  getReclamationByIdRequest: ['response'],
  getReclamationByIdSuccess: ['response', 'loading'],
  getReclamationByIdFailure: ['error']
});

export const editReclamationTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null
});

/* ------------- Reducers ------------- */

const editReclamationRequest = state => state.merge({ loading: true });

const editReclamationSuccess = (state, { response }) => state.merge({
  error: false,
  response
});

const editReclamationFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

const getReclamationByIdRequest = state => state.merge({ loading: true });

const getReclamationByIdSuccess = (state, { response }) => state.merge({
  error: false,
  loading: false,
  response
});

const getReclamationByIdFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.EDIT_RECLAMATION_REQUEST]: editReclamationRequest,
  [Types.EDIT_RECLAMATION_SUCCESS]: editReclamationSuccess,
  [Types.EDIT_RECLAMATION_FAILURE]: editReclamationFailure,

  [Types.GET_RECLAMATION_BY_ID_REQUEST]: getReclamationByIdRequest,
  [Types.GET_RECLAMATION_BY_ID_SUCCESS]: getReclamationByIdSuccess,
  [Types.GET_RECLAMATION_BY_ID_FAILURE]: getReclamationByIdFailure
});
