import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import { errorMessageToReturn } from '../../../shared/utility';
import updatePasswordActions, {
  updatePasswordTypes
} from '../../../store/reducers/UsersReducers/updatePassword';
import alertActions from '../../../store/reducers/alertReducers';
import instance from '../../../axios';
/* eslint no-underscore-dangle: 0 */

function* getUserByIdSagas({ response }) {
  try {
    const res = yield instance.get('/api/users/' + response.payload.id);
    if (res.status === 200 || res.status === 201) {
      yield put(updatePasswordActions.getUserByIdSuccess(res.data));
    }
    else {
      yield put(updatePasswordActions.getUserByIdFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/logout'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(updatePasswordActions.getUserByIdFailure(errorMessage));
  }
}
function* updatePasswordSagas({ response }) {
  try {
    const res = yield instance.put(
      '/api/users/change_passord/' + response.id,
      response
    );
    if (res.status === 200 || res.status === 201) {
      yield put(updatePasswordActions.updatePasswordSuccess(res.data));
      yield put(
        alertActions.alertShow(
          false,
          false,
          true,
          false,
          'Succès',
          'Mot de passe est modifié avec succès'
        )
      );
      yield put(push('/user/list'));
    }
    else {
      yield put(updatePasswordActions.updatePasswordFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/logout'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(updatePasswordActions.updatePasswordFailure(errorMessage));
  }
}

export function* updatePasswordSaga() {
  yield takeLatest(
    updatePasswordTypes.GET_USER_BY_ID_REQUEST,
    getUserByIdSagas
  );
  yield takeLatest(updatePasswordTypes.UPDATE_PASSWORD_REQUEST, updatePasswordSagas);
}
