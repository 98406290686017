import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  deleteCsvRequest: ['response'],
  deleteCsvSuccess: ['response'],
  deleteCsvFailure: ['error']
});

export const deleteCsvTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null
});

/* ------------- Reducers ------------- */

const deleteCsvRequest = state => state.merge({ loading: true });

const deleteCsvSuccess = (state, { response }) => state.merge({
  error: false,
  response,
  loading: false
});

const deleteCsvFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.DELETE_CSV_REQUEST]: deleteCsvRequest,
  [Types.DELETE_CSV_SUCCESS]: deleteCsvSuccess,
  [Types.DELETE_CSV_FAILURE]: deleteCsvFailure
});
