import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getAllReclamationsWithoutPagRequest: ['response'],
  getAllReclamationsWithoutPagSuccess: ['response'],
  getAllReclamationsWithoutPagFailure: ['error']
});

export const getAllReclamationWithoutPagTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null
});

/* ------------- Reducers ------------- */
const getAllReclamationsWithoutPagSuccess = (state, { response }) => {
  return state.merge({
    error: false,
    response: response,
    loading: false
  });
};
const getAllReclamationsWithoutPagFailure = (state, { error }) => state.merge({
  loading: false,
  error: error
});
const getAllReclamationsWithoutPagRequest = (state) => {
  return state.merge({ loading: true });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ALL_RECLAMATIONS_WITHOUT_PAG_REQUEST]: getAllReclamationsWithoutPagRequest,
  [Types.GET_ALL_RECLAMATIONS_WITHOUT_PAG_SUCCESS]: getAllReclamationsWithoutPagSuccess,
  [Types.GET_ALL_RECLAMATIONS_WITHOUT_PAG_FAILURE]: getAllReclamationsWithoutPagFailure
});
