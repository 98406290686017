import { takeLatest, put, call, all } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import { errorMessageToReturn } from '../../../shared/utility';
import deleteCsvActions, {
  deleteCsvTypes
} from '../../../store/reducers/csvReducers/deleteCsv';

import getCsvActions from '../../../store/reducers/csvReducers/getCsv';
import instance from '../../../axios';
import alertActions from '../../../store/reducers/alertReducers';

function* deleteCsvSagas(response) {
  try {
    const responseDelete = yield instance.delete(
      '/api/projet/delete/projetcsv/' + response.response.id
    );
    if (responseDelete.status === 200) {
      yield put(
        alertActions.alertShow(
          false,
          false,
          true,
          false,
          'Succès',
          'Projet est supprimé avec succès'
        )
      );
      yield all([
        yield put(
          deleteCsvActions.deleteCsvSuccess(responseDelete.data),

        ),
        yield put(getCsvActions.getCsvRequest({
          activePage: 1,
          itemsPerPage: 5
        })),
        yield put(push('/projectCsv/list'))

      ]);
    }
    else {
      yield put(
        deleteCsvActions.deleteCsvFailure(responseDelete.data)
      );
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(deleteCsvActions.deleteCsvFailure(errorMessage));
  }
}

export function* deleteCsvSaga() {
  yield takeLatest(
    deleteCsvTypes.DELETE_CSV_REQUEST,
    deleteCsvSagas
  );
}
