import { combineReducers } from 'redux';
import { reducer as auth } from './auth/auth';
import permissions from './permissions';
import roles from './roles';
import { reducer as forgotPassword } from './forgotPassword';
import { reducer as changePassword } from './changePassword';

export default combineReducers({
  auth,
  permissions,
  forgotPassword,
  changePassword,
  roles
});
