import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  addNewBudgetYearRequest: ['response'],
  addNewBudgetYearSuccess: ['response', 'loading'],
  addNewBudgetYearFailure: ['error']
});

export const addNewBudgetYearTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null
});

/* ------------- Reducers ------------- */

const addNewBudgetYearRequest = state => state.merge({
  loading: true
});

const addNewBudgetYearSuccess = (state, { response }) => state.merge({
  loading: false,
  error: false,
  response
});

const addNewBudgetYearFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_NEW_BUDGET_YEAR_REQUEST]: addNewBudgetYearRequest,
  [Types.ADD_NEW_BUDGET_YEAR_SUCCESS]: addNewBudgetYearSuccess,
  [Types.ADD_NEW_BUDGET_YEAR_FAILURE]: addNewBudgetYearFailure
});
