import React from 'react';
import Loadable from 'react-loadable';
import CubeLoader from '../components/UI/CubeLoader';
import { Centred } from '../shared/utility';

const Loading = () => (
  <Centred>
    <CubeLoader showLoader />
  </Centred>
);

const routes = [
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/dashboard/Dashboard'),
      loading: Loading
    }),
    path: '/',
    props: {
      exact: true
    },
    ecrans: ['*']
  },

  {
    loadableComponent: Loadable({
      loader: () => import('../containers/referencial'),
      loading: Loading
    }),
    path: '/referencial',
    props: {
      exact: true
    },
    ecrans: ['referenciel'],
    methode: ['Pagination']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/referencial/addReferencial'),
      loading: Loading
    }),
    path: '/referencial/add',
    props: {
      exact: true
    },
    ecrans: ['referenciel'],
    methode: ['Add']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/referencial/editReferencial'),
      loading: Loading
    }),
    path: '/referencial/edit',
    props: {
      exact: true
    },
    ecrans: ['referenciel'],
    methode: ['Edit']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/Auth/Auth'),
      loading: Loading
    }),
    path: '/auth',
    props: {
      exact: true
    },
    ecrans: ['*']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/Auth/Logout/Logout'),
      loading: Loading
    }),
    path: '/logout',
    ecrans: ['*']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/Auth/permissions'),
      loading: Loading
    }),
    path: '/user/permissions/list',
    ecrans: ['permission'],
    methode: ['Get']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/Auth/permissions/affect'),
      loading: Loading
    }),
    path: '/user/permissions/affect',
    ecrans: ['role-permission'],
    methode: ['Add']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/Auth/Roles'),
      loading: Loading
    }),
    path: '/user/roles/list',
    ecrans: ['role'],
    methode: ['Pagination']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/Auth/Roles/addRoles'),
      loading: Loading
    }),
    path: '/user/roles/add',
    ecrans: ['role'],
    methode: ['Add']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/Auth/Roles/editRoles'),
      loading: Loading
    }),
    path: '/user/roles/edit',
    ecrans: ['role'],
    methode: ['Edit']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/Auth/ForgotPassword/forgotPassword'),
      loading: Loading
    }),
    path: '/user/forgotpassword/',
    props: {
      exact: true
    },
    ecrans: ['*']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/user/updatePassword'),
      loading: Loading
    }),
    path: '/user/updatePassword',
    ecrans: ['*']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/Auth/ForgotPassword/changePassword'),
      loading: Loading
    }),
    path: '/user/changepassword',
    props: {
      exact: true
    },
    ecrans: ['*']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/convention/getConventions'),
      loading: Loading
    }),
    path: '/convention/list',
    ecrans: ['convention'],
    methode: ['Pagination']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/convention/addConvention'),
      loading: Loading
    }),
    path: '/convention/add',
    ecrans: ['convention'],
    methode: ['Add']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/convention/editConvention'),
      loading: Loading
    }),
    path: '/convention/edit',
    ecrans: ['convention'],
    methode: ['Edit']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/convention/detailsConvention'),
      loading: Loading
    }),
    path: '/convention/details',
    ecrans: ['convention'],
    methode: ['Get']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/organisme/editOrganisme'),
      loading: Loading
    }),
    path: '/organisme/edit',
    ecrans: ['organisme'],
    methode: ['Edit']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/organisme/getOrganismes'),
      loading: Loading
    }),
    path: '/organisme/list',
    ecrans: ['organisme'],
    methode: ['Pagination']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/organisme/addOrganisme'),
      loading: Loading
    }),
    path: '/organisme/add',
    ecrans: ['organisme'],
    methode: ['Add']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/budgetYear/getBudgetYear'),
      loading: Loading
    }),
    path: '/anneebudgetaire/list',
    ecrans: ['annee-budgetaire'],
    methode: ['Pagination']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/budgetYear/addBudgetYear'),
      loading: Loading
    }),
    path: '/anneebudgetaire/add',
    ecrans: ['annee-budgetaire'],
    methode: ['Add']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/projects/projects'),
      loading: Loading
    }),
    path: '/project/list',
    ecrans: ['projet'],
    methode: ['Pagination']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/projectCsv/projectCsv'),
      loading: Loading
    }),
    props: {
      exact: true
    },
    path: '/projectCsv/list',
    ecrans: ['projet-csv'],
    methode: ['Pagination']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/projectCsv/addProjectCsv'),
      loading: Loading
    }),
    props: {
      exact: true
    },
    path: '/projectCsv/add',
    ecrans: ['projet-csv'],
    methode: ['Add']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/projectCsv/editProjectCsv'),
      loading: Loading
    }),
    props: {
      exact: true
    },
    path: '/projectCsv/edit/:id',
    ecrans: ['projet-csv'],
    methode: ['Edit']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/projectCsv/detailsProjectCsv'),
      loading: Loading
    }),
    props: {
      exact: true
    },
    path: '/projectCsv/details/:id',
    ecrans: ['projet-csv'],
    methode: ['Get']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/activity/activity'),
      loading: Loading
    }),
    props: {
      exact: true
    },
    path: '/activity/list',
    ecrans: ['activite'],
    methode: ['Pagination']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/activityCsv/activityCsv'),
      loading: Loading
    }),
    props: {
      exact: true
    },
    path: '/activitycsv/list',
    ecrans: ['activite-csv'],
    methode: ['Pagination']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/activityCsv/addActivityCsv'),
      loading: Loading
    }),
    props: {
      exact: true
    },
    path: '/activitycsv/add',
    ecrans: ['activite-csv'],
    methode: ['Add']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/activityCsv/editActivityCsv'),
      loading: Loading
    }),
    path: '/activitycsv/edit',
    ecrans: ['activite-csv'],
    methode: ['Edit']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/projects/editProject'),
      loading: Loading
    }),
    path: '/project/edit',
    ecrans: ['*']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/reclamation/addReclamation'),
      loading: Loading
    }),
    path: '/reclamation/add',
    ecrans: ['reclamation'],
    methode: ['Add']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/reclamation/editReclamation'),
      loading: Loading
    }),
    path: '/reclamation/edit',
    ecrans: ['reclamation'],
    methode: ['Edit']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/reclamation/detailsReclamation'),
      loading: Loading
    }),
    path: '/reclamation/details',
    ecrans: ['reclamation'],
    methode: ['Get']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/reclamation/getReclamation'),
      loading: Loading
    }),
    path: '/reclamation/list',
    ecrans: ['reclamation'],
    methode: ['Pagination']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/decaissement/addDecaissement'),
      loading: Loading
    }),
    path: '/decaissement/add',
    ecrans: ['decaissement'],
    methode: ['Add']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/decaissement/editDecaissement'),
      loading: Loading
    }),
    path: '/decaissement/edit',
    ecrans: ['decaissement'],
    methode: ['Edit']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/decaissement/getDecaissement'),
      loading: Loading
    }),
    path: '/decaissement/list',
    ecrans: ['decaissement'],
    methode: ['Pagination']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/decaissement/detailsDecaissement'),
      loading: Loading
    }),
    path: '/decaissement/details',
    ecrans: ['decaissement'],
    methode: ['Get']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/statistiques/index'),
      loading: Loading
    }),
    path: '/statistiques/list',
    ecrans: ['*']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/user/getUsers'),
      loading: Loading
    }),
    path: '/user/list',
    ecrans: ['user'],
    methode: ['Pagination']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/user/addUser'),
      loading: Loading
    }),
    path: '/user/add',
    ecrans: ['user'],
    methode: ['Add']
  },
  {
    loadableComponent: Loadable({
      loader: () => import('../containers/user/editUser'),
      loading: Loading
    }),
    path: '/user/edit',
    ecrans: ['user'],
    methode: ['Edit']
  }
];
export default routes;
