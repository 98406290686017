import { takeLatest, put, call, all } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import addNewReferencesActions, {
  addNewReferenceTypes
} from '../../../store/reducers/referencialReducers/addNewReferencial';
import getAllReferencesActions from '../../../store/reducers/referencialReducers/getAllReferencial';
import instance from '../../../axios';
import alertActions from '../../../store/reducers/alertReducers';
import { errorMessageToReturn } from '../../../shared/utility';

function* addNewReferencialSagas({ response }) {
  try {
    const responseAdd = yield instance.post('api/referenciel/new', response);
    if (responseAdd.status === 200) {
      yield put(
        alertActions.alertShow(
          false,
          false,
          true,
          false,
          'Succès',
          'Réferentiel est ajouté avec succès'
        )
      );
      yield put(
        getAllReferencesActions.getReferenceByPaginationRequest(
          1,
          5,
          null,
          null
        )
      );
      yield all([
        yield put(addNewReferencesActions.addNewReferenceSuccess(responseAdd.data)),
        yield put(getAllReferencesActions.getAllReferenceRequest()),
        yield put(push('/referencial'))
      ]);
    }
    else {
      yield put(
        addNewReferencesActions.addNewReferenceFailure(responseAdd.data)
      );
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecté
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autorisé
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(addNewReferencesActions.addNewReferenceFailure(errorMessage));
  }
}

export function* addNewReferencialSaga() {
  yield takeLatest(
    addNewReferenceTypes.ADD_NEW_REFERENCE_REQUEST,
    addNewReferencialSagas
  );
}
