import { auth as authSaga } from './auth';
import  permissionSaga from './permissions/index';
import roleSaga from './roles';
import { forgotPasswordSaga as forgotPassword }  from './forgotPassword';
import { changePasswordSaga as changePassword }  from './changePassword';

const auth = [
  authSaga,
  ...permissionSaga,
  ...roleSaga,
  forgotPassword,
  changePassword
];
export default auth;

