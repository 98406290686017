// import { delay } from 'redux-saga';
import { takeLatest, put, call, all, takeEvery } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import { errorMessageToReturn } from '../../../../shared/utility';
import instance from '../../../../axios';
import removeRoleActions, {
  removeRoleTypes
} from '../../../../store/reducers/authReducers/roles/removeRole';
import rolesAtions from '../../../../store/reducers/authReducers/roles/getRoles';
import alertActions from '../../../../store/reducers/alertReducers';

function* removeRole({ response }) {
  const url = '/api/user/roles/' + response;
  try {
    const res = yield instance.delete(url);
    if (res.status === 200) {
      yield put(removeRoleActions.removeRoleSuccess(res.data));
      yield put(rolesAtions.getRolesRequest());
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/logout'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
  }
}
export function* removeRoles() {
  yield takeLatest(
    removeRoleTypes.REMOVE_ROLE_REQUEST,
    removeRole
  );
}
