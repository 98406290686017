import { takeLatest, put, call, all } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import { errorMessageToReturn } from '../../../shared/utility';
import deleteDecaissementActions, {
  deleteDecaissementTypes
} from '../../../store/reducers/decaissementReducers/deleteDecaissement';
import getDecaissementActions from '../../../store/reducers/decaissementReducers/getDecaissements';
import instance from '../../../axios';
import alertActions from '../../../store/reducers/alertReducers';

function* deleteDecaissementSagas(response) {
  try {
    const responseDelete = yield instance.delete(
      '/api/decaissement/delete/' + response.response.id
    );
    if (responseDelete.status === 200) {
      yield put(
        alertActions.alertShow(
          false,
          false,
          true,
          false,
          'Succès',
          'Décaissement est supprimé avec succès'
        )
      );
      yield all([
        yield put(
          deleteDecaissementActions.deleteDecaissementSuccess(responseDelete.data)
        ),

        yield put(getDecaissementActions.getDecaissementsRequest({
          payload: {
            activePage: 1,
            itemsPerPage: 5
          }
        }))

      ]);
    }
    else {
      yield put(
        deleteDecaissementActions.deleteDecaissementFailure(responseDelete.data)
      );
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(deleteDecaissementActions.deleteDecaissementFailure(errorMessage));
  }
}

export function* deleteDecaissementSaga() {
  yield takeLatest(
    deleteDecaissementTypes.DELETE_DECAISSEMENT_REQUEST,
    deleteDecaissementSagas
  );
}
