import { combineReducers } from 'redux';
import { reducer as getAllActivity } from './getAllActivity';
import { reducer as editActivity } from './editActivity';
import { reducer as deleteActivity } from './deleteActivity';
import { reducer as addActivity } from './addActivity';
import { reducer as getActivityWithoutPag } from './getActivityWithoutPag';

export default combineReducers({
  getAllActivity,
  editActivity,
  deleteActivity,
  addActivity,
  getActivityWithoutPag
});
