import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line 
import { push } from 'react-router-redux';
import getAllConventionsActions, {
  getAllConventionsTypes
} from '../../store/reducers/conventionReducers/getConventions';
import instance from '../../axios';
import alertActions from '../../store/reducers/alertReducers';
import { errorMessageToReturn } from '../../shared/utility';
/* eslint no-underscore-dangle: 0 */

function* getAllConventionsSagas({ response }) {
  try {
    let url;
    if (
      (
        (response.searchValue === '' || response.searchValue === undefined) && (response.ptf === '' || response.ptf === undefined) && (response.key === '' || response.key === undefined)
      ) || response === null
    ) {
      url = '/api/convention/'
      + '?limit='
      + response.limit
      + '&page='
      + response.activePage;
    }
    else {
      url = '/api/convention/'
      + '?limit='
      + response.limit
      + '&page='
      + response.activePage
      + '&intitule='
      + (response.searchValue ? (response.searchValue) : '')
      + '&order='
      + (response.order ? (response.order) : 'desc')
      + '&key='
      + (response.key ? (response.key) : '')
      + '&group_executions='
      + (response.ptf ? response.ptf : '');
    }

    const res = yield instance.get(url);
    if (res.status === 200) {
      yield put(getAllConventionsActions.getAllConventionsSuccess(res.data));
      if (response.navigate === true) {
        yield put(push('/convention/list'));
      }
    }
    else {
      yield put(getAllConventionsActions.getAllConventionsFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecté
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autorisé
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getAllConventionsActions.getAllConventionsFailure(errorMessage));
  }
}

export function* getAllConventionsSaga() {
  yield takeLatest(
    getAllConventionsTypes.GET_ALL_CONVENTIONS_REQUEST,
    getAllConventionsSagas
  );
}
