// import "regenerator-runtime/runtime";
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistState } from 'redux-devtools'; // eslint-disable-line
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'react-router-redux';
import {createBrowserHistory} from 'history';
import api from '../../middleware/api';
import createGlobalReducer from '../reducers';
import globalSagas from "../../saga"; // eslint-disable-line

// import DevTools from '../containers/DevTools';
/* eslint no-underscore-dangle: 0 */
const composeEnhancers =  process.env.NODE_ENV === 'development'
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : null || compose;
const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();

const configureStore = (preloadedState) => {
  const store = createStore(
    createGlobalReducer(),
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        thunk,
        api,
        sagaMiddleware,
        createLogger({
          collapsed: true,
          duration: true,
          diff: true
        }),
        routerMiddleware(history)
      ),
      // DevTools.instrument(),
      // Optional. Lets you write ?debug_session=<key> in address bar to persist debug sessions
      persistState(getDebugSessionKey())
    )
  );
  sagaMiddleware.run(globalSagas);
  /*
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      store.replaceReducer(rootReducer);
    });
  } */

  return   store;
};

function getDebugSessionKey() {
  // You can write custom logic here!
  // By default we try to read the key from ?debug_session=<key> in the address bar
  const matches = window.location.href.match(/[?&]debug_session=([^&]+)\b/);
  return matches && matches.length > 0 ? matches[1] : null;
}
export default configureStore;

