import axios from 'axios';
import { API_ROOT } from './constantes/Networks';

const instance = axios.create({
  baseURL: API_ROOT,
  headers: {
    'Accept-Version': 1,
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json; charset=utf-8'
  }
});
// instance.headers.com mon = { 'Access-Control-Allow-Origin': '*' };
// axios.defaults.headers.common['Content-Type'] = 'application/json';
// global interceptors
const { token } = window.localStorage;
instance.interceptors.request.use(
  (request) => {
    if (token && typeof token !== 'undefined') {
      // console.log('%c set token here axios.js', 'background: #222; color: #bada55', token, window.localStorage.token);
      // console.log('%c ' + (window.localStorage.token === token) + ' ', 'background: red; color: yellow');
      instance.defaults.headers.Authorization = 'Bearer ' + (window.localStorage.token === token ? token : window.localStorage.token);
    }
    // place to add auth header
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default instance;
