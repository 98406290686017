import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { updateObject, checkValidity } from '../../../shared/utility';
import Input from '../../../components/UI/Input/InputLogin';
import CubeLoader from '../../../components/UI/CubeLoader';
import changePasswordActions from '../../../store/reducers/authReducers/changePassword';
import alertActions from '../../../store/reducers/alertReducers';

import Logo from '../../../assets/images/logo-sigap-2.png';
import '../login.css';

const Centred = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
/* eslint guard-for-in: 0 */
class ChangePassword extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    history: PropTypes.object,
    alertShow: PropTypes.func.isRequired,
    onChangePassword: PropTypes.func.isRequired
  };

    static defaultProps = {
      loading: false,
      history: {}
    };

    constructor(props) {
      super(props);
      this.state = {
        token: window.location.href.substring(window.location.href.indexOf('?token=') + 7),
        controls: {
          password: {
            elementType: 'input',
            elementConfig: {
              type: 'password',
              placeholder: 'Password ',
              icon: 'fa fa-key',
              label: 'Password',
              required: true
            },
            value: '',
            validation: {
              required: true
            },
            valid: false,
            touched: false
          },
          confirmPassword: {
            elementType: 'input',
            elementConfig: {
              type: 'password',
              placeholder: 'Confirmer le mot de passe ',
              icon: 'fa fa-key',
              label: 'Confirmer le mot de passe',
              required: true
            },
            value: '',
            validation: {
              required: true
            },
            valid: false,
            touched: false
          }
        }
      };
    }

    submitHandler = (event) => {
      event.preventDefault();
      const { onChangePassword, alertShow } = this.props;
      const { controls, token } = this.state;
      if (controls.password.value !== controls.confirmPassword.value) {
        alertShow(false, true, false, false, 'Erreur', 'les mots de passes ne sont pas identiques');
        return;
      }
      /*
      * password must contain characters or numbers only
      * length>=6
      */
      if (!/^[A-Za-z0-9]{6,}$/.test(controls.password.value)) {
        alertShow(false, true, false, false, 'Erreur',
          'le mot de passe doit être plus de 5 caractères et ne contenants pas de caractères spéciaux');
        return;
      }
      onChangePassword({
        payload: {
          password: controls.password.value,
          token: token
        }
      });
    };

    inputChangedHandler = (event, controlName) => {
      const { controls } = this.state;
      const updatedControls = updateObject(controls, {
        [controlName]: updateObject(controls[controlName], {
          value: event.target.value,
          valid: checkValidity(
            event.target.value,
            controls[controlName].validation
          ),
          touched: true
        })
      });
      this.setState({ controls: updatedControls });
    };

    cancel=() => {
      const { history } = this.props;
      history.push('/auth');
    }

    render() {
      const formElementsArray = [];
      const { loading } = this.props;
      const { controls } = this.state;
      for (const key in controls) {
        formElementsArray.push({
          id: key,
          config: controls[key]
        });
      }

      const form = formElementsArray.map(formElement => (
        <Input
          key={formElement.id}
          elementType={formElement.config.elementType}
          elementConfig={formElement.config.elementConfig}
          value={formElement.config.value}
          invalid={!formElement.config.valid}
          shouldValidate={formElement.config.validation}
          touched={formElement.config.touched}
          changed={event => this.inputChangedHandler(event, formElement.id)}
      />
      ));
      return (
        <React.Fragment>
          <div className="col-md-12">
            <div className="col-md-6  offset-2">
              <form onSubmit={this.submitHandler} className=" form-group">
                <div className="text-center">
                  <img
                    style={{
                      width: 150,
                      marginTop: '-10px',
                      display: 'inline-block'
                    }}
                    src={Logo}
                    alt="" />
                  <Centred>
                    <CubeLoader showLoader={loading !== null} />
                  </Centred>
                  <br />
                  {' '}
                  <br />
                  <h4 style={{ textTransform: 'uppercase' }}>
Système Intégré de Gestion
                    {' '}
                    <br />
de l’Aide Publique au Mali (SIGAP)
                    {' '}
                  </h4>
                </div>
                <br />
                <p> Veuillez saisir le nouveau mot de passe</p>
                {form}

                <br />
                <button className="btn btn-raised bgSha" align="content-right" type="submit"> Valider </button>
                <button
                  className="btn btn-danger "
                  align="content-right"
                  onClick={() => this.cancel()}
               >
                  {' '}
Retour
                </button>
              </form>
            </div>
          </div>
        </React.Fragment>
      );
    }
}
const mapStateToProps = (state) => {
  return {
    loading: state.auth.changePassword.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangePassword: payload => dispatch(changePasswordActions.changePasswordRequest(payload)),
    alertShow: (warning, error, success, info, title, message) => dispatch(
      alertActions.alertShow(warning, error, success, info, title, message)
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword);
