// import { delay } from 'redux-saga';
import { takeLatest, put, call, all, takeEvery } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import { errorMessageToReturn } from '../../../../shared/utility';
import instance from '../../../../axios';
import addPermissionActions, {
  addPermissionTypes
} from '../../../../store/reducers/authReducers/permissions/addPermission';
import permissionsActions from '../../../../store/reducers/authReducers/permissions/getPermissions';
import alertActions from '../../../../store/reducers/alertReducers';

function* addPermissionSaga({ response }) {
  const url = '/api/user/roles/give_permissions_to_role/';
  try {
    const res = yield instance.post(url, response);
    if (res.status === 200 || res.status === 201) {
      yield put(addPermissionActions.addPermissionSuccess(res.data));
      yield put(permissionsActions.getPermissionsRequest());
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/logout'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(addPermissionActions.addPermissionFailure(errorMessage));
  }
}
export function* addPermission() {
  yield takeLatest(
    addPermissionTypes.ADD_PERMISSION_REQUEST,
    addPermissionSaga
  );
}
