// import { delay } from 'redux-saga';
import { takeLatest, put, call, all, takeEvery } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import { errorMessageToReturn } from '../../../../shared/utility';
import instance from '../../../../axios';
import addRoleActions, {
  addRoleTypes
} from '../../../../store/reducers/authReducers/roles/addRole';
import alertActions from '../../../../store/reducers/alertReducers';

function* addRoleSaga({ response }) {
  const url = '/api/user/roles/new';
  try {
    const res = yield instance.post(url, response);
    if (res.status === 200 || res.status === 201) {
      yield put(addRoleActions.addRoleSuccess(res.data));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/logout'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(addRoleActions.addRoleFailure(errorMessage));
  }
}
export function* addRole() {
  yield takeLatest(
    addRoleTypes.ADD_ROLE_REQUEST,
    addRoleSaga
  );
}
