import { combineReducers } from 'redux';
import { reducer as getReclamations } from './getAllReclamations';
import { reducer as addNewReclamation } from './addNewReclamation';
import { reducer as editReclamation } from './editReclamation';
import { reducer as getReclamationsWithoutPag } from './getAllReclamationsWithoutPag';
// import { reducer as getReclamationsBySousProg } from './getReclamationsBySousProg';

export default combineReducers({
  getReclamations,
  addNewReclamation,
  editReclamation,
  getReclamationsWithoutPag
  // getReclamationsBySousProg
});
