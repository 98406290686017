import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line 
import { push } from 'react-router-redux';
import getAllConventionsNoPagActions, {
  getAllConventionsNoPagTypes
} from '../../../store/reducers/conventionReducers/getConventionsNoPag';
import instance from '../../../axios';
import alertActions from '../../../store/reducers/alertReducers';
import { errorMessageToReturn } from '../../../shared/utility';
/* eslint no-underscore-dangle: 0 */

function* getAllConventionsNoPagSagas() {
  try {
    const url = '/api/convention/all';
    const res = yield instance.get(url);
    if (res.status === 200) {
      yield put(getAllConventionsNoPagActions.getAllConventionsNoPagSuccess(res));
    }
    else {
      yield put(getAllConventionsNoPagActions.getAllConventionsNoPagFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecté
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autorisé
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getAllConventionsNoPagActions.getAllConventionsNoPagFailure(errorMessage));
  }
}

export function* getAllConventionsNoPagSaga() {
  yield takeLatest(
    getAllConventionsNoPagTypes.GET_ALL_CONVENTIONS_NO_PAG_REQUEST,
    getAllConventionsNoPagSagas
  );
}
