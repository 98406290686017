import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import { errorMessageToReturn, removeSpecialChars } from '../../../shared/utility';
import getAllReclamationActions, {
  getAllReclamationTypes
} from '../../../store/reducers/reclamationReducers/getAllReclamations';
import alertActions from '../../../store/reducers/alertReducers';
import instance from '../../../axios';
/* eslint no-underscore-dangle: 0 */

function* getAllReclamationsSagas({ response }) {
  try {
    let url;
    if ((response.payload.searchValue === '') && (response.payload.key === '')) {
      url = '?page='
        + response.payload.activePage
        + '&order='
        + (response.payload.order ? (response.payload.order) : 'desc')
        + '&key='
        + (response.payload.key ? (response.payload.key) : '')
        + '&limit='
        + response.payload.itemsPerPage;
    }
    else  {
      url = '?page='
        + response.payload.activePage
        + '&limit='
        + (response.payload.itemsPerPage ? response.payload.itemsPerPage : 5)
        + '&texte='
        + removeSpecialChars(response.payload.searchValue)
        + '&categorie='
        + response.payload.categorie
        + '&order='
        + (response.payload.order ? (response.payload.order) : 'desc')
        + '&key='
        + (response.payload.key ? (response.payload.key) : '')
        + '&convention='
        + response.payload.convention;
    }

    const res = yield instance.get('api/reclamation/' + url);
    if (res.status === 200) {
      yield put(getAllReclamationActions.getAllReclamationsSuccess(res.data));
      if (response.payload.redirect === true) {
        yield put(push('/reclamation/list'));
      }
    }
    else {
      yield put(getAllReclamationActions.getAllReclamationsFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getAllReclamationActions.getAllReclamationsFailure(errorMessage));
  }
}

export function* getAllReclamationsSaga() {
  yield takeLatest(
    getAllReclamationTypes.GET_ALL_RECLAMATIONS_REQUEST,
    getAllReclamationsSagas
  );
}
