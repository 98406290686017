/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
// import { Link } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Logo from '../../assets/images/logo-sigap-2.png';
import Notifications from './notifications/notifications';
import { getByEcran, getPermission } from '../../shared/utility';
import instance from '../../axios';


class Header extends Component {
  static propTypes = {
    user: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      fichier: '',
    }
  };

  componentDidMount() {
    instance.get('/api/users/guide').then((res) => {
      this.setState({
        fichier: res,
      });
    });
   
    };

  static defaultProps = {
    user: {}
  };

  render() {
    const { user } =  this.props;
    return (
      <Fragment>
        
        <nav
          className="navbar navbar-expand-lg bg-success ">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo03"
            aria-controls="navbarTogglerDemo03"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <a className="navbar-brand">
            <img src={Logo} height="40" className="d-inline-block align-top" alt="" />
            {' '}
             Système Intégré de Gestion de l’Aide Publique au Mali (SIGAP)
          </a>
          <ul className="navbar-nav flex-row ml-md-auto d-none d-md-flex">

            {(getPermission('convention', user, 'Validate')
             || getPermission('decaissement', user, 'Validate')
              || getPermission('reclamation', user, 'Edit')
              || user.details.user_roles[0].role === 'SHA') && <Notifications />}
            <li className="nav-item">
              <a
                className="nav-link p-2"
                rel="noopener"
                aria-label="Profil">
                <i className="fa fa-user" />
                { user.details.name}

              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="dropdown01"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Configuration
              </a>
              <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdown01"
              >
                {
          getByEcran('user', user) ? (
            <Link to="/user/list" className="dropdown-item">
              <i className="fas fa-users" />
                  Utilisateurs
            </Link>
          ) : ''
        }
                {
          getByEcran('role', user) ? (
            <Link to="/user/roles/list" className="dropdown-item">
              <i className="fas fa fa-id-badge" />
            roles
            </Link>
          ) : ''
                  }
                {
        getByEcran('permission', user) ? (
          <Link to="/user/permissions/list" className="dropdown-item">
            <i className="fas fa fa-unlock" />
          Permissions
          </Link>
        ) : ''
        }

<a href={this.state.fichier.data} target="_blank">

                <div style={{ padding: 22 }}>
                    <i className="fas fa-info" />
                    Guide d'utilisation
                </div>
                </a>

                <Link to="/logout" className="dropdown-item">
                  <i className="fas fa-sign-out-alt" />
                  Déconnexion
                </Link>
              </div>
            </li>
          </ul>
        </nav>
      </Fragment>
    );
  }
}

export default Header;
