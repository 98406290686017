import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  alertShow: [
    'warning',
    'error',
    'success',
    'info',
    'title',
    'message',
    'onConfirm'
  ],
  alertHide: ['']
});

export const alertTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  warning: false,
  show: false,
  error: false,
  success: false,
  info: false,
  title: '',
  message: '',
  onConfirm: null
});

/* ------------- Reducers ------------- */

const alertHide = state => state.merge({ show: false });

const alertShow = (
  state,
  {
    warning, error, success, info, title, message, onConfirm
  }
) => state.merge({
  warning,
  error,
  success,
  info,
  title,
  message,
  onConfirm,
  show: true
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ALERT_SHOW]: alertShow,
  [Types.ALERT_HIDE]: alertHide
});
