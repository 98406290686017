
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* eslint no-underscore-dangle: 0 */
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getAllActivityCsvRequest: ['response'],
  getAllActivityCsvSuccess: ['response', 'loading'],
  getAllActivityCsvFailure: ['error']
});

export const getAllActivityCsvTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null,
  totalItems: 0,
  activePage: 1,
  itemsPerPage: 5
});

/* ------------- Reducers ------------- */
const getAllActivityCsvSuccess = (state, { response }) => {
  return state.merge({
    error: false,
    response: response.data,
    loading: false,
    activePage: response.meta.page,
    total: response.meta.total,
    itemsPerPage: response.meta.limit

  });
};
const getAllActivityCsvFailure = (state, { error }) => state.merge({
  loading: false,
  error: error
});
const getAllActivityCsvRequest = (state) => {
  return state.merge({ loading: true });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ALL_ACTIVITY_CSV_REQUEST]: getAllActivityCsvRequest,
  [Types.GET_ALL_ACTIVITY_CSV_SUCCESS]: getAllActivityCsvSuccess,
  [Types.GET_ALL_ACTIVITY_CSV_FAILURE]: getAllActivityCsvFailure
});
