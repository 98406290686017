import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import scrollUp from '../../assets/images/scroll-arrow-to-up.svg';

const Img = styled.img`
  display: inline-block;
  vertical-align: middle;
`;
class ScrollButton extends React.Component {
  constructor() {
    super();
    this.state = {
      intervalId: 0
    };
  }

  componentWillMount() {}

  scrollStep() {
    const { intervalId } = this.state;
    const { scrollStepInPx } = this.props;

    if (window.pageYOffset === 0) {
      clearInterval(intervalId);
    }
    window.scroll(0, window.pageYOffset - scrollStepInPx);
  }

  scrollToTop() {
    const { delayInMs } = this.props;

    const intervalId = setInterval(this.scrollStep.bind(this), delayInMs);
    this.setState({ intervalId });
  }

  render() {
    const { opacity } = this.props;
    const Button = styled.button`
      border-width: 1px;
      margin-bottom: 7px;
      opacity: ${() => (opacity - 100) * 0.01};
      display: ${() => (opacity - 100 > 0 ? 'block' : 'none')};
    `;

    return (
      <div>
        <Button
          className="scroll"
          opacity={opacity}
          onClick={() => {
            this.scrollToTop();
          }}
        >
          <Img alt="scroll button" src={scrollUp} className="scrollUp" />
        </Button>
      </div>
    );
  }
}
ScrollButton.defaultProps = {
  delayInMs: 0,
  opacity: 0,
  scrollStepInPx: 0
};

ScrollButton.propTypes = {
  delayInMs: PropTypes.string,
  opacity: PropTypes.number,
  scrollStepInPx: PropTypes.string
};
export default ScrollButton;
