
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* eslint no-underscore-dangle: 0 */
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getOrganismesByPaginationRequest: ['response'],
  getOrganismesByPaginationSuccess: ['response', 'loading'],
  getOrganismesByPaginationFailure: ['error']
});

export const getOrganismesByPaginationTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null
});

/* ------------- Reducers ------------- */
const getOrganismesByPaginationSuccess = (state, { response }) => {
  return state.merge({
    error: false,
    response: response.data,
    loading: false,
    activePage: response.meta.page,
    total: response.meta.total,
    itemsPerPage: response.meta.limit

  });
};
const getOrganismesByPaginationFailure = (state, { error }) => state.merge({
  loading: false,
  error: error
});
const getOrganismesByPaginationRequest = (state) => {
  return state.merge({ loading: true });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ORGANISMES_BY_PAGINATION_REQUEST]: getOrganismesByPaginationRequest,
  [Types.GET_ORGANISMES_BY_PAGINATION_SUCCESS]: getOrganismesByPaginationSuccess,
  [Types.GET_ORGANISMES_BY_PAGINATION_FAILURE]: getOrganismesByPaginationFailure
});
