import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getFilesRequest: ['response'],
  getFilesSuccess: ['response', 'loading'],
  getFilesFailure: ['error']
});

export const getFilesTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null
});

/* ------------- Reducers ------------- */

const getFilesRequest = state => state.merge({ loading: true });

const getFilesSuccess = (state, { response }) => state.merge({
  error: false,
  response,
  loading: false
});

const getFilesFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_FILES_REQUEST]: getFilesRequest,
  [Types.GET_FILES_SUCCESS]: getFilesSuccess,
  [Types.GET_FILES_FAILURE]: getFilesFailure
});
