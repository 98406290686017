import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import { errorMessageToReturn } from '../../../shared/utility';
import editUserActions, {
  editUserTypes
} from '../../../store/reducers/UsersReducers/editUser';
import getUsersActions from '../../../store/reducers/UsersReducers/getUsers';
import alertActions from '../../../store/reducers/alertReducers';
import instance from '../../../axios';
/* eslint no-underscore-dangle: 0 */

function* getUserByIdSagas({ response }) {
  try {
    const res = yield instance.get('/api/users/' + response.payload.id);
    if (res.status === 200 || res.status === 201) {
      yield put(editUserActions.getUserByIdSuccess(res.data));
    }
    else {
      yield put(editUserActions.getUserByIdFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/logout'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(editUserActions.getUserByIdFailure(errorMessage));
  }
}
function* editUserSagas({ response }) {
  try {
    const res = yield instance.patch(
      '/api/users/edit/' + response.id,
      response
    );
    if (res.status === 200 || res.status === 201) {
      yield put(editUserActions.editUserSuccess(res.data));
      yield put(
        alertActions.alertShow(
          false,
          false,
          true,
          false,
          'Succès',
          'Utilisateur  est modifié avec succès'
        )
      );
      yield put(getUsersActions.getUsersRequest({
        payload: {
          activePage: 1, itemPerPage: 5, searchValue: '', role: 0
        }
      }));
      yield put(push('/user/list'));
    }
    else {
      yield put(editUserActions.editUserFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/logout'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(editUserActions.editUserFailure(errorMessage));
  }
}

export function* editUserSaga() {
  yield takeLatest(
    editUserTypes.GET_USER_BY_ID_REQUEST,
    getUserByIdSagas
  );
  yield takeLatest(editUserTypes.EDIT_USER_REQUEST, editUserSagas);
}
