import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  removeRoleRequest: ['response'],
  removeRoleSuccess: ['response'],
  removeRoleFailure: ['error']
});
export const removeRoleTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null
});

/* ------------- Reducers ------------- */

const removeRoleSuccess = (state, {
  response
}) => {
  return state.merge({
    error: false,
    response: response,
    loading: false
  });
};
const removeRoleFailure = (state, { error }) => state.merge({
  loading: false,
  error: error
});
const removeRoleRequest = (state) => {
  return state.merge({ loading: true });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REMOVE_ROLE_REQUEST]: removeRoleRequest,
  [Types.REMOVE_ROLE_SUCCESS]: removeRoleSuccess,
  [Types.REMOVE_ROLE_FAILURE]: removeRoleFailure

});
