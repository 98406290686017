import { takeLatest, put, call } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import getAllReferencesActions, {
  getAllReferenceTypes
} from '../../../store/reducers/referencialReducers/getAllReferencial';
import alertActions from '../../../store/reducers/alertReducers';
import instance from '../../../axios';
import { errorMessageToReturn } from '../../../shared/utility';
import authActions from '../../../store/reducers/authReducers/auth/auth';

export function renameKeys(obj) {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = obj[key].id || key.substring(31, key.length);
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
}
function* getByPaginationReferencialSagas(payload) {
  try {
    let result = '?';
    Object.keys(payload).map((key, index) => {
      if (key !== 'type' && payload[key] != null) {
        if (index !== 1) {
          result += '&';
        }
        result += key;
        result += '=';
        result += payload[key];
      }
      return result;
    });

    const response = yield instance.get('api/referenciel/' + result);
    if (response.status === 200) {
      yield put(
        getAllReferencesActions.getReferenceByPaginationSuccess(response.data)
      );
    }
    else {
      yield put(
        getAllReferencesActions.getReferenceByPaginationFailure(response)
      );
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    // yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecté
    // alert(error.response.data.code);
    if (error.response.data.code === 401) {
      yield put(authActions.authLogout());
      yield put(push('/auth'));
    }
    // user non autorisé
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getAllReferencesActions.getAllReferenceFailure(errorMessage));
  }
}
function* getAllReferencialSagas(payload) {
  try {
    const response = yield instance.get('api/referenciel/all');
    if (response.status === 200) {
      const referenciels = renameKeys(response.data.referenciels); //serveur
      //const referenciels = response.data.referenciels; //locale
      yield put(getAllReferencesActions.getAllReferenceSuccess({
        categories: response.data.categories,
        referenciels: referenciels
      }));

      if (window.location.pathname.indexOf('/referencial/') > -1) {
        yield put(push('/referencial'));
      }
      else if (payload.response && typeof payload.response.showIt !== 'undefined') {
        if (payload.response.showIt) {
          yield put(alertActions.alertShow(false,  false,  true,  false,  'Succès',  'Page rafraîchie avec succès'));
        }
      }
    }
    else {
      yield put(getAllReferencesActions.getAllReferenceFailure(response));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    // yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecté
    if (error.response.data.code === 401) {
      yield put(authActions.authLogout());
      yield put(push('/auth'));
    }
    // user non autorisé
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getAllReferencesActions.getAllReferenceFailure(errorMessage));
  }
}

export function* getAllReferencialSaga() {
  yield takeLatest(
    getAllReferenceTypes.GET_ALL_REFERENCE_REQUEST,
    getAllReferencialSagas
  );
  yield takeLatest(
    getAllReferenceTypes.GET_REFERENCE_BY_PAGINATION_REQUEST,
    getByPaginationReferencialSagas
  );
}

