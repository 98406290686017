import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';

import getUsersActions, {
  getUsersTypes
} from '../../../store/reducers/UsersReducers/getUsers';
import alertActions from '../../../store/reducers/alertReducers';
import instance from '../../../axios';
/* eslint no-underscore-dangle: 0 */

function* getUsersSagas({ response }) {
  try {
    let url;
    if (
      (response.payload.searchValue === '') && (response.payload.key === '')
    ) {
      url =        '?page='
        + response.payload.activePage
        + '&limit='
        + response.payload.itemPerPage;
    }
    else {
      url =        '?page='
        + response.payload.activePage
        + '&limit='
        + response.payload.itemPerPage
        + '&username='
        + response.payload.searchValue
        + '&order='
        + (response.payload.order ? (response.payload.order) : 'desc')
        + '&key='
        + (response.payload.key ? (response.payload.key) : '')  
        + '&name='
        + response.payload.searchValue
        + '&surname='
        + response.payload.searchValue;
    }
    const res = yield instance.get('/api/users/bypage' + url);
    if (res.status === 200) {
      yield put(getUsersActions.getUsersSuccess(res.data));
      if (response.navigate === true) {
        yield put(push('/user/list'));
      }
    }
    else {
      yield put(getUsersActions.getUsersFailure(res));
    }
  }
  catch (error) {
    yield put(
      alertActions.alertShow(
        false,
        true,
        false,
        false,
        'Erreur',
        error.toString()
      )
    );
    yield put(getUsersActions.getUsersFailure(error.toString()));
  }
}

export function* getUsersSaga() {
  yield takeLatest(getUsersTypes.GET_USERS_REQUEST, getUsersSagas);
}
