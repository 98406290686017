import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import { errorMessageToReturn } from '../../../shared/utility';
import addNewActivityCsvActions, {
  addNewActivityCsvTypes
} from '../../../store/reducers/activityCsvReducers/addActivityCsv';
import  getAllActivityCsvActions from '../../../store/reducers/activityCsvReducers/getAllActivityCsv';
import alertActions from '../../../store/reducers/alertReducers';
import instance from '../../../axios';
/* eslint no-underscore-dangle: 0 */

function* addNewActivityCsvSagas({ response }) {
  try {
    const res = yield instance.post(
      '/api/activite/csv/add/', response
    );
    if (res.status === 200 || res.status === 201) {
      yield put(addNewActivityCsvActions.addNewActivityCsvSuccess(res.data));
      yield put(
        alertActions.alertShow(
          false,
          false,
          true,
          false,
          'Succès',
          'Activité est ajoutée avec succès'
        )
      );
      yield put(getAllActivityCsvActions.getAllActivityCsvRequest({
        payload: {
          activePage: 1,
          itemsPerPage: 5
        }
      }));
      yield put(push('/activitycsv/list'));
    }
    else {
      yield put(addNewActivityCsvActions.addNewActivityCsvFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(addNewActivityCsvActions.addNewActivityCsvFailure(errorMessage));
  }
}
export function* addNewActivityCsvSaga() {
  yield takeLatest(
    addNewActivityCsvTypes.ADD_NEW_ACTIVITY_CSV_REQUEST,
    addNewActivityCsvSagas
  );
}
