import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line

import { push } from 'react-router-redux';
import getCsvActions, {
  getCsvTypes
} from '../../../store/reducers/csvReducers/getCsv';
import alertActions from '../../../store/reducers/alertReducers';
import instance from '../../../axios';
import { errorMessageToReturn } from '../../../shared/utility';

/* eslint no-underscore-dangle: 0 */
function* getCsvSagas({ response }) {
  try {
    const url = '?page='
        + response.activePage
        + '&limit='
        + response.limit
        + (response.libelle ? '&libelle=' + response.libelle : '')
        + '&order='
        + (response.order ? (response.order) : 'desc')
        + '&key='
        + (response.key ? (response.key) : '')
        + (response.rna ? '&rna=' + response.rna : '');

    const res = yield instance.get('/api/projet/projetcsv/bypage' + url);

    if (res.status === 200 || res.status === 201) {
      yield put(getCsvActions.getCsvSuccess(res));
    }
    else {
      yield put(getCsvActions.getCsvFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecté
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autorisé
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getCsvActions.getCsvFailure(errorMessage));
  }
}

export function* getCsvSaga() {
  yield takeLatest(
    getCsvTypes.GET_CSV_REQUEST,
    getCsvSagas
  );
}
