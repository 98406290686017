import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  addCsvRequest: ['response'],
  addCsvSuccess: ['response', 'loading'],
  addCsvFailure: ['error']
});

export const addCsvTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null
});

/* ------------- Reducers ------------- */

const addCsvRequest = state => state.merge({ loading: true });

const addCsvSuccess = (state, { response }) => state.merge({
  error: false,
  response,
  loading: false
});

const addCsvFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_CSV_REQUEST]: addCsvRequest,
  [Types.ADD_CSV_SUCCESS]: addCsvSuccess,
  [Types.ADD_CSV_FAILURE]: addCsvFailure
});
