import { combineReducers } from 'redux';
import { reducer as getPermission } from './getPermission';
import { reducer as getPermissions } from './getPermissions';
import { reducer as addPermission } from './addPermission';

export default combineReducers({
  getPermission,
  getPermissions,
  addPermission
});
