import { normalize } from 'normalizr';
import { camelizeKeys } from 'humps';
import { CALL_API, API_ROOT } from '../constantes/Networks';

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
const callApi = (endpoint, schema) => {
  const fullUrl =    endpoint.indexOf(API_ROOT) === -1 ? API_ROOT + endpoint : endpoint;

  return fetch(fullUrl)
    .then(response => response.json(), error => Promise.reject(error))
    .then(
      (json) => {
        const camelizedJson = camelizeKeys(json);
        // TODO implementation nextPageUrl
        // const nextPageUrl = getNextPageUrl(response);

        return Object.assign(
          {},
          schema ? normalize(camelizedJson, schema) : camelizedJson
        );
        // { nextPageUrl });
      },
      error => Promise.reject(error)
    );
};

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default store => next => (action) => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  let { endpoint } = callAPI;
  const { schema, types } = callAPI;

  if (typeof endpoint === 'function') {
    endpoint = endpoint(store.getState());
  }

  let apiPromise = null;
  if (typeof endpoint === 'string') {
    if (!Array.isArray(types) || types.length !== 3) {
      throw new Error('Expected an array of three action types.');
    }
    if (!types.every(type => typeof type === 'string')) {
      throw new Error('Expected action types to be strings.');
    }

    apiPromise = callApi(endpoint, schema);
  }
  else if (Array.isArray(endpoint)) {
    apiPromise = Promise.all(
      endpoint.map((resource) => {
        return new Promise(resource);
      })
    );
  }
  else {
    throw new Error(
      'Specify either a string endpoint URL or Array of function.'
    );
  }

  const actionWith = (data) => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;
  next(actionWith({ type: requestType }));

  if (apiPromise) {
    return apiPromise
      .then(
        response => next(
          actionWith({
            response,
            type: successType
          })
        ),
        error => next(
          actionWith({
            type: failureType,
            error: error.message || 'Error'
          })
        )
      )
      .catch(error => next(
        actionWith({
          type: failureType,
          error: error.message || 'Error'
        })
      ));
  }
  next(
    actionWith({
      type: failureType,
      error: 'Error api promises'
    })
  );
};
