import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getDecaissementsRequest: ['response'],
  getDecaissementsSuccess: ['response'],
  getDecaissementsFailure: ['error']
});

export const getDecaissementTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null,
  totalItems: 0,
  activePage: 1,
  itemPerPage: 5
});

/* ------------- Reducers ------------- */
const getDecaissementsSuccess = (state, {
  response
}) => {
  return state.merge({
    error: false,
    response: response.data,
    loading: false,
    activePage: response.meta.page,
    total: response.meta.total,
    itemsPerPage: response.meta.limit
  });
};
const getDecaissementsFailure = (state, { error }) => state.merge({
  loading: false,
  error: error
});
const getDecaissementsRequest = (state) => {
  return state.merge({ loading: true });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_DECAISSEMENTS_REQUEST]: getDecaissementsRequest,
  [Types.GET_DECAISSEMENTS_SUCCESS]: getDecaissementsSuccess,
  [Types.GET_DECAISSEMENTS_FAILURE]: getDecaissementsFailure

});
