import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import { errorMessageToReturn } from '../../../shared/utility';

import getAllBudgetYearActions, {
  getAllBudgetYearsTypes
} from '../../../store/reducers/budgetYearReducers/getBudgetYear';
import alertActions from '../../../store/reducers/alertReducers';
import instance from '../../../axios';
/* eslint no-underscore-dangle: 0 */
export function renameKeys(obj) {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = obj[key].id || key.substring(29, key.length);
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
}
function* getAllBudgetYearSagas({ response }) {
  try {
    const res = yield instance.get('api/anneeBudgetaire/all');
    if (res.status === 200 || res.status === 201) {
      yield put(getAllBudgetYearActions.getAllBudgetYearsSuccess(res.data));

      if (response.navigate === true) {
        yield put(push('/anneebudgetaire/list'));
      }
    }
    else {
      yield put(getAllBudgetYearActions.getAllBudgetYearsFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getAllBudgetYearActions.getAllBudgetYearsFailure(errorMessage));
  }
}

export function* getAllBudgetYearSaga() {
  yield takeLatest(
    getAllBudgetYearsTypes.GET_ALL_BUDGET_YEARS_REQUEST,
    getAllBudgetYearSagas
  );
}
