import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import getActivityByIdActions, {
  editActivityTypes
} from '../../../store/reducers/activityReducers/editActivity';
import  getAllActivityActions from '../../../store/reducers/activityReducers/getAllActivity';
// import getOrganismesByPaginationRequestActions from '../../../store/reducers/organismeReducers/getOrganismesByPagination';
import instance from '../../../axios';
import alertActions from '../../../store/reducers/alertReducers';
import { errorMessageToReturn } from '../../../shared/utility';

/* eslint no-underscore-dangle: 0 */

function* getActivityByIdSagas(payload) {
  try {
    const res = yield instance.get('/api/activite/get/' + payload.response.payload.id);
    if (res.status === 200 || res.status === 201) {
      yield put(getActivityByIdActions.getActivityByIdSuccess(res.data));
    }
    else {
      yield put(getActivityByIdActions.getActivityByIdFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getActivityByIdActions.getActivityByIdFailure(errorMessage));
  }
}

function* editActivitySagas({ response }) {
  const newResponse = {  libelle: response.libelle, projet: { id: response.projet.id, libelle: response.projet.libelle } };
  try {
    const res = yield instance.patch('/api/activite/' + response.id, newResponse);
    if (res.status === 200 || res.status === 201) {
      yield put(getActivityByIdActions.editActivitySuccess(res.data));
      yield put(
        alertActions.alertShow(
          false,
          false,
          true,
          false,
          'Succès',
          'Activité est modifié avec succès'
        )
      );
      yield put(getAllActivityActions.getAllActivityRequest({
        payload: {
          activePage: 1,
          itemsPerPage: 5
        }
      }));
      yield put(push('/activity/list'));
    }
    else {
      yield put(getActivityByIdActions.editActivityFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getActivityByIdActions.editActivityFailure(errorMessage));
  }
}

export function* editActivitySaga() {
  yield takeLatest(
    editActivityTypes.GET_ACTIVITY_BY_ID_REQUEST,
    getActivityByIdSagas
  );
  yield takeLatest(
    editActivityTypes.EDIT_ACTIVITY_REQUEST,
    editActivitySagas
  );
}
