import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import alertActions from '../../../store/reducers/alertReducers';
import { errorMessageToReturn } from '../../../shared/utility';

import getOrganismesByPaginationRequestActions from '../../../store/reducers/organismeReducers/getOrganismesByPagination';
import addNewOrganismeActions, {
  addNewOrganismeTypes
} from '../../../store/reducers/organismeReducers/addOrganisme';
import instance from '../../../axios';
import getAllOrganismestActions from '../../../store/reducers/organismeReducers/getAllOrganismes';

/* eslint no-underscore-dangle: 0 */
function* addNewOrganismeSagas({ response }) {
  if (Object.keys(response.ptf_categorie).length === 0) {
    delete response.ptf_categorie;
  }
  if (Object.keys(response.ptf_type).length === 0) {
    delete response.ptf_type;
  }
  if (Object.keys(response.structure_etat).length === 0) {
    delete response.structure_etat;
  }
  try {
    const res = yield instance.post('/api/organisme/add/', response);

    if (res.status === 200 || res.status === 201) {
      yield put(
        alertActions.alertShow(
          false,
          false,
          true,
          false,
          'Succès',
          'Organisme est ajouté avec succès'
        )
      );
      yield put(addNewOrganismeActions.addNewOrganismeSuccess(res.data));

      yield put(
        getOrganismesByPaginationRequestActions.getOrganismesByPaginationRequest(
          {
            limit: 5,
            activePage: 1,
            navigate: true
          }
        )
      );
      yield put(
        getAllOrganismestActions.getAllOrganismesRequest()
      );
    }
    else {
      yield put(addNewOrganismeActions.addNewOrganismeFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(addNewOrganismeActions.addNewOrganismeFailure(errorMessage));
  }
}

export function* addNewOrganismeSaga() {
  yield takeLatest(
    addNewOrganismeTypes.ADD_NEW_ORGANISME_REQUEST,
    addNewOrganismeSagas
  );
}
