import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getPermissionsRequest: ['response'],
  getPermissionsSuccess: ['response'],
  getPermissionsFailure: ['error']
});
export const permissionsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null
});

/* ------------- Reducers ------------- */

const getPermissionsSuccess = (state, {
  response
}) => {
  return state.merge({
    error: false,
    response: response,
    loading: false
  });
};
const getPermissionsFailure = (state, { error }) => state.merge({
  loading: false,
  error: error
});
const getPermissionsRequest = (state) => {
  return state.merge({ loading: true });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PERMISSIONS_REQUEST]: getPermissionsRequest,
  [Types.GET_PERMISSIONS_SUCCESS]: getPermissionsSuccess,
  [Types.GET_PERMISSIONS_FAILURE]: getPermissionsFailure

});
