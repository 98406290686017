import { takeLatest, put, call, all } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import { errorMessageToReturn } from '../../../shared/utility';
import deleteActivityCsvActions, {
  deleteActivityCsvTypes
} from '../../../store/reducers/activityCsvReducers/deleteActivityCsv';
import  getAllActivityCsvActions from '../../../store/reducers/activityCsvReducers/getAllActivityCsv';
import instance from '../../../axios';
import alertActions from '../../../store/reducers/alertReducers';

function* deleteActivityCsvSagas(response) {
  try {
    const responseDelete = yield instance.delete(
      '/api/activite/csv/delete/' + response.response.id
    );
    if (responseDelete.status === 200) {
      yield all([
        yield put(
          alertActions.alertShow(false,false,true,false,'Succès','Activité est supprimée avec succès')
        ),
        yield put(
          deleteActivityCsvActions.deleteActivityCsvSuccess(responseDelete.data)
        ),
        yield put(getAllActivityCsvActions.getAllActivityCsvRequest({
          payload: {
            activePage: 1,
            itemsPerPage: 5
          }
        })),
        yield put(push('/activitycsv/list'))

      ]);
    }
    else {
      yield put(
        deleteActivityCsvActions.deleteActivityCsvFailure(responseDelete.data)
      );
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(deleteActivityCsvActions.deleteActivityCsvFailure(errorMessage));
  }
}

export function* deleteActivityCsvSaga() {
  yield takeLatest(
    deleteActivityCsvTypes.DELETE_ACTIVITY_CSV_REQUEST,
    deleteActivityCsvSagas
  );
}
