
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* eslint no-underscore-dangle: 0 */
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getActivityCsvWithoutPagRequest: ['response'],
  getActivityCsvWithoutPagSuccess: ['response', 'loading'],
  getActivityCsvWithoutPagFailure: ['error']
});

export const getActivityCsvWithoutPagTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null
});

/* ------------- Reducers ------------- */
const getActivityCsvWithoutPagSuccess = (state, { response }) => {
  return state.merge({
    error: false,
    response: response,
    loading: false
  });
};
const getActivityCsvWithoutPagFailure = (state, { error }) => state.merge({
  loading: false,
  error: error
});
const getActivityCsvWithoutPagRequest = (state) => {
  return state.merge({ loading: true });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ACTIVITY_CSV_WITHOUT_PAG_REQUEST]: getActivityCsvWithoutPagRequest,
  [Types.GET_ACTIVITY_CSV_WITHOUT_PAG_SUCCESS]: getActivityCsvWithoutPagSuccess,
  [Types.GET_ACTIVITY_CSV_WITHOUT_PAG_FAILURE]: getActivityCsvWithoutPagFailure
});
