import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line
import { push } from "react-router-redux";
import { errorMessageToReturn } from "../../../shared/utility";
import editDecaissementActions, {
  editDecaissementTypes,
} from "../../../store/reducers/decaissementReducers/updateDecaissement";
import alertActions from "../../../store/reducers/alertReducers";
import getNotificationsActions from "../../../store/reducers/notificationsReducers/getNotifications";
import getDecaissementActions from "../../../store/reducers/decaissementReducers/getDecaissements";
import instance from "../../../axios";
/* eslint no-underscore-dangle: 0 */

function* getDecaissementByIdSagas({ response }) {
  try {
    const res = yield instance.get("/api/decaissement/" + response.payload.id);
    if (res.status === 200 || res.status === 201) {
      yield put(editDecaissementActions.getDecaissementByIdSuccess(res.data));
    } else {
      yield put(editDecaissementActions.getDecaissementByIdFailure(res));
    }
  } catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(
      alertActions.alertShow(false, true, false, false, "Erreur", errorMessage)
    );
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push("/logout"));
    }
    if (error.response.data.code === 403) {
      yield put(push("/"));
    }
    yield put(editDecaissementActions.getDecaissementByIdFailure(errorMessage));
  }
}
function* editDecaissementSagas({ response }) {
  try {
    const res = yield instance.patch(
      "/api/decaissement/" + response.id,
      response
    );
    if (res.status === 200 || res.status === 201) {
      yield put(editDecaissementActions.editDecaissementSuccess(res.data));
      yield put(getNotificationsActions.getNotificationsRequest());

      yield put(
        alertActions.alertShow(
          false,
          false,
          true,
          false,
          "Succès",
          "Décaissement  est " +
            (response.validate ? "validé" : "modifié") +
            " avec succès"
        )
      );
      yield put(
        getDecaissementActions.getDecaissementsRequest({
          payload: { activePage: 1, itemsPerPage: 5 },
        })
      );
      yield put(push("/decaissement/list"));
    } else {
      yield put(editDecaissementActions.editDecaissementFailure(res));
    }
  } catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(
      alertActions.alertShow(false, true, false, false, "Erreur", errorMessage)
    );
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push("/logout"));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push("/"));
    }
    yield put(editDecaissementActions.editDecaissementFailure(errorMessage));
  }
}

export function* editDecaissementSaga() {
  yield takeLatest(
    editDecaissementTypes.GET_DECAISSEMENT_BY_ID_REQUEST,
    getDecaissementByIdSagas
  );
  yield takeLatest(
    editDecaissementTypes.EDIT_DECAISSEMENT_REQUEST,
    editDecaissementSagas
  );
}
