import {
  takeLatest,
  put
  } from "redux-saga/effects"; // eslint-disable-line

import { push } from 'react-router-redux';
import getNotificationsActions, {
  getNotificationsTypes
} from '../../../store/reducers/notificationsReducers/getNotifications';
import instance from '../../../axios';
/* eslint no-underscore-dangle: 0 */
function* getNotificationsSagas() {
  try {
    const res = yield instance.get('api/notification/all');

    if (res.status === 200) {
      yield put(getNotificationsActions.getNotificationsSuccess(res.data));
    }
    else {
      yield put(getNotificationsActions.getNotificationsFailure(res));
    }
  }
  catch (error) {
    // const errorMessage = errorMessageToReturn(error);
    // yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecté
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autorisé
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    // yield put(
    //   getNotificationsActions.getNotificationsFailure(errorMessage)
    // );
  }
}

export function* getNotificationsSaga() {
  yield takeLatest(
    getNotificationsTypes.GET_NOTIFICATIONS_REQUEST,
    getNotificationsSagas
  );
}

