import React, { Component, Fragment } from 'react';
import Alert from '../../components/UI/Alert';

const withErrorHandler = (WrappedComponent, instance) => {
  return class extends Component {
        state = {
          error: null
        }

        componentWillUnmount() {
          instance.interceptors.request.eject(this.reqInterceptor);
          instance.interceptors.response.eject(this.resInterceptor);
        }

    errorConfirmedHandler = () => {
      this.setState({ error: null });
    };

    render() {
      const { error } = this.state;
      return (
        <Fragment>
          <Alert error={error !== null} message={error} title="Error" />
          <WrappedComponent {...this.props} />
        </Fragment>
      );
    }
  };
};
export default withErrorHandler;
