import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  addNewDecaissementRequest: ['response'],
  addNewDecaissementSuccess: ['response', 'loading'],
  addNewDecaissementFailure: ['error']
});

export const addNewDecaissementTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null
});

/* ------------- Reducers ------------- */

const addNewDecaissementRequest = state => state.merge({ loading: true });

const addNewDecaissementSuccess = (state, { response }) => state.merge({
  error: false,
  response,
  loading: false
});

const addNewDecaissementFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_NEW_DECAISSEMENT_REQUEST]: addNewDecaissementRequest,
  [Types.ADD_NEW_DECAISSEMENT_SUCCESS]: addNewDecaissementSuccess,
  [Types.ADD_NEW_DECAISSEMENT_FAILURE]: addNewDecaissementFailure
});
