// import { delay } from 'redux-saga';
import { takeLatest, put, call, all, takeEvery } from "redux-saga/effects"; // eslint-disable-line
// import { push } from 'react-router-redux';
import { errorMessageToReturn } from '../../../../shared/utility';
import instance from '../../../../axios';
import permissionsActions, {
  permissionsTypes
} from '../../../../store/reducers/authReducers/permissions/getPermissions';

function* getPermissions() {
  const url = '/api/user/permissions/';
  try {
    const res = yield instance.get(url);
    if (res.status === 200) {
      yield put(permissionsActions.getPermissionsSuccess(res.data));
    }
  }
  catch (error) {
    errorMessageToReturn(error);
  }
}
export function* permissions() {
  yield takeLatest(
    permissionsTypes.GET_PERMISSIONS_REQUEST,
    getPermissions
  );
}
