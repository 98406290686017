
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* eslint no-underscore-dangle: 0 */
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getBudgetYearsRequest: ['response'],
  getBudgetYearsSuccess: ['response', 'loading'],
  getBudgetYearsFailure: ['error']
});

export const getBudgetYearsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null,
  total: 0,
  activePage: 1,
  itemsPerPage: 5

});

/* ------------- Reducers ------------- */
const getBudgetYearsSuccess = (state, { response }) => {
  return state.merge({
    error: false,
    response: response.data,
    loading: false,
    activePage: response.meta.page,
    total: response.meta.total,
    itemsPerPage: response.meta.limit
  });
};
const getBudgetYearsFailure = (state, { error }) => state.merge({
  loading: false,
  error: error
});
const getBudgetYearsRequest = (state) => {
  return state.merge({ loading: true });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_BUDGET_YEARS_REQUEST]: getBudgetYearsRequest,
  [Types.GET_BUDGET_YEARS_SUCCESS]: getBudgetYearsSuccess,
  [Types.GET_BUDGET_YEARS_FAILURE]: getBudgetYearsFailure
});
