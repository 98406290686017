import { combineReducers } from 'redux';
import { reducer as allBudgetYears } from './getBudgetYear';
import { reducer as budgetYears } from './getBudgetYearWithPag';
import { reducer as addBudgetYear } from './addBudgetYear';
import { reducer as editBudgetYear } from './editBudgetYear';

export default combineReducers({
  allBudgetYears,
  budgetYears,
  addBudgetYear,
  editBudgetYear

});
