import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line 
import { push } from 'react-router-redux';
import getActivityWithoutPagActions, {
  getActivityWithoutPagTypes
} from '../../../store/reducers/activityReducers/getActivityWithoutPag';
import instance from '../../../axios';
import alertActions from '../../../store/reducers/alertReducers';
import { errorMessageToReturn } from '../../../shared/utility';
/* eslint no-underscore-dangle: 0 */

function* getActivityWithoutPagSagas() {
  try {
    const res = yield instance.get('/api/activite/all');
    if (res.status === 200 || res.status === 201) {
      yield put(getActivityWithoutPagActions.getActivityWithoutPagSuccess(res.data));
    }
    else {
      yield put(getActivityWithoutPagActions.getActivityWithoutPagFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getActivityWithoutPagActions.getActivityWithoutPagFailure(errorMessage));
  }
}

export function* getActivityWithoutPagSaga() {
  yield takeLatest(
    getActivityWithoutPagTypes.GET_ACTIVITY_WITHOUT_PAG_REQUEST,
    getActivityWithoutPagSagas
  );
}
