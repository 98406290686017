import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  addNewReclamationRequest: ['response'],
  addNewReclamationSuccess: ['response', 'loading'],
  addNewReclamationFailure: ['error']
});

export const addNewReclamationTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null
});

/* ------------- Reducers ------------- */

const addNewReclamationRequest = state => state.merge({ loading: true });

const addNewReclamationSuccess = (state, { response }) => state.merge({
  error: false,
  response,
  loading: false
});

const addNewReclamationFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_NEW_RECLAMATION_REQUEST]: addNewReclamationRequest,
  [Types.ADD_NEW_RECLAMATION_SUCCESS]: addNewReclamationSuccess,
  [Types.ADD_NEW_RECLAMATION_FAILURE]: addNewReclamationFailure
});
