import { fork, all, call, take } from "redux-saga/effects"; // eslint-disable-line
import { getAllReferencialSaga } from './referencialSaga/getAllReferencialSaga';
import { addNewReferencialSaga } from './referencialSaga/addNewReferencialSaga';
import { editReferencialSaga } from './referencialSaga/editReferencialSaga';
import { deleteReferencialSaga } from './referencialSaga/deleteReferencialSaga';
import { addNewOrganismeSaga } from './organismeSaga/addOrganismeSaga';
import { editOrganismeSaga } from './organismeSaga/editOrganismeSaga';
import { getOrganismesByPaginationSaga } from './organismeSaga/index';
import { getOrganismesSaga } from './organismeSaga/getAllOrganismes/index';
import { deleteOrganismeSaga } from './organismeSaga/deleteOrganisme';
import { addNewConventionSaga } from './conventionSaga/addConventionSaga';
import { editConventionSaga } from './conventionSaga/editConventionSaga';
import { detailsConventionSaga } from './conventionSaga/detailsConventionSaga';
import { getAllConventionsSaga } from './conventionSaga/index';
import { getAllConventionsNoPagSaga } from './conventionSaga/getAllConventionsNoPagSaga';
import { deleteConventionSaga } from './conventionSaga/deleteConvention';
import auth from './authSaga/index';
import users from './userSaga/index';
import notifications from './notificationsSaga/index';
import { getAllBudgetYearSaga } from './budgetYearSaga/getAllBudgetYearSaga';
import { getBudgetYearsSaga } from './budgetYearSaga/index';
import { addNewBudgetYearSaga } from './budgetYearSaga/addBudgetYearSaga';
import { editBudgetYearSaga } from './budgetYearSaga/editBudgetYearSaga';
import { getAllDecaissementsSaga } from './decaissementSaga/getAllDecaissementSaga';
import { getDecaissementsByCommunesSaga } from './decaissementSaga/getDecaissementsByCommunesSaga';
import { deleteDecaissementSaga } from './decaissementSaga/deleteDecaissementSaga';
import { addNewDecaissementSaga } from './decaissementSaga/addNewDecaissementSaga';
import { editDecaissementSaga } from './decaissementSaga/editDecaissementSaga';
import { getProjectsSaga } from './projectSaga/getProjectsSaga';
import { editProjectSaga } from './projectSaga/editProjectSaga';
import { getAllProjectsSaga } from './projectSaga/getAllProjectsSaga';
import { getAllReclamationsSaga } from './reclamationSaga/getAllReclamationSaga';
import { addNewReclamationSaga } from './reclamationSaga/addNewReclamationSaga';
import { editReclamationSaga } from './reclamationSaga/editReclamationSaga';
import { getDecaissementsSaga } from './decaissementSaga/getDecaissementSaga';
import { getStatistiquesSaga } from './getStatistiquesSaga';
import { editActivitySaga } from './activitySaga/editActivitySaga';
import { addNewActivitySaga } from './activitySaga/addNewActivitySaga';
import { deleteActivitySaga } from './activitySaga/deleteActivitySaga';
import { getAllActivitySaga } from './activitySaga/getAllActivitySaga';
import { editActivityCsvSaga } from './activityCsvSaga/editActivityCsvSaga';
import { addNewActivityCsvSaga } from './activityCsvSaga/addNewActivityCsvSaga';
import { deleteActivityCsvSaga } from './activityCsvSaga/deleteActivityCsvSaga';
import { getAllActivityCsvSaga } from './activityCsvSaga/getAllActivityCsvSaga';
import { addCsvSaga } from './csvSaga/addCsvSaga';
import { deleteCsvSaga } from './csvSaga/deleteCsvSaga';
import { editCsvSaga } from './csvSaga/editCsvSaga';
import { getCsvSaga } from './csvSaga/getCsvSaga';
import { getAllCsvSaga } from './csvSaga/getAllCsvSaga';
import { generateCsvSaga } from './csvSaga/generateCsv';
import { getActivityWithoutPagSaga } from './activitySaga/getActivityWithoutPag';
import { getActivityCsvWithoutPagSaga } from './activityCsvSaga/getActivityCsvWithoutPag';

// import { getAllReclamationsWithoutPagSagas } from './reclamationSaga/getAllReclamationsWithoutPagSaga';

export const takeFirst = (pattern, saga, ...args) => fork(function* first() {
  while (true) {
    const action = yield take(pattern);
    yield call(saga, ...args.concat(action));
  }
});

const sagas = [
  ...users,
  ...notifications,
  getStatistiquesSaga,
  getAllProjectsSaga,
  getProjectsSaga,
  editProjectSaga,
  getDecaissementsSaga,
  getAllDecaissementsSaga,
  deleteDecaissementSaga,
  editDecaissementSaga,
  getAllBudgetYearSaga,
  getBudgetYearsSaga,
  addNewBudgetYearSaga,
  editBudgetYearSaga,
  getAllConventionsSaga,
  getAllConventionsNoPagSaga,
  addNewConventionSaga,
  editConventionSaga,
  detailsConventionSaga,
  deleteConventionSaga,
  getOrganismesByPaginationSaga,
  getOrganismesSaga,
  addNewOrganismeSaga,
  editOrganismeSaga,
  deleteOrganismeSaga,
  getAllReferencialSaga,
  addNewReferencialSaga,
  ...auth,
  editReferencialSaga,
  deleteReferencialSaga,
  getAllReclamationsSaga,
  addNewReclamationSaga,
  editReclamationSaga,
  addNewDecaissementSaga,
  getDecaissementsByCommunesSaga,
  getAllActivitySaga,
  getActivityWithoutPagSaga,
  addNewActivitySaga,
  deleteActivitySaga,
  editActivitySaga,
  getAllActivityCsvSaga,
  getActivityCsvWithoutPagSaga,
  addNewActivityCsvSaga,
  deleteActivityCsvSaga,
  editActivityCsvSaga,
  addCsvSaga,
  editCsvSaga,
  getCsvSaga,
  getAllCsvSaga,
  deleteCsvSaga,
  generateCsvSaga
];

function* globalSagas() {
  const globalSagasForks = sagas.map(saga => fork(saga));
  yield all([...globalSagasForks]);
}

export default globalSagas;
