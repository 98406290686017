import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import instance from '../../axios';
import ScrollButton from './ScrollButton';
import MoreInfoButton from '../../components/otherComponents/MoreInfoButton';

class footer extends Component {
  static propTypes = {
    isLogged: PropTypes.bool
  };

  static defaultProps = {
    isLogged: false
  };

  constructor() {
    super();
    this.state = { opacityBoutton: 0 };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.resize);
    instance.interceptors.response.use(
      (response) => {
        // place to add auth header
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.forceUpdate();
  };

  handleScroll = () => {
    this.setState({
      opacityBoutton: window.pageYOffset
    });
  };

  render() {
    const { opacityBoutton } = this.state;
    const { isLogged } = this.props;

    return (
      <footer className="footerGbo">
        <p className="footerCopyright">
          <a href="https://gfi.world/tn-fr/" target="_blank">
          Powered By Inetum 2021
          </a>
        </p>

        <div className="scrollButtonContainer">
          <div className="scrollButton">
            {isLogged && (
              <ScrollButton
                opacity={opacityBoutton}
                scrollStepInPx="50"
                delayInMs="20"
              />
            )}
            {isLogged && <MoreInfoButton />}
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = state => ({
  isLogged: state.auth.auth.token !== null
});

export default connect(
  mapStateToProps,
  null
)(footer);
