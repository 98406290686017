import { takeLatest, put, call, all, takeEvery } from "redux-saga/effects"; // eslint-disable-line
import instance from '../../../axios';
import forgotPasswordActions, {
  forgotPasswordTypes
} from '../../../store/reducers/authReducers/forgotPassword';
import alertActions from '../../../store/reducers/alertReducers';
import { errorMessageToReturn } from '../../../shared/utility';

function* forgotPasswordSagas({ response }) {
  const url = '/api/users/sendmail';
  try {
    yield instance.post(url, response.payload);
    yield put(
      alertActions.alertShow(
        false,
        false,
        true,
        false,
        'Envoyé',
        'Veuillez vérifiez votre boîte mail pour un email de récupération de mot de passe.'
      )
    );
    yield put(forgotPasswordActions.forgotPasswordSuccess());
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    yield put(forgotPasswordActions.forgotPasswordFailure(errorMessage));
  }
}

export function* forgotPasswordSaga() {
  yield all([
    yield takeLatest(forgotPasswordTypes.FORGOT_PASSWORD_REQUEST, forgotPasswordSagas)
  ]);
}
