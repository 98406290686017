import React from 'react';

import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getByEcran } from '../../../shared/utility';

const sidebare = ({ user }) => {
  return (
    <div id="sidebar" className="sidebar-sticky">
      <ul className="nav nav-pills nav-stacked">

        <li>
          <NavLink to="/">Tableau de bord </NavLink>
        </li>

        <li>
          <NavLink to="/statistiques/list"> Tableau de statistiques</NavLink>
        </li>

        {
          getByEcran('convention', user) ? (
            <li>
              <NavLink to="/convention/list">Gestion des conventions </NavLink>
            </li>
          ) : ''
          }
        {
          getByEcran('projet', user) ? (
            <li>
              <NavLink to="/project/list">Liste des projets</NavLink>
            </li>
          ) : ''
          }
        {
      getByEcran('activite', user) ? (
        <li>
          <NavLink to="/activity/list">Liste des activités</NavLink>
        </li>
      ) : ''
        }
        {
        getByEcran('projet-csv', user) ? (
          <li>
            <NavLink to="/projectCsv/list">Collecte des projets</NavLink>
          </li>
        ) : ''
        }
        {
      getByEcran('activite-csv', user) ? (
        <li>
          <NavLink to="/activitycsv/list">Collecte des activités</NavLink>
        </li>
      ) : ''
        }

        {
          getByEcran('decaissement', user) ? (
            <li>
              <NavLink to="/decaissement/list">Gestion des décaissements</NavLink>
            </li>
          ) : ''
          }
        {
          getByEcran('annee-budgetaire', user) ? (

            <li>
              <NavLink to="/anneebudgetaire/list">Gestion des années budgétaires</NavLink>
            </li>
          ) : ''
          }
        {
          getByEcran('reclamation', user) ? (

            <li>
              <NavLink to="/reclamation/list">Gestion des réclamations</NavLink>
            </li>
          ) : ''
          }
        {
          getByEcran('organisme', user) ? (

            <li>
              <NavLink to="/organisme/list">Gestion des organismes</NavLink>
            </li>
          ) : ''
          }
        {
          getByEcran('referenciel', user) ? (

            <li>
              <NavLink to="/referencial">Gestion des référentiels</NavLink>
            </li>
          ) : ''
          }
        {
          getByEcran('user', user) ? (

            <li>
              <NavLink to="/user/list">Gestion des utilisateurs  </NavLink>
            </li>
          ) : ''
          }

      </ul>
    </div>
  );
};

export default sidebare;
sidebare.propTypes = {
  user: PropTypes.object
};

sidebare.defaultProps = {
  user: {}
};

