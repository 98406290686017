// import { delay } from 'redux-saga';
import { takeLatest, put, call, all, takeEvery } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import { errorMessageToReturn } from '../../../../shared/utility';
import instance from '../../../../axios';
import rolesActions, {
  rolesTypes
} from '../../../../store/reducers/authReducers/roles/getRoles';
import alertActions from '../../../../store/reducers/alertReducers';

function* getRoles() {
  const url = '/api/user/roles/';
  try {
    const res = yield instance.get(url);
    if (res.status === 200) {
      yield put(rolesActions.getRolesSuccess(res.data));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
  }
}
export function* roles() {
  yield takeLatest(
    rolesTypes.GET_ROLES_REQUEST,
    getRoles
  );
}
