import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import alertActions from '../../../store/reducers/alertReducers';

import editReferencesActions, {
  editReferenceTypes
} from '../../../store/reducers/referencialReducers/editReferencial';
import getAllReferencesActions from '../../../store/reducers/referencialReducers/getAllReferencial';
import instance from '../../../axios';
import { errorMessageToReturn } from '../../../shared/utility';

function* editReferencialSagas({ response }) {
  const newResponse = {
    intitulefr: response.intitulefr,
    categorie: response.categorie,
    parent: response.parent
  };

  try {
    const responseEdit = yield instance.put(
      'api/referenciel/' + response.id,
      newResponse
    );
    if (responseEdit.status === 200) {
      yield put(
        alertActions.alertShow(
          false,
          false,
          true,
          false,
          'Succès',
          'Réferentiel est modifié avec succès'
        )
      );
      yield put(
        getAllReferencesActions.getReferenceByPaginationRequest(
          1,
          5,
          null,
          null
        )
      );
      yield put(editReferencesActions.editReferenceSuccess(responseEdit.data));
      yield put(getAllReferencesActions.getAllReferenceRequest());
      yield put(push('/referencial'));
    }
    else {
      yield put(editReferencesActions.editReferenceFailure(responseEdit.data));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecté
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autorisé
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(editReferencesActions.editReferenceFailure(errorMessage));
  }
}

export function* editReferencialSaga() {
  yield takeLatest(
    editReferenceTypes.EDIT_REFERENCE_REQUEST,
    editReferencialSagas
  );
}
