import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  editUserRequest: ['response'],
  editUserSuccess: ['loading', 'response'],
  editUserFailure: ['error'],

  getUserByIdRequest: ['response'],
  getUserByIdSuccess: ['response', 'loading'],
  getUserByIdFailure: ['error']
});

export const editUserTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null
});

/* ------------- Reducers ------------- */

const editUserRequest = state => state.merge({ loading: true });

const editUserSuccess = (state, { response }) => state.merge({
  error: false,
  loading: false,
  response
});

const editUserFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

const getUserByIdRequest = state => state.merge({ loading: true });

const getUserByIdSuccess = (state, { response }) => state.merge({
  error: false,
  loading: false,
  response
});

const getUserByIdFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.EDIT_USER_REQUEST]: editUserRequest,
  [Types.EDIT_USER_SUCCESS]: editUserSuccess,
  [Types.EDIT_USER_FAILURE]: editUserFailure,

  [Types.GET_USER_BY_ID_REQUEST]: getUserByIdRequest,
  [Types.GET_USER_BY_ID_SUCCESS]: getUserByIdSuccess,
  [Types.GET_USER_BY_ID_FAILURE]: getUserByIdFailure
});
