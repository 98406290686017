import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import { errorMessageToReturn } from '../../../shared/utility';
import getBudgetYearByIdActions, {
  editBudgetYearTypes
} from '../../../store/reducers/budgetYearReducers/editBudgetYear';
import getAllBudgetYearsActions from '../../../store/reducers/budgetYearReducers/getBudgetYear';
import instance from '../../../axios';
import alertActions from '../../../store/reducers/alertReducers';
/* eslint no-underscore-dangle: 0 */

function* getBudgetYearByIdSagas(payload) {
  try {
    const res = yield instance.get('/api/anneeBudgetaire/' +  payload.response.payload.id);
    if (res.status === 200 || res.status === 201) {
      yield put(getBudgetYearByIdActions.getBudgetYearByIdSuccess(res.data));
      yield put(push('/anneebudgetaire/list'));

    }
    else {
      yield put(getBudgetYearByIdActions.getBudgetYearByIdFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getBudgetYearByIdActions.getBudgetYearByIdFailure(errorMessage));
  }
}

function* editBudgetYearSagas({ response }) {
  const NewResponse = { etat: response.etat };
  try {
    const res = yield instance.patch('/api/anneeBudgetaire/' + response.id, NewResponse);
    if (res.status === 200 || res.status === 201) {
      yield put(getBudgetYearByIdActions.editBudgetYearSuccess(res.data));
      yield put(
        alertActions.alertShow(
          false,
          false,
          true,
          false,
          'Succès',
          'Année budgétaire est ' + (response.etat === 0 ? 'clôturée' : 'mise en cours') + ' avec succès'
        )
      );
      yield put(
        getAllBudgetYearsActions.getAllBudgetYearsRequest({
          activePage: 1, limit: 5, navigate: true
        })
      );
    }
    else {
      yield put(getBudgetYearByIdActions.editBudgetYearFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getBudgetYearByIdActions.editBudgetYearFailure(errorMessage));
  }
}

export function* editBudgetYearSaga() {
  yield takeLatest(
    editBudgetYearTypes.GET_BUDGET_YEAR_BY_ID_REQUEST,
    getBudgetYearByIdSagas
  );
  yield takeLatest(
    editBudgetYearTypes.EDIT_BUDGET_YEAR_REQUEST,
    editBudgetYearSagas
  );
}
