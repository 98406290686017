import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getUsersRequest: ['response'],
  getUsersSuccess: ['response'],
  getUsersFailure: ['error']
});

export const getUsersTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null,
  totalItems: 0,
  activePage: 1,
  itemPerPage: 5
});

/* ------------- Reducers ------------- */
const getUsersSuccess = (state, { response }) => {
  return state.merge({
    error: false,
    response: response.data,
    loading: false,
    activePage: response.meta.page,
    totalItems: response.meta.total,
    itemPerPage: response.meta.limit
  });
};
const getUsersFailure = (state, { error }) => state.merge({
  loading: false,
  error: error
});
const getUsersRequest = (state) => {
  return state.merge({ loading: true });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_USERS_REQUEST]: getUsersRequest,
  [Types.GET_USERS_SUCCESS]: getUsersSuccess,
  [Types.GET_USERS_FAILURE]: getUsersFailure
});
