import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import { errorMessageToReturn } from '../../../shared/utility';
import alertActions from '../../../store/reducers/alertReducers';
import getAllBudgetyearsActions  from '../../../store/reducers/budgetYearReducers/getBudgetYear';
import getBudgetYearsActions  from '../../../store/reducers/budgetYearReducers/getBudgetYearWithPag';
import addNewBudgetyearActions, { addNewBudgetYearTypes } from '../../../store/reducers/budgetYearReducers/addBudgetYear';
import instance from '../../../axios';
/* eslint no-underscore-dangle: 0 */

function* addNewBudgetYearSagas({ response }) {
  try {
    const res = yield instance.post('/api/anneeBudgetaire/add/', response);

    if (res.status === 200 || res.status === 201) {
      yield put(alertActions.alertShow(false, false, true, false, 'Succès', 'Année budgétaire est ajoutée avec succès'));
      yield put(addNewBudgetyearActions.addNewBudgetYearSuccess(res.data));
      yield put(getAllBudgetyearsActions.getAllBudgetYearsRequest({ limit: 5, activePage: 1, navigate: true }));
      yield put(getBudgetYearsActions.getBudgetYearsRequest());
      yield put(push('/anneebudgetaire/list'));
    }
    else {
      yield put(addNewBudgetyearActions.addNewBudgetYearFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(addNewBudgetyearActions.addNewBudgetYearFailure(errorMessage));
  }
}

export function* addNewBudgetYearSaga() {
  yield takeLatest(
    addNewBudgetYearTypes.ADD_NEW_BUDGET_YEAR_REQUEST,
    addNewBudgetYearSagas
  );
}
