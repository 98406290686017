import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line 
import { push } from 'react-router-redux';
import getOrganismesByPaginationActions, {
  getOrganismesByPaginationTypes
} from '../../store/reducers/organismeReducers/getOrganismesByPagination/index';
import instance from '../../axios';
import alertActions from '../../store/reducers/alertReducers';
import { errorMessageToReturn } from '../../shared/utility';
/* eslint no-underscore-dangle: 0 */

function* getOrganismesByPaginationSagas({ response }) {
  try {
    let url;
    if (response.searchValue === '' && response.searchValue === undefined && response === null && response.key === '' && response.key === undefined) {
      url = '/api/organisme/'
      + '?limit='
      + response.limit
      + '&page='
      + response.activePage;
    }
 
    else {
      url = '/api/organisme/'
      + '?limit='
      + response.limit
      + '&page='
      + response.activePage
      + '&order='
      + (response.order ? (response.order) : 'desc')
      + '&key='
      + (response.key ? (response.key) : '')  
      + '&designation='
      + (response.searchValue ? response.searchValue : '');
    }

    const res = yield instance.get(url);
    if (res.status === 200) {
      yield put(getOrganismesByPaginationActions.getOrganismesByPaginationSuccess(res.data));
      if (response.navigate === true) {
        yield put(push('/organisme/list'));
      }
    }
    else {
      yield put(getOrganismesByPaginationActions.getOrganismesByPaginationFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }

    yield put(getOrganismesByPaginationActions.getOrganismesByPaginationFailure(errorMessage));
  }
}

export function* getOrganismesByPaginationSaga() {
  yield takeLatest(
    getOrganismesByPaginationTypes.GET_ORGANISMES_BY_PAGINATION_REQUEST,
    getOrganismesByPaginationSagas
  );
}
