import { combineReducers } from 'redux';
import { reducer as getCsv } from './getCsv';
import { reducer as getAllCsv } from './getAllCsv';
import { reducer as addCsv } from './addCsv';
import { reducer as editCsv } from './editCsv';
import { reducer as deleteCsv } from './deleteCsv';
import { reducer as generateCsv } from './generateCsv';

export default combineReducers({
  getCsv,
  getAllCsv,
  addCsv,
  editCsv,
  deleteCsv,
  generateCsv
});
