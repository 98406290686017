import { combineReducers } from 'redux';
import { reducer as getAllActivityCsv } from './getAllActivityCsv';
import { reducer as editActivityCsv } from './editActivityCsv';
import { reducer as deleteActivityCsv } from './deleteActivityCsv';
import { reducer as addActivityCsv } from './addActivityCsv';
import { reducer as getActivityCsvWithoutPag } from './getActivityCsvWithoutPag';

export default combineReducers({
  getAllActivityCsv,
  editActivityCsv,
  deleteActivityCsv,
  addActivityCsv,
  getActivityCsvWithoutPag
});
