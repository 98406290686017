import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  detailsConventionRequest: ['response'],
  detailsConventionSuccess: ['loading', 'response'],
  detailsConventionFailure: ['error'],
  getConventionByIdRequest: ['response'],
  getConventionByIdSuccess: ['response', 'loading'],
  getConventionByIdFailure: ['error']
});

export const detailsConventionTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: true
});

/* ------------- Reducers ------------- */

const detailsConventionRequest = state => state.merge({ loading: true });

const detailsConventionSuccess = (state, { response }) => state.merge({
  error: false,
  loading: false,
  response
});

const detailsConventionFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

const getConventionByIdRequest = state => state.merge({ loading: true });

const getConventionByIdSuccess = (state, { response }) => state.merge({
  error: false,
  loading: false,
  response
});

const getConventionByIdFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.DETAILS_CONVENTION_REQUEST]: detailsConventionRequest,
  [Types.DETAILS_CONVENTION_SUCCESS]: detailsConventionSuccess,
  [Types.DETAILS_CONVENTION_FAILURE]: detailsConventionFailure,

  [Types.GET_CONVENTION_BY_ID_REQUEST]: getConventionByIdRequest,
  [Types.GET_CONVENTION_BY_ID_SUCCESS]: getConventionByIdSuccess,
  [Types.GET_CONVENTION_BY_ID_FAILURE]: getConventionByIdFailure
});
