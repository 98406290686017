import { combineReducers } from 'redux';
import { reducer as allOrganismes } from './getAllOrganismes';
import { reducer as addOrganisme } from './addOrganisme';
import { reducer as editOrganisme } from './editOrganisme';
import { reducer as deleteOrganisme } from './deleteOrganisme';
import { reducer as getOrganismesByPagination } from './getOrganismesByPagination';

export default combineReducers({
  allOrganismes,
  addOrganisme,
  editOrganisme,
  deleteOrganisme,
  getOrganismesByPagination
});
