import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import { errorMessageToReturn } from '../../../shared/utility';
import editReclamationActions, {
  editReclamationTypes
} from '../../../store/reducers/reclamationReducers/editReclamation';
import alertActions from '../../../store/reducers/alertReducers';
import instance from '../../../axios';
import getAllReclamationActions from '../../../store/reducers/reclamationReducers/getAllReclamations';
/* eslint no-underscore-dangle: 0 */

function* getReclamationByIdSagas({ response }) {
  // console.log('response', response);

  try {
    const res = yield instance.get('/api/reclamation/' + response.payload.id);

    if (res.status === 200 || res.status === 201) {
      yield put(editReclamationActions.getReclamationByIdSuccess(res.data));
    }
    else {
      yield put(editReclamationActions.getReclamationByIdFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(editReclamationActions.getReclamationByIdFailure(errorMessage));
  }
}
function* editReclamationSagas({ response }) {
  try {
    const res = yield instance.patch(
      '/api/reclamation/' + response.payload.idreclam,
      response.payload.reclamation
    );

    if (res.status === 200 || res.status === 201) {
      yield put(editReclamationActions.editReclamationSuccess(res.data));
      yield put(getAllReclamationActions.getAllReclamationsRequest({ payload: { limit: 5, activePage: 1, redirect: true } }));
      yield put(
        alertActions.alertShow(false, false, true, false, 'Succès', 'Réclamation est modifiée avec succès')
      );
    }
    else {
      yield put(editReclamationActions.editReclamationFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(editReclamationActions.editReclamationFailure(errorMessage));
  }
}

export function* editReclamationSaga() {
  yield takeLatest(
    editReclamationTypes.GET_RECLAMATION_BY_ID_REQUEST,
    getReclamationByIdSagas
  );
  yield takeLatest(editReclamationTypes.EDIT_RECLAMATION_REQUEST, editReclamationSagas);
}
