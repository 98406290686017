import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import getConventionByIdActions, { editConventionTypes } from '../../../store/reducers/conventionReducers/editConvention';
import getAllConventionsActions from '../../../store/reducers/conventionReducers/getConventions';
import instance from '../../../axios';
import alertActions from '../../../store/reducers/alertReducers';
import { errorMessageToReturn } from '../../../shared/utility';
import getNotificationsActions from '../../../store/reducers/notificationsReducers/getNotifications';
/* eslint no-underscore-dangle: 0 */
import getAllConventionsNoPagActions from '../../../store/reducers/conventionReducers/getConventionsNoPag';

function* getConventionByIdSagas(payload) {
  try {
    const res = yield instance.get('/api/convention/' +  payload.response.id);
    if (res.status === 200 || res.status === 201) {
      yield put(getConventionByIdActions.getConventionByIdSuccess(res.data));
    }
    else {
      yield put(getConventionByIdActions.getConventionByIdFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecté
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autorisé
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getConventionByIdActions.editConventionFailure(errorMessage));
  }
}

function* editConventionSagas({ response }) {
  try {
    const res = yield instance.patch('/api/convention/' + response.id, response);
    if (res.status === 200 || res.status === 201) {
      const msg = 'Convention est ' + (response.validate ? 'validée' : 'modifiée') + ' avec succès';
      yield put(getNotificationsActions.getNotificationsRequest());
      yield put(alertActions.alertShow(false, false, true, false, 'Succès', msg));
      yield put(getConventionByIdActions.editConventionSuccess(res.data));
      yield put(getAllConventionsActions.getAllConventionsRequest({ activePage: 1, limit: 5, navigate: true }));
      yield put(getAllConventionsNoPagActions.getAllConventionsNoPagRequest());
    }
    else {
      yield put(getConventionByIdActions.editConventionFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecté
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autorisé
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getConventionByIdActions.editConventionFailure(errorMessage));
  }
}

export function* editConventionSaga() {
  yield takeLatest(
    editConventionTypes.GET_CONVENTION_BY_ID_REQUEST,
    getConventionByIdSagas
  );
  yield takeLatest(
    editConventionTypes.EDIT_CONVENTION_REQUEST,
    editConventionSagas
  );
}
