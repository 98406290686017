import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import { errorMessageToReturn } from '../../../shared/utility';
import getDecaissementActions, {
  getDecaissementTypes
} from '../../../store/reducers/decaissementReducers/getDecaissements';
import alertActions from '../../../store/reducers/alertReducers';
import instance from '../../../axios';
/* eslint no-underscore-dangle: 0 */

function* getDecaissementsSagas({ response }) {
  try {
    let url;
    if (
      typeof response.payload.libelle === 'undefined'
      || !response.payload.libelle
    ) {
      url =        '?page='
        + response.payload.activePage
        + '&limit='
        + response.payload.itemsPerPage
        + '&order='
        + (response.payload.order ? (response.payload.order) : 'desc')
        + '&key='
        + (response.payload.key ? (response.payload.key) : '')
        + '&convention='
        + response.payload.convention;
    }
    else {
      url =        '?page='
        + response.payload.activePage
        + '&limit='
        + response.payload.itemsPerPage
        + '&id='
        + response.payload.libelle
        + '&order='
        + (response.payload.order ? (response.payload.order) : 'desc')
        + '&key='
        + (response.payload.key ? (response.payload.key) : '')
        + '&convention='
        + response.payload.convention;
    }
    const res = yield instance.get('/api/decaissement' + url);
    if (res.status === 200) {
      yield put(getDecaissementActions.getDecaissementsSuccess(res.data));
      if (response.navigate === true) {
        yield put(push('/decaissement/list'));
      }
    }
    else {
      yield put(getDecaissementActions.getDecaissementsFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/logout'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getDecaissementActions.getDecaissementsFailure(errorMessage));
  }
}

export function* getDecaissementsSaga() {
  yield takeLatest(
    getDecaissementTypes.GET_DECAISSEMENTS_REQUEST,
    getDecaissementsSagas
  );
}
