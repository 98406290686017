import { combineReducers } from 'redux';
import { reducer as getAllDecaissements } from './getAllDecaissements';
import { reducer as getDecaissements } from './getDecaissements';
import { reducer as addNewDecaissement } from './addNewDecaissement';
import { reducer as updateDecaissement } from './updateDecaissement';
import { reducer as deleteDecaissement } from './deleteDecaissement';
import { reducer as getDecaissementsByCommunes } from './getDecaissementsByCommunes';

export default combineReducers({
  getAllDecaissements,
  getDecaissements,
  addNewDecaissement,
  updateDecaissement,
  deleteDecaissement,
  getDecaissementsByCommunes
});
