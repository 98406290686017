import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  deleteActivityRequest: ['response'],
  deleteActivitySuccess: ['response'],
  deleteActivityFailure: ['error']
});

export const deleteActivityTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null
});

/* ------------- Reducers ------------- */

const deleteActivityRequest = state => state.merge({ loading: true });

const deleteActivitySuccess = (state, { response }) => state.merge({
  error: false,
  response,
  loading: false
});

const deleteActivityFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.DELETE_ACTIVITY_REQUEST]: deleteActivityRequest,
  [Types.DELETE_ACTIVITY_SUCCESS]: deleteActivitySuccess,
  [Types.DELETE_ACTIVITY_FAILURE]: deleteActivityFailure
});
