import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line 
import { push } from 'react-router-redux';
import getActivityCsvWithoutPagActions, {
  getActivityCsvWithoutPagTypes
} from '../../../store/reducers/activityCsvReducers/getActivityCsvWithoutPag';
import instance from '../../../axios';
import alertActions from '../../../store/reducers/alertReducers';
import { errorMessageToReturn } from '../../../shared/utility';
/* eslint no-underscore-dangle: 0 */

function* getActivityCsvWithoutPagSagas() {
  try {
    const res = yield instance.get('/api/activite/csv/all');
    if (res.status === 200 || res.status === 201) {
      yield put(getActivityCsvWithoutPagActions.getActivityCsvWithoutPagSuccess(res.data));
    }
    else {
      yield put(getActivityCsvWithoutPagActions.getActivityCsvWithoutPagFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getActivityCsvWithoutPagActions.getActivityCsvWithoutPagFailure(errorMessage));
  }
}

export function* getActivityCsvWithoutPagSaga() {
  yield takeLatest(
    getActivityCsvWithoutPagTypes.GET_ACTIVITY_CSV_WITHOUT_PAG_REQUEST,
    getActivityCsvWithoutPagSagas
  );
}
