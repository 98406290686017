import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  addNewConventionRequest: ['response'],
  addNewConventionSuccess: ['response', 'loading'],
  addNewConventionFailure: ['error']
});

export const addNewConventionTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null
});

/* ------------- Reducers ------------- */

const addNewConventionRequest = state => state.merge({
  loading: true
});

const addNewConventionSuccess = (state, { response }) => state.merge({
  error: false,
  response,
  loading: false
});

const addNewConventionFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_NEW_CONVENTION_REQUEST]: addNewConventionRequest,
  [Types.ADD_NEW_CONVENTION_SUCCESS]: addNewConventionSuccess,
  [Types.ADD_NEW_CONVENTION_FAILURE]: addNewConventionFailure
});
