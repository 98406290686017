import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  editCsvRequest: ['response'],
  editCsvSuccess: ['loading', 'response'],
  editCsvFailure: ['error'],
  getCsvByIdRequest: ['response'],
  getCsvByIdSuccess: ['response', 'loading'],
  getCsvByIdFailure: ['error']
});

export const editCsvTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null,
  missions: null
});

/* ------------- Reducers ------------- */

const editCsvRequest = state => state.merge({ loading: true });

const editCsvSuccess = (state, { response }) => state.merge({
  error: false,
  response
});

const editCsvFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

const getCsvByIdRequest = state => state.merge({ loading: true });

const getCsvByIdSuccess = (state, { response }) => state.merge({
  error: false,
  loading: false,
  response
});

const getCsvByIdFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.EDIT_CSV_REQUEST]: editCsvRequest,
  [Types.EDIT_CSV_SUCCESS]: editCsvSuccess,
  [Types.EDIT_CSV_FAILURE]: editCsvFailure,

  [Types.GET_CSV_BY_ID_REQUEST]: getCsvByIdRequest,
  [Types.GET_CSV_BY_ID_SUCCESS]: getCsvByIdSuccess,
  [Types.GET_CSV_BY_ID_FAILURE]: getCsvByIdFailure
});
