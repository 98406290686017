// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import WebFont from 'webfontloader';
import $ from 'jquery/src/jquery';
import Popper from './popper';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-material-design/dist/css/bootstrap-material-design.min.css';

import 'bootstrap-material-design';
import './App.css';
import authActions from '../../store/reducers/authReducers/auth/auth';
import Layout from '../../hoc/Layouts/Layout';
import instance from '../../axios';
// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';

// window.jQuery = window.$ =  $;
window.jQuery = window.$ = $ // eslint-disable-line
window.Popper = Popper;// eslint-disable-line
WebFont.load({
  google: {
    families: ['Roboto:100,300,400,500,700']
  }
});
$(document).ready(() => {
  $('body').bootstrapMaterialDesign();
});
class App extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    loading: PropTypes.bool,
    user: PropTypes.object,
    onTryAutoSignup: PropTypes.func.isRequired,
    history: PropTypes.object
  };

  static defaultProps = {
    isAuthenticated: false,
    loading: false,
    user: {},
    history: {}
  };

  componentWillMount() {
    const { token } = localStorage;
    const { history } = this.props;
    if (token && typeof token !== 'undefined') {
      instance.defaults.headers.Authorization = 'Bearer ' + token;
    }
    else if (window.location.href.includes('user/changepassword/?token=') === false)       history.push('/auth');
  }

  componentDidMount() {
    const { onTryAutoSignup } = this.props;
    const { token } = localStorage;
    if (token && typeof token !== 'undefined') onTryAutoSignup();
  }

  render() {
    const { isAuthenticated, user, loading } = this.props;
    return <Layout isLogued={isAuthenticated} userInfo={user} loading={loading} />;
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.auth.token !== null,
    user: state.auth.auth.user,
    loading: !state.referencial.allReferencials.response
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(authActions.authCheckState())
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
