import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  forgotPasswordRequest: ['response'],
  forgotPasswordSuccess: ['response'],
  forgotPasswordFailure: ['error']
});
export const forgotPasswordTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  loading: null
});
const forgotPasswordRequest = state => state.merge({
  loading: true
});

const forgotPasswordSuccess = state => state.merge({
  loading: false
});

const forgotPasswordFailure = state => state.merge({
  loading: false
});

export const reducer = createReducer(INITIAL_STATE, {
  [forgotPasswordTypes.FORGOT_PASSWORD_REQUEST]: forgotPasswordRequest,
  [forgotPasswordTypes.FORGOT_PASSWORD_SUCCESS]: forgotPasswordSuccess,
  [forgotPasswordTypes.FORGOT_PASSWORD_FAILURE]: forgotPasswordFailure
});
