import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import getActivityCsvByIdActions, {
  editActivityCsvTypes
} from '../../../store/reducers/activityCsvReducers/editActivityCsv';
import  getAllActivityCsvActions from '../../../store/reducers/activityCsvReducers/getAllActivityCsv';
// import getOrganismesByPaginationRequestActions from '../../../store/reducers/organismeReducers/getOrganismesByPagination';
import instance from '../../../axios';
import alertActions from '../../../store/reducers/alertReducers';
import { errorMessageToReturn } from '../../../shared/utility';

/* eslint no-underscore-dangle: 0 */

function* getActivityCsvByIdSagas(payload) {
  try {
    const res = yield instance.get('/api/activite/csv/get/' + payload.response.payload.id);
    if (res.status === 200 || res.status === 201) {
      yield put(getActivityCsvByIdActions.getActivityCsvByIdSuccess(res.data));
    }
    else {
      yield put(getActivityCsvByIdActions.getActivityCsvByIdFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getActivityCsvByIdActions.getActivityCsvByIdFailure(errorMessage));
  }
}

function* editActivityCsvSagas({ response }) {
  try {
    const res = yield instance.patch('/api/activite/csv/' + response.id, response);
    if (res.status === 200 || res.status === 201) {
      yield put(getActivityCsvByIdActions.editActivityCsvSuccess(res.data));
      yield put(
        alertActions.alertShow(
          false,
          false,
          true,
          false,
          'Succès',
          'Activité est modifié avec succès'
        )
      );

      yield put(getAllActivityCsvActions.getAllActivityCsvRequest({
        payload: {
          activePage: 1,
          itemsPerPage: 5
        }
      }));
      yield put(push('/activitycsv/list'));
    }
    else {
      yield put(getActivityCsvByIdActions.editActivityCsvFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getActivityCsvByIdActions.editActivityCsvFailure(errorMessage));
  }
}

export function* editActivityCsvSaga() {
  yield takeLatest(
    editActivityCsvTypes.GET_ACTIVITY_CSV_BY_ID_REQUEST,
    getActivityCsvByIdSagas
  );
  yield takeLatest(
    editActivityCsvTypes.EDIT_ACTIVITY_CSV_REQUEST,
    editActivityCsvSagas
  );
}
