import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  generateCsvRequest: ['response'],
  generateCsvSuccess: ['response', 'loading'],
  generateCsvFailure: ['error']
});

export const generateCsvTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null
});

/* ------------- Reducers ------------- */

const generateCsvRequest = state => state.merge({ loading: true });

const generateCsvSuccess = (state, { response }) => state.merge({
  error: false,
  response,
  loading: false
});

const generateCsvFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GENERATE_CSV_REQUEST]: generateCsvRequest,
  [Types.GENERATE_CSV_SUCCESS]: generateCsvSuccess,
  [Types.GENERATE_CSV_FAILURE]: generateCsvFailure
});
