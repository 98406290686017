import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line 
import { push } from 'react-router-redux';
import { errorMessageToReturn } from '../../shared/utility';
import getBudgetYearsActions, {
  getBudgetYearsTypes
} from '../../store/reducers/budgetYearReducers/getBudgetYearWithPag';
import instance from '../../axios';

import alertActions from '../../store/reducers/alertReducers';
/* eslint no-underscore-dangle: 0 */

function* getBudgetYearsSagas({ response }) {
  try {
    let url = '';
    if (
      (
        (response.searchValue === '' || response.searchValue === undefined) && (response.etat === '' || response.etat === undefined) && (response.key === '' || response.key === undefined)
      ) || response === null
    )  {
      url =   '/api/anneeBudgetaire/?limit=' 
      + response.itemsPerPage
      + '&page='
      + response.activePage
      + '&order=asc';
    }

    else {
      url =   '/api/anneeBudgetaire/?limit='
      + response.itemsPerPage
      + '&page='
      + response.activePage
      + '&annee='
      +  (response.searchValue ? response.searchValue : '')
      + '&order='
      + (response.order ? (response.order) : 'asc')
      + '&key='
      + (response.key ? (response.key) : '')
      + '&etat='
      +  ((response.etat !== undefined && response.etat !== null) ? response.etat : '');
    }
    const res = yield instance.get(
      url
    );
    if (res.status === 200) {
      yield put(getBudgetYearsActions.getBudgetYearsSuccess(res.data));
      if (response.navigate === true) {
        yield put(push('/anneebudgetaire/list'));
      }
    }
    else {
      yield put(getBudgetYearsActions.getBudgetYearsFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getBudgetYearsActions.getBudgetYearsFailure(errorMessage));
  }
}

export function* getBudgetYearsSaga() {
  yield takeLatest(
    getBudgetYearsTypes.GET_BUDGET_YEARS_REQUEST,
    getBudgetYearsSagas
  );
}
