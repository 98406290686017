import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import { errorMessageToReturn } from '../../../shared/utility';
import getDecaissementsByCommunesActions, {
  getDecaissementsByCommunesTypes
} from '../../../store/reducers/decaissementReducers/getDecaissementsByCommunes';
import alertActions from '../../../store/reducers/alertReducers';
import instance from '../../../axios';
/* eslint no-underscore-dangle: 0 */

function* getDecaissementsByCommunesSagas({ response }) {
  try {
    const res = yield instance.get(
      'api/decaissement/commune?ids=' + response
    );
    if (res.status === 200) {
      yield put(
        getDecaissementsByCommunesActions.getDecaissementsByCommunesSuccess(
          res
        )
      );
    }
    else {
      yield put(
        getDecaissementsByCommunesActions.getDecaissementsByCommunesFailure(res)
      );
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getDecaissementsByCommunesActions.getDecaissementsByCommunesFailure(errorMessage));
  }
}

export function*  getDecaissementsByCommunesSaga() {
  yield takeLatest(
    getDecaissementsByCommunesTypes.GET_DECAISSEMENTS_BY_COMMUNES_REQUEST,
    getDecaissementsByCommunesSagas
  );
}
