import { takeLatest, put, call, all } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import { errorMessageToReturn } from '../../../shared/utility';
import deleteOrganismeActions, {
  deleteOrganismeTypes
} from '../../../store/reducers/organismeReducers/deleteOrganisme';
import getOrganismesByPaginationRequestActions from '../../../store/reducers/organismeReducers/getOrganismesByPagination';

import instance from '../../../axios';
import alertActions from '../../../store/reducers/alertReducers';
import getAllOrganismestActions from '../../../store/reducers/organismeReducers/getAllOrganismes';

function* deleteOrganismeSagas(response) {
  try {
    const responseDelete = yield instance.delete(
      '/api/organisme/delete/' + response.response
    );
    if (responseDelete.status === 200) {
      yield put(
        alertActions.alertShow(
          false,
          false,
          true,
          false,
          'Succès',
          'Organisme est supprimé avec succès'
        )
      );
      yield all([
        yield put(
          deleteOrganismeActions.deleteOrganismeSuccess(responseDelete.data)
        ),
        yield put(getOrganismesByPaginationRequestActions.getOrganismesByPaginationRequest({ limit: 5, activePage: 1 })),
        yield put(
          getAllOrganismestActions.getAllOrganismesRequest()
        )
      ]);
    }
    else {
      yield put(
        deleteOrganismeActions.deleteOrganismeFailure(responseDelete.data)
      );
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(deleteOrganismeActions.deleteOrganismeFailure(errorMessage));
  }
}

export function* deleteOrganismeSaga() {
  yield takeLatest(
    deleteOrganismeTypes.DELETE_ORGANISME_REQUEST,
    deleteOrganismeSagas
  );
}
