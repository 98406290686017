import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  editNotificationRequest: ['response'],
  editNotificationSuccess: ['loading', 'response'],
  editNotificationFailure: ['error'],

  getNotificationByIdRequest: ['response'],
  getNotificationByIdSuccess: ['response', 'loading'],
  getNotificationByIdFailure: ['error']
});

export const editNotificationTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null,
  missions: null
});

/* ------------- Reducers ------------- */

const editNotificationRequest = state => state.merge({ loading: true });

const editNotificationSuccess = (state, { response }) => state.merge({
  loading: false,
  error: false,
  response
});

const editNotificationFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

const getNotificationByIdRequest = state => state.merge({ loading: true });

const getNotificationByIdSuccess = (state, { response }) => state.merge({
  error: false,
  loading: false,
  response
});

const getNotificationByIdFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.EDIT_NOTIFICATION_REQUEST]: editNotificationRequest,
  [Types.EDIT_NOTIFICATION_SUCCESS]: editNotificationSuccess,
  [Types.EDIT_NOTIFICATION_FAILURE]: editNotificationFailure,

  [Types.GET_NOTIFICATION_BY_ID_REQUEST]: getNotificationByIdRequest,
  [Types.GET_NOTIFICATION_BY_ID_SUCCESS]: getNotificationByIdSuccess,
  [Types.GET_NOTIFICATION_BY_ID_FAILURE]: getNotificationByIdFailure
});
