import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  editActivityCsvRequest: ['response'],
  editActivityCsvSuccess: ['loading', 'response'],
  editActivityCsvFailure: ['error'],
  getActivityCsvByIdRequest: ['response'],
  getActivityCsvByIdSuccess: ['response', 'loading'],
  getActivityCsvByIdFailure: ['error']
});

export const editActivityCsvTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null,
  missions: null
});

/* ------------- Reducers ------------- */

const editActivityCsvRequest = state => state.merge({ loading: true });

const editActivityCsvSuccess = (state, { response }) => state.merge({
  error: false,
  response
});

const editActivityCsvFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

const getActivityCsvByIdRequest = state => state.merge({ loading: true });

const getActivityCsvByIdSuccess = (state, { response }) => state.merge({
  error: false,
  loading: false,
  response
});

const getActivityCsvByIdFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.EDIT_ACTIVITY_CSV_REQUEST]: editActivityCsvRequest,
  [Types.EDIT_ACTIVITY_CSV_SUCCESS]: editActivityCsvSuccess,
  [Types.EDIT_ACTIVITY_CSV_FAILURE]: editActivityCsvFailure,

  [Types.GET_ACTIVITY_CSV_BY_ID_REQUEST]: getActivityCsvByIdRequest,
  [Types.GET_ACTIVITY_CSV_BY_ID_SUCCESS]: getActivityCsvByIdSuccess,
  [Types.GET_ACTIVITY_CSV_BY_ID_FAILURE]: getActivityCsvByIdFailure
});
