import { combineReducers } from 'redux';
import { reducer as getUsers } from './getAllUsers';
import { reducer as addUser } from './addUser';
import { reducer as editUser } from './editUser';
import { reducer as deleteUser } from './deleteUser';
import { reducer as users } from './getUsers';
import { reducer as updatePassword } from './updatePassword';

export default combineReducers({
  getUsers,
  addUser,
  editUser,
  users,
  deleteUser,
  updatePassword
});
