import { takeLatest, put, all } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import getProjectByIdActions, { editProjectTypes } from '../../../store/reducers/projectReducers/editProject';
import getProjectsActions from '../../../store/reducers/projectReducers/getProjects';
import instance from '../../../axios';
import alertActions from '../../../store/reducers/alertReducers';
import { errorMessageToReturn } from '../../../shared/utility';
/* eslint no-underscore-dangle: 0 */

function* getProjectByIdSagas(payload) {
  // console.log(payload);

  try {
    const res = yield instance.get('/api/projet/' +  payload.response.id);
    if (res.status === 200 || res.status === 201) {
      yield put(getProjectByIdActions.getProjectByIdSuccess(res.data));
    }
    else {
      yield put(getProjectByIdActions.getProjectByIdFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecté
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autorisé
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getProjectByIdActions.getProjectByIdFailure(errorMessage));
  }
}

function* editProjectSagas({ response }) {
  const newResponse = { communes: response.communes };

  try {
    const res = yield instance.patch('/api/projet/' + response.id, newResponse);
    if (res.status === 200 || res.status === 201) {
      yield put(getProjectByIdActions.editProjectSuccess(res.data));
      yield put(
        alertActions.alertShow(
          false,
          false,
          true,
          false,
          'Succès',
          'Géolocalisation est modifiée avec succès'
        )
      );

      yield put(
        getProjectsActions.getProjectsRequest({
          activePage: 1, limit: 5
        })
      );
      yield put(push('/project/list'));
    }
    else {
      yield put(getProjectByIdActions.editProjectFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecté
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autorisé
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getProjectByIdActions.editProjectFailure(errorMessage));
  }
}

export function* editProjectSaga() {
  yield takeLatest(
    editProjectTypes.GET_PROJECT_BY_ID_REQUEST,
    getProjectByIdSagas
  );
  yield takeLatest(
    editProjectTypes.EDIT_PROJECT_REQUEST,
    editProjectSagas
  );
}
