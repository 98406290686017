import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'react-router-redux';
import {createBrowserHistory} from 'history';
import api from '../../middleware/api';
import createGlobalReducer from '../reducers';
import globalSagas from '../../saga';
/* eslint no-underscore-dangle: 0 */
const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();
const middleware =  applyMiddleware(
  sagaMiddleware,
  thunk,
  api,
  routerMiddleware(history)
);

const rootreducer =  createGlobalReducer();
const configureStore = (preloadedState) => {
  const store = createStore(
    rootreducer,
    preloadedState,
    compose(middleware)
  );
  sagaMiddleware.run(globalSagas);
  return store;
};

export default configureStore;
