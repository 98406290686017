import {
  takeLatest,
  put
} from "redux-saga/effects"; // eslint-disable-line

import { push } from 'react-router-redux';
import getProjectsActions, {
  getProjectsTypes
} from '../../../store/reducers/projectReducers/getProjects';
import alertActions from '../../../store/reducers/alertReducers';
import instance from '../../../axios';
import { errorMessageToReturn } from '../../../shared/utility';
/* eslint no-underscore-dangle: 0 */
function* getProjectsSagas({ response }) {
  try {
    let url;
    if (
      (
        (response.searchValue === '' || response.searchValue === undefined) && (response.rna === '' || response.rna === undefined) && (response.key === '' || response.key === undefined)
      ) || response === null
    ) {
      url = '/api/projet/'
      + '?limit='
      + response.limit
      + '&page='
      + response.activePage;
    }
    else {
      url = '/api/projet/'
      + '?limit='
      + response.limit
      + '&page='
      + response.activePage
      + '&libelle='
      + (response.searchValue ? (response.searchValue) : '')
      + '&order='
      + (response.order ? (response.order) : 'desc')
      + '&key='
      + (response.key ? (response.key) : '')
      + '&convention='
      + (response.rna ? response.rna : '');
    }
    const res = yield instance.get(url);

    if (res.status === 200) {
      yield put(getProjectsActions.getProjectsSuccess(res.data));
    }
    else {
      yield put(getProjectsActions.getProjectsFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecté
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autorisé
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(
      getProjectsActions.getProjectsFailure(errorMessage)
    );
  }
}

export function* getProjectsSaga() {
  yield takeLatest(
    getProjectsTypes.GET_PROJECTS_REQUEST,
    getProjectsSagas
  );
}
