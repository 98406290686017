import { takeLatest, put, call, all } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import deleteConventionActions, {
  deleteConventionTypes
} from '../../../store/reducers/conventionReducers/deleteConvention';
import getAllConventionsActions from '../../../store/reducers/conventionReducers/getConventions';

import instance from '../../../axios';
import alertActions from '../../../store/reducers/alertReducers';
import { errorMessageToReturn } from '../../../shared/utility';
import getAllConventionsNoPagActions from '../../../store/reducers/conventionReducers/getConventionsNoPag';

function* deleteConventionSagas(response) {
  try {
    const responseDelete = yield instance.delete(
      '/api/convention/delete/' + response.response
    );
    if (responseDelete.status === 200) {
      yield put(alertActions.alertShow(false, false, true, false, 'Succès', 'Convention est supprimée avec succès'));
      yield all([
        yield put(deleteConventionActions.deleteConventionSuccess(responseDelete.data)),
        yield put(getAllConventionsNoPagActions.getAllConventionsNoPagRequest()),
        yield put(getAllConventionsActions.getAllConventionsRequest({ limit: 5, activePage: 1, navigate: true }))
      ]);
    }
    else {
      yield put(
        deleteConventionActions.deleteConventionFailure(responseDelete.data)
      );
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecté
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autorisé
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(deleteConventionActions.deleteConventionFailure(errorMessage));
  }
}

export function* deleteConventionSaga() {
  yield takeLatest(
    deleteConventionTypes.DELETE_CONVENTION_REQUEST,
    deleteConventionSagas
  );
}
