
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* eslint no-underscore-dangle: 0 */
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getNotificationsRequest: ['response'],
  getNotificationsSuccess: ['response', 'loading'],
  getNotificationsFailure: ['error']
});

export const  getNotificationsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null

});

/* ------------- Reducers ------------- */
const  getNotificationsSuccess = (state, { response }) => {
  return state.merge({
    response: response,
    error: false,
    loading: false

  });
};
const  getNotificationsFailure = (state, { error }) => state.merge({
  loading: false,
  error: error
});
const  getNotificationsRequest = (state) => {
  return state.merge({ loading: true });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_NOTIFICATIONS_REQUEST]: getNotificationsRequest,
  [Types.GET_NOTIFICATIONS_SUCCESS]: getNotificationsSuccess,
  [Types.GET_NOTIFICATIONS_FAILURE]: getNotificationsFailure
});
