import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import { errorMessageToReturn } from '../../../shared/utility';
import addNewReclamationActions, {
  addNewReclamationTypes
} from '../../../store/reducers/reclamationReducers/addNewReclamation';
import alertActions from '../../../store/reducers/alertReducers';
import getAllReclamationActions from '../../../store/reducers/reclamationReducers/getAllReclamations';
import instance from '../../../axios';
import getNotificationsActions from '../../../store/reducers/notificationsReducers/getNotifications';
/* eslint no-underscore-dangle: 0 */

function* addNewReclamationSagas({ response }) {
  const user = { id: 1 };
  response.payload.reclamation = { ...response.payload.reclamation, user };
  response.payload.reclamation.reponse = '';// reponse est réquise !!!
  try {
    const res = yield instance.post(
      'api/reclamation/',
      response.payload.reclamation
    );
    if (res.status === 200 || res.status === 201) {
      yield put(addNewReclamationActions.addNewReclamationSuccess(res.data));
      yield put(getNotificationsActions.getNotificationsRequest());
      yield put(
        alertActions.alertShow(
          false,
          false,
          true,
          false,
          'Succès',
          'Réclamation est ajoutée avec succès'
        )
      );
      yield put(getAllReclamationActions.getAllReclamationsRequest({ payload: { itemsPerPage: 5, activePage: 1 } }));
      yield put(push('/reclamation/list'));
    }
    else {
      yield put(addNewReclamationActions.addNewReclamationFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(addNewReclamationActions.addNewReclamationFailure(errorMessage));
  }
}
export function* addNewReclamationSaga() {
  yield takeLatest(
    addNewReclamationTypes.ADD_NEW_RECLAMATION_REQUEST,
    addNewReclamationSagas
  );
}
