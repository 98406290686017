import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import { errorMessageToReturn } from '../../../shared/utility';
import editNotificationActions, {
  editNotificationTypes
} from '../../../store/reducers/notificationsReducers/editNotification';
import getNotifications from '../../../store/reducers/notificationsReducers/getNotifications';
import alertActions from '../../../store/reducers/alertReducers';

import instance from '../../../axios';
/* eslint no-underscore-dangle: 0 */

function* editNotificationSagas({ response }) {
  const newResponse = { id: response.id, etat: true, url: response.url };
  try {
    const res = yield instance.patch(
      '/api/notification/' + newResponse.id,
      newResponse
    );
    if (res.status === 200 || res.status === 201) {
      yield put(editNotificationActions.editNotificationSuccess(res.data));
      yield put(getNotifications.getNotificationsRequest());
      yield put(push(response.url));
    }
    else {
      yield put(editNotificationActions.editNotificationFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.res.data.code === 401) {
      yield put(push('/logout'));
    }
    // user non autoriser
    if (error.res.data.code === 403) {
      yield put(push('/'));
    }
    yield put(editNotificationActions.editNotificationFailure(errorMessage));
  }
}

export function* editNotificationSaga() {
  yield takeLatest(editNotificationTypes.EDIT_NOTIFICATION_REQUEST, editNotificationSagas);
}
