import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  addNewOrganismeRequest: ['response'],
  addNewOrganismeSuccess: ['response', 'loading'],
  addNewOrganismeFailure: ['error']
});

export const addNewOrganismeTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null
});

/* ------------- Reducers ------------- */

const addNewOrganismeRequest = state => state.merge({
  loading: true
});

const addNewOrganismeSuccess = (state, { response }) => state.merge({
  loading: false,
  error: false,
  response
});

const addNewOrganismeFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_NEW_ORGANISME_REQUEST]: addNewOrganismeRequest,
  [Types.ADD_NEW_ORGANISME_SUCCESS]: addNewOrganismeSuccess,
  [Types.ADD_NEW_ORGANISME_FAILURE]: addNewOrganismeFailure
});
