import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  editRoleRequest: ['response'],
  editRoleSuccess: ['response'],
  editRoleFailure: ['error']
});
export const editRoleTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null
});

/* ------------- Reducers ------------- */

const editRoleSuccess = (state, {
  response
}) => {
  return state.merge({
    error: false,
    response: response,
    loading: false
  });
};
const editRoleFailure = (state, { error }) => state.merge({
  loading: false,
  error: error
});
const editRoleRequest = (state) => {
  return state.merge({ loading: true });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.EDIT_ROLE_REQUEST]: editRoleRequest,
  [Types.EDIT_ROLE_SUCCESS]: editRoleSuccess,
  [Types.EDIT_ROLE_FAILURE]: editRoleFailure

});
