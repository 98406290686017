import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getDecaissementsByCommunesRequest: ['response'],
  getDecaissementsByCommunesSuccess: ['response'],
  getDecaissementsByCommunesFailure: ['error']
});

export const getDecaissementsByCommunesTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null,
  totalItems: 0,
  activePage: 1,
  itemsPerPage: 5
});

/* ------------- Reducers ------------- */

const getDecaissementsByCommunesRequest = (state) => {
  return state.merge({ loading: true });
};
const getDecaissementsByCommunesSuccess = (state, { response }) => {
  return state.merge({
    error: false,
    response: response.data,
    loading: false,
    totalItems: response.meta ? response.meta.total : 10,
    activePage: response.meta ? response.meta.page : 1,
    itemsPerPage: response.meta ? response.meta.limit : 5
  });
};
const getDecaissementsByCommunesFailure = (state, { error }) => state.merge({
  loading: false,
  error: error
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_DECAISSEMENTS_BY_COMMUNES_REQUEST]: getDecaissementsByCommunesRequest,
  [Types.GET_DECAISSEMENTS_BY_COMMUNES_SUCCESS]: getDecaissementsByCommunesSuccess,
  [Types.GET_DECAISSEMENTS_BY_COMMUNES_FAILURE]: getDecaissementsByCommunesFailure
});
