import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import getConventionByIdActions, { detailsConventionTypes } from '../../../store/reducers/conventionReducers/detailsConvention';
import getAllConventionsActions from '../../../store/reducers/conventionReducers/getConventions';
import instance from '../../../axios';
import alertActions from '../../../store/reducers/alertReducers';
import { errorMessageToReturn } from '../../../shared/utility';

/* eslint no-underscore-dangle: 0 */

function* getConventionByIdSagas(payload) {
  try {
    const res = yield instance.get('/api/convention/' +  payload.response.id);
    if (res.status === 200 || res.status === 201) {
      yield put(getConventionByIdActions.getConventionByIdSuccess(res.data));

      if (payload.response.redirect) {
        yield put(push('/convention/details/' + payload.response.id));
      }
    }
    else {
      yield put(getConventionByIdActions.getConventionByIdFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecté
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autorisé
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getConventionByIdActions.detailsConventionFailure(errorMessage));
  }
}

function* detailsConventionSagas({ response }) {
  try {
    const res = yield instance.patch('/api/convention/' + response.id, response);
    if (res.status === 200 || res.status === 201) {
      yield put(getConventionByIdActions.detailsConventionSuccess(res.data));
      yield put(
        getAllConventionsActions.getAllConventionsRequest({
          activePage: 1, limit: 5, navigate: true
        })
      );
    }
    else {
      yield put(getConventionByIdActions.detailsConventionFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecté
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autorisé
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getConventionByIdActions.detailsConventionFailure(errorMessage));
  }
}

export function* detailsConventionSaga() {
  // yield takeLatest(
  //   detailsConventionTypes.GET_CONVENTION_BY_ID_REQUEST,
  //   getConventionByIdSagas
  // );
  yield takeLatest(
    detailsConventionTypes.DETAILS_CONVENTION_REQUEST,
    detailsConventionSagas
  );
}
