import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import { errorMessageToReturn } from '../../../shared/utility';
import addNewActivityActions, {
  addNewActivityTypes
} from '../../../store/reducers/activityReducers/addActivity';
import  getAllActivityActions from '../../../store/reducers/activityReducers/getAllActivity';
import alertActions from '../../../store/reducers/alertReducers';
import instance from '../../../axios';
/* eslint no-underscore-dangle: 0 */

function* addNewActivitySagas({ response }) {
  try {
    const res = yield instance.post(
      '/api/activite/add/', response
    );
    if (res.status === 200 || res.status === 201) {
      yield put(addNewActivityActions.addNewActivitySuccess(res.data));
      yield put(
        alertActions.alertShow(
          false,
          false,
          true,
          false,
          'Succès',
          'Activité est ajoutée avec succès'
        )
      );
      yield put(getAllActivityActions.getAllActivityRequest({
        payload: {
          activePage: 1,
          itemsPerPage: 5
        }
      }));
      yield put(push('/activity/list'));
    }
    else {
      yield put(addNewActivityActions.addNewActivityFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(addNewActivityActions.addNewActivityFailure(errorMessage));
  }
}
export function* addNewActivitySaga() {
  yield takeLatest(
    addNewActivityTypes.ADD_NEW_ACTIVITY_REQUEST,
    addNewActivitySagas
  );
}
