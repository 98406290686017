/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import   './notifications.css';
import getNotificationsActions from '../../../store/reducers/notificationsReducers/getNotifications';
import editNotificationsActions from '../../../store/reducers/notificationsReducers/editNotification';
import { generateKey, getPermission } from '../../../shared/utility';
import getReclamationByIdActions from '../../../store/reducers/reclamationReducers/editReclamation';
import getDecaissementByIdActions from '../../../store/reducers/decaissementReducers/updateDecaissement';
import detailsConvention from '../../../store/reducers/conventionReducers/detailsConvention';
import getProjectByIdActions from '../../../store/reducers/projectReducers/editProject';

class Notifications extends Component {
  static propTypes={
    notifications: PropTypes.array,
    onDisplayNotifications: PropTypes.func.isRequired,
    OnEditNotification: PropTypes.func.isRequired,
    onDisplayDetailsReclamation: PropTypes.func.isRequired,
    onDisplayDetailsDecaissement: PropTypes.func.isRequired,
    onDetailsConvention: PropTypes.func.isRequired,
    onDisplayDetailsProject: PropTypes.func.isRequired,
    loggedUser: PropTypes.object.isRequired

  };

  static defaultProps={
    notifications: null
  }

  constructor(props) {
    super(props);
    this.state = { countNotif: 0, notifications: null };
  }

  componentDidMount() {
    const { onDisplayNotifications, notifications, loggedUser } = this.props;
    if (notifications) {
      let listNotifs = [];
      if (getPermission('convention', loggedUser, 'Validate') || loggedUser.details.user_roles[0].role === 'SHA') {
        listNotifs = [...listNotifs, ...(notifications || []).filter(n => n.type === 'convention')];
      }
      if (getPermission('decaissement', loggedUser, 'Validate') || loggedUser.details.user_roles[0].role === 'SHA') {
        listNotifs = [...listNotifs, ...(notifications || []).filter(n => n.type === 'decaissement' || n.type === 'projet')];
      }
      if (getPermission('reclamation', loggedUser, 'Edit') || loggedUser.details.user_roles[0].role === 'SHA') {
        listNotifs = [...listNotifs, ...(notifications || []).filter(n => n.type === 'reclamation')];
      }
      this.setState({
        notifications: listNotifs,
        countNotif: listNotifs.filter(item => item.etat === false).length
      });
    }
  else onDisplayNotifications();
  }

  componentWillReceiveProps(nextProps) {
    const { notifications, loggedUser } = this.props;
    if (notifications !== nextProps.notifications) {
      // const countNotif = !nextProps.notifications ? 0 : nextProps.notifications.filter(item => item.etat === false).length;

      let listNotifs = [];
      if (getPermission('convention', loggedUser, 'Validate') || loggedUser.details.user_roles[0].role === 'SHA') {
        listNotifs = [...listNotifs, ...(nextProps.notifications || []).filter(n => n.type === 'convention')];
      }
      if (getPermission('decaissement', loggedUser, 'Validate') || loggedUser.details.user_roles[0].role === 'SHA') {
        listNotifs = [...listNotifs, ...(nextProps.notifications || []).filter(n => n.type === 'decaissement' || n.type === 'projet')];
      }
      if (getPermission('reclamation', loggedUser, 'Edit') || loggedUser.details.user_roles[0].role === 'SHA') {
        listNotifs = [...listNotifs, ...(nextProps.notifications || []).filter(n => n.type === 'reclamation')];
      }
      this.setState({
        notifications: listNotifs,
        countNotif: listNotifs.filter(item => item.etat === false).length
      });
    }
  }

  changeStatus=(e, id, notif) => {
    const {
      OnEditNotification,
      onDisplayDetailsReclamation,
      onDisplayDetailsDecaissement,
      onDisplayDetailsProject,
      onDetailsConvention
    } = this.props;
    const { countNotif } = this.state;
    e.preventDefault();
    let url = '';
    switch (notif.type) {
      case 'reclamation':
        onDisplayDetailsReclamation({ payload: { id: notif.reclamation.id } });
        url = '/reclamation/details/' + notif.reclamation.id;
        break;
      case 'decaissement':
        onDisplayDetailsDecaissement({ payload: { id: notif.decaissement.id } });
        url = '/decaissement/details/' + notif.decaissement.id;
        break;
      case 'projet':
        onDisplayDetailsProject({ id: notif.projet.id });
        url = '/project/edit/' + notif.projet.id;
        break;
      case 'convention':
        onDetailsConvention({ id: notif.convention.id, redirect: true });
        url = '/convention/details/' + notif.convention.id;
        break;
      default:
    }

    OnEditNotification({ id: id, url: url });
    this.setState({
      countNotif: countNotif > 0 ? countNotif - 1 : countNotif
    });
  }

  render() {
    const { notifications } = this.state;

    const { countNotif } = this.state;
    /**
     * Need to add test on user role
     */
    const arrayNotif = !notifications ? [] : notifications.map((item) => {
      let newText = '';

      switch (item.type) {
        case 'reclamation':
          newText = (<span><b>{item.user.name}</b>{' a ajouté une nouvelle '} <b>réclamation</b></span>);

          break;
        case 'convention':
          newText =  (<span><b>{item.user.name}</b>{' a validé une nouvelle '} <b>convention</b></span>);
          break;
        case 'decaissement':
          newText = (<span><b>{item.user.name}</b>{' a validé un  nouveau '}<b>décaissement</b></span>);
          break;
        case 'projet':
          newText =  (<span>Un {<b>nouveau projet</b>} est ajouté</span>);
          break;
        default:
      }

      return (
        <li className={item.etat === true ? 'liNotif ' : 'liNotif linotifread'} key={generateKey()}>
          <a className={item.etat === true ? 'nav-item dropdown NotifRead ' : 'nav-item dropdown NotifUnRead'} onClick={e => this.changeStatus(e, item.id, item, countNotif)}>
            <span>{newText}</span>
            <p className="dateList">{item.created_at}</p>

          </a>
        </li>

      );
    });
    return (
      <li id="dropdown02" className="nav-item dropdown">

        <a
          className="nav-link"
          id="dropdown01"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        > <i className="fa fa-bell notifIcon" name="hideNotif" aria-hidden="true"> <span className={countNotif === 0 ? 'hideNotif' : 'numNotif'}>{countNotif}</span>  </i>

        </a>
        <div className="dropdown-menu dropdown-menu-right menuStyle" aria-labelledby="dropdown01">

          <p className="notifStyle">Notifications</p>
          <hr className="styleHr" />
          <ul className="styleList">{ arrayNotif.length === 0 ? <p> &nbsp; Aucune notification</p>  : arrayNotif }</ul>
        </div>
      </li>

    );
  }
}
const mapStateToProps = (state) => {
  return {
    notifications: state.notifications.getNotifications.response,
    loggedUser: state.auth.auth.user
  };
};

const mapDispatchToProps = dispatch => ({
  onDisplayNotifications: () => dispatch(getNotificationsActions.getNotificationsRequest()),
  OnEditNotification: payload => dispatch(editNotificationsActions.editNotificationRequest(payload)),
  onDisplayDetailsReclamation: payload => dispatch(getReclamationByIdActions.getReclamationByIdRequest(payload)),
  onDisplayDetailsDecaissement: payload => dispatch(getDecaissementByIdActions.getDecaissementByIdRequest(payload)),
  onDetailsConvention: payload => dispatch(detailsConvention.getConventionByIdRequest(payload)),
  onDisplayDetailsProject: payload => dispatch(getProjectByIdActions.getProjectByIdRequest(payload))

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications);
