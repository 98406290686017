import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getStatistiquesRequest: ['response'],
  getStatistiquesSuccess: ['response'],
  getStatistiquesFailure: ['error']
});

export const  getStatistiquesTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null
});

/* ------------- Reducers ------------- */
const  getStatistiquesSuccess = (state, {
  response
}) => {
  return state.merge({
    error: false,
    response: response[1] < 4 ? response[0] : state.response,
    alignOSCREDD: response[1] === 4 ? response[0] : state.response,
    ventProjGeo: response[1] === 5 ? response[0] : state.response,
    ventProjOSCREDD: response[1] === 6 ? response[0] : state.response,
    specPTFOSCREDD: response[1] === 7 ? response[0] : state.response,
    decaissPrevOSCREDDPTF: response[1] === 8 ? response[0] : state.response,
    anaNatFin: response[1] === 9 ? response[0] : state.response,
    evoMontant: response[1] === 10 ? response[0] : state.response,
    loading: false
  });
};
const getStatistiquesFailure = (state, { error }) => state.merge({
  loading: false,
  error: error
});
const getStatistiquesRequest = (state) => {
  return state.merge({ loading: true });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_STATISTIQUES_REQUEST]: getStatistiquesRequest,
  [Types.GET_STATISTIQUES_SUCCESS]: getStatistiquesSuccess,
  [Types.GET_STATISTIQUES_FAILURE]: getStatistiquesFailure

});
