import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  deleteDecaissementRequest: ['response'],
  deleteDecaissementSuccess: ['response'],
  deleteDecaissementFailure: ['error']
});

export const deleteDecaissementTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null
});

/* ------------- Reducers ------------- */

const deleteDecaissementRequest = state => state.merge({ loading: true });

const deleteDecaissementSuccess = (state, { response }) => state.merge({
  error: false,
  response,
  loading: false
});

const deleteDecaissementFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.DELETE_DECAISSEMENT_REQUEST]: deleteDecaissementRequest,
  [Types.DELETE_DECAISSEMENT_SUCCESS]: deleteDecaissementSuccess,
  [Types.DELETE_DECAISSEMENT_FAILURE]: deleteDecaissementFailure
});
