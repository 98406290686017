
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* eslint no-underscore-dangle: 0 */
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getAllOrganismesRequest: ['response'],
  getAllOrganismesSuccess: ['response', 'loading'],
  getAllOrganismesFailure: ['error']
});

export const getAllOrganismesTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null

});

/* ------------- Reducers ------------- */
const getAllOrganismesSuccess = (state, { response }) => {
  return state.merge({
    response: response,
    error: false,
    loading: false

  });
};
const getAllOrganismesFailure = (state, { error }) => state.merge({
  loading: false,
  error: error
});
const getAllOrganismesRequest = (state) => {
  return state.merge({ loading: true });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ALL_ORGANISMES_REQUEST]: getAllOrganismesRequest,
  [Types.GET_ALL_ORGANISMES_SUCCESS]: getAllOrganismesSuccess,
  [Types.GET_ALL_ORGANISMES_FAILURE]: getAllOrganismesFailure
});
