import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  editActivityRequest: ['response'],
  editActivitySuccess: ['loading', 'response'],
  editActivityFailure: ['error'],
  getActivityByIdRequest: ['response'],
  getActivityByIdSuccess: ['response', 'loading'],
  getActivityByIdFailure: ['error']
});

export const editActivityTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  loading: null,
  missions: null
});

/* ------------- Reducers ------------- */

const editActivityRequest = state => state.merge({ loading: true });

const editActivitySuccess = (state, { response }) => state.merge({
  error: false,
  response
});

const editActivityFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

const getActivityByIdRequest = state => state.merge({ loading: true });

const getActivityByIdSuccess = (state, { response }) => state.merge({
  error: false,
  loading: false,
  response
});

const getActivityByIdFailure = (state, { response }) => state.merge({
  loading: false,
  error: true,
  response
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.EDIT_ACTIVITY_REQUEST]: editActivityRequest,
  [Types.EDIT_ACTIVITY_SUCCESS]: editActivitySuccess,
  [Types.EDIT_ACTIVITY_FAILURE]: editActivityFailure,

  [Types.GET_ACTIVITY_BY_ID_REQUEST]: getActivityByIdRequest,
  [Types.GET_ACTIVITY_BY_ID_SUCCESS]: getActivityByIdSuccess,
  [Types.GET_ACTIVITY_BY_ID_FAILURE]: getActivityByIdFailure
});
