import { takeLatest, put, all } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import getCsvByIdActions, { editCsvTypes } from '../../../store/reducers/csvReducers/editCsv';
import getCsvActions from '../../../store/reducers/csvReducers/getCsv';
import instance from '../../../axios';
import alertActions from '../../../store/reducers/alertReducers';
import { errorMessageToReturn } from '../../../shared/utility';
/* eslint no-underscore-dangle: 0 */

function* getCsvByIdSagas(payload) {
  try {
    const res = yield instance.get('/api/projet/get/projetcsv/' +  payload.response.payload.id);
    if (res.status === 200 || res.status === 201) {
      yield put(getCsvByIdActions.getCsvByIdSuccess(res.data));
    }
    else {
      yield put(getCsvByIdActions.getCsvByIdFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecté
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autorisé
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getCsvByIdActions.getCsvByIdFailure(errorMessage));
  }
}

function* editCsvSagas({ response }) {
  try {
    const res = yield instance.patch('/api/projet/edit/' + response.id, response);
    if (res.status === 200 || res.status === 201) {
      yield put(getCsvByIdActions.editCsvSuccess(res.data));
      yield put(
        alertActions.alertShow(
          false,
          false,
          true,
          false,
          'Succès',
          'Projet est modifiée avec succès'
        )
      );

      yield put(
        getCsvActions.getCsvRequest({
          activePage: 1, limit: 5
        })
      );
      yield put(push('/projectCsv/list'));
    }
    else {
      yield put(getCsvByIdActions.editCsvFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecté
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autorisé
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(getCsvByIdActions.editCsvFailure(errorMessage));
  }
}

export function* editCsvSaga() {
  yield takeLatest(
    editCsvTypes.GET_CSV_BY_ID_REQUEST,
    getCsvByIdSagas
  );
  yield takeLatest(
    editCsvTypes.EDIT_CSV_REQUEST,
    editCsvSagas
  );
}
