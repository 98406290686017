import { takeLatest, put, call, all } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import { errorMessageToReturn } from '../../../shared/utility';
import deleteActivityActions, {
  deleteActivityTypes
} from '../../../store/reducers/activityReducers/deleteActivity';
import  getAllActivityActions from '../../../store/reducers/activityReducers/getAllActivity';
import instance from '../../../axios';
import alertActions from '../../../store/reducers/alertReducers';

function* deleteActivitySagas(response) {
  try {
    const responseDelete = yield instance.delete(
      '/api/activite/delete/' + response.response.id
    );
    if (responseDelete.status === 200) {
      yield all([
        yield put(
          alertActions.alertShow(
            false,
            false,
            true,
            false,
            'Succès',
            'Activité est supprimée avec succès'
          )
        ),
        yield put(
          deleteActivityActions.deleteActivitySuccess(responseDelete.data)
        ),
        yield put(getAllActivityActions.getAllActivityRequest({
          payload: {
            activePage: 1,
            itemsPerPage: 5
          }
        })),
        yield put(push('/activity/list'))

      ]);
    }
    else {
      yield put(
        deleteActivityActions.deleteActivityFailure(responseDelete.data)
      );
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(deleteActivityActions.deleteActivityFailure(errorMessage));
  }
}

export function* deleteActivitySaga() {
  yield takeLatest(
    deleteActivityTypes.DELETE_ACTIVITY_REQUEST,
    deleteActivitySagas
  );
}
