import { takeLatest, put } from "redux-saga/effects"; // eslint-disable-line
import { push } from 'react-router-redux';
import { errorMessageToReturn } from '../../../shared/utility';
import addNewDecaissementActions, {
  addNewDecaissementTypes
} from '../../../store/reducers/decaissementReducers/addNewDecaissement';
import getDecaissementActions from '../../../store/reducers/decaissementReducers/getDecaissements';
import alertActions from '../../../store/reducers/alertReducers';
import instance from '../../../axios';
/* eslint no-underscore-dangle: 0 */

function* addNewDecaissementSagas({ response }) {
  try {
    const res = yield instance.post(
      '/api/decaissement/add/', response
    );
    if (res.status === 200 || res.status === 201) {
      yield put(addNewDecaissementActions.addNewDecaissementSuccess(res.data));
      yield put(
        alertActions.alertShow(
          false,
          false,
          true,
          false,
          'Succès',
          'Décaissement est ajouté avec succès'
        )
      );
      yield put(getDecaissementActions.getDecaissementsRequest({ payload: { activePage: 1, itemsPerPage: 5 } }));
      yield put(push('/decaissement/list'));
    }
    else {
      yield put(addNewDecaissementActions.addNewDecaissementFailure(res));
    }
  }
  catch (error) {
    const errorMessage = errorMessageToReturn(error);
    yield put(alertActions.alertShow(false, true, false, false, 'Erreur', errorMessage));
    // user non connecter
    if (error.response.data.code === 401) {
      yield put(push('/auth'));
    }
    // user non autoriser
    if (error.response.data.code === 403) {
      yield put(push('/'));
    }
    yield put(addNewDecaissementActions.addNewDecaissementFailure(errorMessage));
  }
}
export function* addNewDecaissementSaga() {
  yield takeLatest(
    addNewDecaissementTypes.ADD_NEW_DECAISSEMENT_REQUEST,
    addNewDecaissementSagas
  );
}
