
// export const API_ROOT = 'http://10.1.1.50:70';
// export const API_ROOT = 'http://41.226.165.26:8192/';
// export const TRANSLATION_ENDPOINT = '/Translation/get?culture=';

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = 'CALL_API';

const baseUrl = window.location.hostname; 
let api = '';
if (window.location.hostname === 'localhost' || baseUrl.indexOf('10.1.1.50') > -1) {
  //api = 'http://10.1.1.50:70';
 api = "https://api.sigap.gouv.ml"
  //api = 'http://127.0.0.1:8000';
}
else if (baseUrl.indexOf('41.226.165.26') > -1) { // || baseUrl.indexOf('sip-equipement') > -1) {
  api = 'http://41.226.165.26:8192';
}
else {
  api =  api = "https://api.sigap.gouv.ml";
}
export const API_ROOT = api;
